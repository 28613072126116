/* eslint-disable max-lines */
import React, { FC, ReactElement, useEffect } from 'react';
import { Action, Dispatch } from 'redux';
import { connect } from 'react-redux';
import loadable from '@loadable/component';
import useHotjar from '../ThirdPartyIntegration/useHotjar';
import { useOverlayStateChecker } from '../Overlays/useOverlayStateChecker';
import { OverlayId } from '../Overlays/types';
import { Dictionary } from 'Utils/types/utility';
import ProductFiltersFlyout from './ProductFiltersFlyout';
import useTrackingPageLabel from './hooks/useTrackingPageLabel';
import { getDefaultSeoData } from './services/seo';
import useListingPageUrlState from './hooks/useListingPageUrlState';
import ListingPage from './ListingPage';
import NoResultsPage from './NoResults';
import NoFilterResultsPage from './NoFilterResults';
import useLandingPageRedirects from './redirectsHook';
import { buildDynamicSearchGaData } from './SearchTracking';
import { useListingPageInfusions } from './hooks/useListingPageInfusions';
import { convertListingPageStateToUrl } from './services/listingPageUrlService';
import determineDynamicListingPageStatus, { DynamicListingPageStatus } from './determineDynamicListingPageStatus';
import StateInterface from 'Client/redux/types';
import { ListingPageUrlState, ProductsState, SortingParameters } from 'Client/redux/products/types';
import useGa from 'Client/hooks/useGa';
import { BinaryWithOffVariant, PageType } from 'AppShell/appshell_types';
import usePrevious from 'Client/hooks/usePrevious';
import { fetchProductsDataIfNeeded } from 'Client/redux/products/actions';
import { fetchContentInfusionIfNeeded } from 'Client/redux/infusions/actions';
import { NormalizedCategoriesData } from 'Client/redux/category/types';
import useFetchingAction from 'Client/hooks/useFetchingAction/useFetchingAction';
import useConfig from 'AppShell/hooks/useConfig';
import useBellaTranslator from 'Client/hooks/useTranslator';
import { combineThunks } from 'Client/redux/utils';
import { fetchSeoDataIfNeeded } from 'Client/redux/seo/actions';
import { useBrazePlpEvent } from 'Client/hooks/Braze/useBraze';
import { fetchCMSLinkedBannersIfNeeded } from 'Client/redux/cms/actions';
import { SHOP_TO_CLUB_FOOTER_LINK } from 'Client/redux/cms/constants';
import { getEntityByUrlKey } from 'Client/redux/category/categoriesNormalizer';
import { clearDynamicSearchMetrics, isRealDynamicSearchAction } from 'Client/redux/header/dynamicSearch/actions';
import { RedirectSuggestions } from 'Client/redux/header/dynamicSearch/types';
import { useBellaSelector } from 'Client/redux/hooks';
import { SeoState } from 'Client/redux/seo/types';
import useFeatureToggle from 'Client/hooks/useFeatureToggle';
import NotFound from 'Client/components/NotFound';
import StatusCodeComponentWrapper from 'Client/components/StaticContent/StatusCodeComponentWrapper';
import {
    ProductListViewedData,
    useSegmentProductListViewedTracking,
} from '../../../common/segment/useSegmentProductListViewedTracking';
import { SEGMENT_TRACKING_SOURCES } from '../../../common/segment/types';
import { fetchMediaInfusionIfNeeded } from 'Client/redux/mediaInfusion/actions';
import { useSegmentTrackingInfo } from 'Common/segment/useSegmentTrackingInfo';

interface ReduxStateProps {
    dynamicSearchRedirectSuggestions: RedirectSuggestions;
    isRealDynamicSearch: boolean;
    clickedSuggestion: string;
    products: ProductsState;
    brandEntities: Dictionary<string> | {};
    categories: NormalizedCategoriesData | null;
    seoState: SeoState;
}

interface ReduxDispatchProps {
    clearDynamicSearchMetrics: () => Action;
    setRealDynamicSearch: (value: boolean) => Action;
}

const buildPageViewData = (page: number): Dictionary<string> => ({ page_number: page.toString() });

const EnergyEfficiencyOverlay = loadable(() => import('Client/components/ProductDetailPage/Overlays/EnergyEfficiency'));

const dynamicListingPageMap = {
    [DynamicListingPageStatus.WasRedirected]: () => null,
    [DynamicListingPageStatus.PageNotFound]: () => (
        <StatusCodeComponentWrapper code={404}>
            <NotFound />
        </StatusCodeComponentWrapper>
    ),
    [DynamicListingPageStatus.NoAllProductsResult]: () => <NoResultsPage />,
    [DynamicListingPageStatus.NoFiltersResult]: () => <NoFilterResultsPage />,
};

const isCategoryPageWithProductBoostSorting = (urlState: ListingPageUrlState): boolean => {
    const sortType = urlState.sortingParams.sort || 'score_product_boost';
    const pageType = urlState.listingPageType;

    return sortType === 'score_product_boost' && pageType === PageType.CATEGORY;
};

const isInterleavingConditionFulfilled = (
    urlState: ListingPageUrlState,
    p100EnableInterleaving: undefined | boolean
): boolean => {
    const sortType = urlState.sortingParams.sort || 'score_product_boost';

    return !!p100EnableInterleaving && sortType === 'score_product_boost';
};

const getSortingParameters = (
    urlState: ListingPageUrlState,
    p100SignalBoostSorting: boolean | undefined
): SortingParameters => {
    if (isCategoryPageWithProductBoostSorting(urlState) && !!p100SignalBoostSorting) {
        return {
            sort: 'signal_boost',
            order: 'desc',
        };
    }

    return urlState.sortingParams;
};

const isPlpPersonalizationEnabled = (p100PersonalizeCategoryPlpResults: BinaryWithOffVariant) =>
    p100PersonalizeCategoryPlpResults === BinaryWithOffVariant.treatment ||
    p100PersonalizeCategoryPlpResults === BinaryWithOffVariant.control;

const isPlpPersonalizationConditionFulfilled = (
    urlState: ListingPageUrlState,
    p100PersonalizeCategoryPlpResults: BinaryWithOffVariant,
    loginHash: string | undefined
): boolean => {
    const isLoggedIn = !!loginHash;

    return (
        isLoggedIn &&
        isCategoryPageWithProductBoostSorting(urlState) &&
        isPlpPersonalizationEnabled(p100PersonalizeCategoryPlpResults)
    );
};

const shouldPersonalizeResults = (
    urlState: ListingPageUrlState,
    p100PersonalizeCategoryPlpResults: BinaryWithOffVariant,
    loginHash: string | undefined
): boolean => {
    if (isPlpPersonalizationConditionFulfilled(urlState, p100PersonalizeCategoryPlpResults, loginHash)) {
        return p100PersonalizeCategoryPlpResults === BinaryWithOffVariant.treatment;
    }

    return false;
};

export const getSegmentProductPayload = ({ simpleSku, sku, position }) => ({ product_id: simpleSku, sku, position });

const getSegmentTrackingFiltersSortsData = (
    urlState: ListingPageUrlState
): Pick<ProductListViewedData, 'filters' | 'sorts'> => {
    const data: Pick<ProductListViewedData, 'filters' | 'sorts'> = {};
    if (urlState.appliedFilters.length) {
        data.filters = urlState.appliedFilters.map(filter => ({
            type: filter.type,
            value: filter.values,
        }));
    }

    if (urlState.sortingParams.sort && urlState.sortingParams.order) {
        data.sorts = [
            {
                type: urlState.sortingParams.sort,
                value: urlState.sortingParams.order,
            },
        ];
    }

    return data;
};

// eslint-disable-next-line complexity
const DynamicListingPage: FC<{} & ReduxStateProps & ReduxDispatchProps> = (props): ReactElement | null => {
    const {
        products,
        dynamicSearchRedirectSuggestions,
        categories,
        brandEntities,
        isRealDynamicSearch,
        clearDynamicSearchMetrics,
        clickedSuggestion,
        seoState,
    } = props;
    const [urlState] = useListingPageUrlState();
    const pageType = useTrackingPageLabel();
    const gaTracking = useGa();
    const trackListingPageView = useSegmentProductListViewedTracking();
    const { pageType: segmentPageType, pageLabel } = useSegmentTrackingInfo();

    const wasRedirected = useLandingPageRedirects();
    const config = useConfig();
    const dynamicListingPageStatus = determineDynamicListingPageStatus({
        productsState: products,
        categories,
        urlState,
        wasRedirected,
        seoState,
        config,
    });

    const {
        p100SignalBoostSorting,
        p100AlgoliaTest,
        p100Plp120PerPage,
        p100PlpLooksInfusion,
        p100PlpInfusion,
        p100EnableInterleaving,
        p100InterleaveByDefaultSortFirst,
        p100PersonalizeCategoryPlpResults = 'off',
        p100PlpVideo,
    } = useFeatureToggle();
    const hasFiltersApplied = urlState.appliedFilters.length > 0;
    const { isFetchingComplete, trackingData } = products;
    const hasNoAllProductsResult = dynamicListingPageStatus === DynamicListingPageStatus.NoAllProductsResult;
    const wasNoResults = usePrevious(hasNoAllProductsResult);
    const didNoResultsBecomeTrue = !wasNoResults && hasNoAllProductsResult;
    const loginHash = useBellaSelector(state => state.appShellLogin.data?.loginHash);

    const personalizeResults = shouldPersonalizeResults(
        urlState,
        p100PersonalizeCategoryPlpResults as BinaryWithOffVariant,
        loginHash
    );

    const t = useBellaTranslator();
    const overlayStateChecker = useOverlayStateChecker();
    const thunks = [
        fetchProductsDataIfNeeded(urlState, {
            countryCode: config.countryCode,
            currency: config.currency,
            includeLooksInfusions: !!p100PlpLooksInfusion,
            includePromotions: !!p100PlpInfusion && !hasFiltersApplied,
            sortingParameters: getSortingParameters(urlState, p100SignalBoostSorting),
            enableInterleaving: p100EnableInterleaving,
            interleaveByDefaultSortFirst: p100InterleaveByDefaultSortFirst,
            loginHash: personalizeResults ? loginHash : undefined,
            p100Plp120PerPage,
        }),
        fetchSeoDataIfNeeded(urlState, getDefaultSeoData(urlState.listingPageType, t, config)),
        fetchCMSLinkedBannersIfNeeded(SHOP_TO_CLUB_FOOTER_LINK),
    ];

    const { infusionEnabled } = useListingPageInfusions();

    if (infusionEnabled) {
        thunks.push(fetchContentInfusionIfNeeded(urlState));
    }

    if (p100PlpVideo) {
        thunks.push(fetchMediaInfusionIfNeeded(urlState));
    }

    useFetchingAction(combineThunks(...thunks));

    useEffect(() => {
        if (didNoResultsBecomeTrue) {
            gaTracking.dynamicSearch.trackSearchWithNoResult(urlState.search);
        }
    }, [didNoResultsBecomeTrue, gaTracking, urlState.search]);

    useEffect(() => {
        const query = urlState.search;

        if (query) {
            gaTracking.dynamicSearch.trackSearchUrlParam(query);
        }
    }, [gaTracking, urlState.search]);

    const buildGaData = (
        urlState: ListingPageUrlState,
        categories: NormalizedCategoriesData | null,
        brandEntities: Dictionary<string> | {}
    ): Dictionary<string> => {
        const { page, listingPageKey } = urlState;
        const pageViewData = buildPageViewData(page);
        switch (urlState.listingPageType) {
            case PageType.CATEGORY:
                return {
                    categories_path: listingPageKey,
                    category_id: categories ? categories.urlKeys[listingPageKey] : '',
                    ...pageViewData,
                };
            case PageType.BRANDS:
                return { brand_id: brandEntities[listingPageKey] || '', ...pageViewData };
            default:
                return pageViewData;
        }
    };

    // eslint-disable-next-line complexity
    useEffect(() => {
        /**
         * Page view needs to be fired only when
         * API fetch has been completed, checked via isFetchingComplete
         * but when moving client side say PLP -> Home -> PLP the component
         * is remounted and at that time in store isFetchingComplete is true
         * To make sure at that point page view is not fired, we put identifier
         * matching condition, when identifier in URL and store is same, the
         * store is properly updated and safe to trigger pageView.
         */

        const identifier = convertListingPageStateToUrl(urlState);
        if (!isFetchingComplete || identifier !== products.identifier) {
            return;
        }

        const dynamicSearchData = buildDynamicSearchGaData(
            urlState.search || '',
            products,
            dynamicSearchRedirectSuggestions,
            isRealDynamicSearch,
            clickedSuggestion
        );

        if (isCategoryPageWithProductBoostSorting(urlState)) {
            gaTracking.trackVariation('p100SignalBoostSorting', p100SignalBoostSorting);
            gaTracking.trackVariation('p100AlgoliaTest', p100AlgoliaTest);
        }

        if (
            isPlpPersonalizationConditionFulfilled(
                urlState,
                p100PersonalizeCategoryPlpResults as BinaryWithOffVariant,
                loginHash
            )
        ) {
            gaTracking.trackVariation('p100PersonalizeCategoryPlpResults', p100PersonalizeCategoryPlpResults);
        }

        if (isInterleavingConditionFulfilled(urlState, p100EnableInterleaving)) {
            gaTracking.trackVariation('p100InterleaveByDefaultSortFirst', p100InterleaveByDefaultSortFirst);
        }

        gaTracking.trackVariation('p100Plp120PerPage', p100Plp120PerPage);

        gaTracking.trackPageView({
            pageType,
            gaData: {
                ...buildGaData(urlState, categories, brandEntities),
                ...dynamicSearchData,
                search_id: trackingData?.searchId || '',
                search_response_id: trackingData?.searchResponseId || '',
            },
        });

        trackListingPageView({
            listId: SEGMENT_TRACKING_SOURCES.PLP,
            listLabel: urlState.listingPageKey,
            products: products.products.map(getSegmentProductPayload),
            pageType: segmentPageType,
            pageLabel,
            ...getSegmentTrackingFiltersSortsData(urlState),
        });

        if (isRealDynamicSearch || clickedSuggestion) {
            clearDynamicSearchMetrics();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetchingComplete]);

    const categoryEntity = getEntityByUrlKey(categories, urlState.listingPageKey);
    useBrazePlpEvent(categoryEntity);

    // Trigger hotjar overlays
    const isLoggedIn = useBellaSelector(state => state.appShellLogin.isLoggedIn);
    const { sendOverlayTrigger } = useHotjar();
    useEffect(() => {
        if (isLoggedIn) {
            sendOverlayTrigger('plp__page-view--logged-in');
        }
    }, [isLoggedIn, sendOverlayTrigger]);
    sendOverlayTrigger('plp__page-view');

    const DynamicListingPageMatch = dynamicListingPageMap[dynamicListingPageStatus];
    if (DynamicListingPageMatch) {
        return <DynamicListingPageMatch />;
    }

    const isEnergyEfficiencyOverlayActive = overlayStateChecker(OverlayId.ENERGY_EFFICIENCY);

    return (
        <>
            {isEnergyEfficiencyOverlayActive && <EnergyEfficiencyOverlay />}
            <ProductFiltersFlyout />
            <ListingPage />
        </>
    );
};

const mapStateToProps = (state: StateInterface): ReduxStateProps => ({
    dynamicSearchRedirectSuggestions: state.header.dynamicSearchSuggestions.searchRedirect,
    isRealDynamicSearch: state.header.dynamicSearchSuggestions.isRealSearch,
    clickedSuggestion: state.header.dynamicSearchSuggestions.clickedSuggestion,
    products: state.products,
    brandEntities: state.brands.brandEntities,
    categories: state.categories.normalized,
    seoState: state.seoData,
});

const mapDispatchToProps = (dispatch: Dispatch): ReduxDispatchProps => ({
    clearDynamicSearchMetrics: (): Action => dispatch(clearDynamicSearchMetrics()),
    setRealDynamicSearch: (value: boolean): Action => dispatch(isRealDynamicSearchAction(value)),
});
const Connected = connect(mapStateToProps, mapDispatchToProps)(DynamicListingPage);

export default Connected;
