import { convertListingPageStateToUrl } from 'Client/components/ListingPage/services/listingPageUrlService';
import { PageType } from 'AppShell/appshell_types/routingTypes';
import { NormalizedCategoriesData } from 'Client/redux/category/types';
import { FilterType, ListingPageUrlState, ProductsState } from 'Client/redux/products/types';
import { SeoState } from 'Client/redux/seo/types';
import { Config } from 'AppShell/appshell_types';

export enum DynamicListingPageStatus {
    NoAllProductsResult,
    NoFiltersResult,
    PLPResult,
    PageNotFound,
    WasRedirected,
}

interface PageStatusParams {
    productsState: ProductsState;
    categories: NormalizedCategoriesData | null;
    urlState: ListingPageUrlState;
    wasRedirected: boolean;
    seoState: SeoState;
    config: Config;
}

const hasNoFilterResult = ({ productsState, urlState }: PageStatusParams) => {
    const hasFiltersApplied = urlState.appliedFilters.length > 0;

    const { isFetching, isFetchingComplete, error, products, alternativeProducts } = productsState;
    const isBlockedByFetching = !isFetching && isFetchingComplete;
    const hasNoFilterProducts = !products.length && !alternativeProducts.length;
    const hasValidFilterResultUrl = error?.code !== 404;
    const requestWasAborted = error?.type === 'aborted';

    // prettier-ignore
    return (
        isBlockedByFetching &&
        hasNoFilterProducts &&
        hasFiltersApplied &&
        hasValidFilterResultUrl &&
        !requestWasAborted
    );
};

const hasNoAllProductsResult = ({ productsState, urlState }: PageStatusParams) => {
    const { isFetchingComplete, products } = productsState;
    const hasNoProducts = !products.length;
    const isAllProductsPageType = urlState.listingPageType === PageType.ALLPRODUCTS;

    return isAllProductsPageType && isFetchingComplete && hasNoProducts;
};

const hasPageNotFoundResult = ({ productsState }: PageStatusParams) => {
    const { isFetchingComplete, products, error, didInvalidate } = productsState;
    const redirectOccurred = error && error.isRedirect;
    const hasNoProducts = !products.length;

    return didInvalidate && isFetchingComplete && hasNoProducts && !redirectOccurred;
};

const hasInvalidCategoryListingPageFilters = ({ urlState, seoState, config }: PageStatusParams) => {
    const { isFetching, seoData } = seoState;
    const localizedFilterTypes = [
        FilterType.materials,
        FilterType.colors,
        FilterType.styles,
        FilterType.pattern,
        FilterType.form,
        FilterType.pillowType,
        FilterType.feature,
    ];
    const isCategoryListingPageType = urlState.listingPageType === PageType.CATEGORY;
    const identifier = convertListingPageStateToUrl(urlState);

    return (
        isCategoryListingPageType &&
        !isFetching &&
        // we need to make sure that we're checking up-to-date SEO state
        seoState.identifier === identifier &&
        urlState.appliedFilters.length &&
        urlState.appliedFilters.some(appliedFilter => {
            if (!localizedFilterTypes.includes(appliedFilter.type)) {
                return false;
            }

            const localizedFilters =
                seoData.localizedFilters?.[config.currentCountry]?.[appliedFilter.name]?.values ?? [];

            return appliedFilter.values.some(
                appliedFilterValue => !localizedFilters.includes(appliedFilterValue.toLocaleLowerCase())
            );
        })
    );
};

const determineDynamicListingPageStatus = (pageStatusParams: PageStatusParams) => {
    if (pageStatusParams.wasRedirected) {
        return DynamicListingPageStatus.WasRedirected;
    }

    if (hasInvalidCategoryListingPageFilters(pageStatusParams)) {
        return DynamicListingPageStatus.PageNotFound;
    }

    if (hasNoAllProductsResult(pageStatusParams)) {
        return DynamicListingPageStatus.NoAllProductsResult;
    }

    if (hasNoFilterResult(pageStatusParams)) {
        return DynamicListingPageStatus.NoFiltersResult;
    }

    if (hasPageNotFoundResult(pageStatusParams)) {
        return DynamicListingPageStatus.PageNotFound;
    }

    return DynamicListingPageStatus.PLPResult;
};

export default determineDynamicListingPageStatus;
