import useTranslator from 'Client/hooks/useTranslator';
import { SeoData } from 'Client/redux/seo/types';
import { ListingPageUrlState } from 'Client/redux/products/types';
import { PageType } from 'AppShell/appshell_types/routingTypes';
import { BellaTranslator } from 'AppShell/appshell_types';

export const SHOP_NAME_POE_KEY = 'SHOP_NAME';
export const NEW_PRODUCTS_POE_KEY = 'Neuheiten';
export const SEARCH_POE_KEY = 'page_headline_text_search';
export const EMPTY_SEARCH_POE_KEY = 'page_headline_empty_search';

export const getH1TitleCandidate = (mobilePageHeader: string, seoHeadline: string) =>
    mobilePageHeader || seoHeadline || '';

export const getH1Title = (defaultTitle: string, urlState: ListingPageUrlState, t: BellaTranslator) => {
    const { NEWPRODUCTS, ALLPRODUCTS } = PageType;
    const { search, listingPageType } = urlState;

    switch (listingPageType) {
        case NEWPRODUCTS:
            return t(NEW_PRODUCTS_POE_KEY);
        case ALLPRODUCTS:
            return search ? t(SEARCH_POE_KEY, [search]) : t(EMPTY_SEARCH_POE_KEY);
        default:
            return defaultTitle;
    }
};

export const getMetaTitle = (seoTitle: string, h1Title: string, shopName: string) =>
    seoTitle || (h1Title ? `${h1Title} | ${shopName}` : shopName);

const useListingPageTitle = (urlState: ListingPageUrlState, seoData: SeoData) => {
    const t = useTranslator();
    const { mobilePageHeader = '', seoHeadline = '', seoTitle = '' } = seoData || {};

    const shopName = t(SHOP_NAME_POE_KEY);
    const h1TitleCandidate = getH1TitleCandidate(mobilePageHeader, seoHeadline);
    const h1Title = getH1Title(h1TitleCandidate, urlState, t);
    const metaTitle = getMetaTitle(seoTitle, h1Title, shopName);

    return [h1Title, metaTitle];
};

export default useListingPageTitle;
