import React from 'react';
import styled from 'styled-components';
import { forDesktop, forTablet, forWideScreens } from '@westwing/ui-kit/breakPoints';
import { SliderData } from 'Client/redux/seo/types';
import CardImage from 'Client/components/Generic/Banner';
import HorizontalScrollableElement from 'Client/components/ScrollableElement';
import { BannerText } from 'Client/components/Homepage/SecondaryBanner';
import { HorizontalScrollContainer } from 'Client/components/Generic/ProductsGrid/Infusions';
import { useLogger } from 'AppShell/logger/LoggerProvider';
import { changePreformattedImageProperties } from '@westwing/ui-kit/utils/image';

export interface SeoSliderProps {
    sliderData: SliderData | null;
}

const SeoSliderContainer = styled.div`
    margin: 35px -10px 30px 15px;

    ${forTablet`
        margin-right: -30px;
    `}

    ${forDesktop`
        margin-right: 0;
    `}
`;

const StyledSliderHeader = styled(HorizontalScrollableElement)`
    padding: 0;
    margin: 0 0 15px;

    ${forTablet`
        padding: 0;
        margin: 0;
        `}

    ${forDesktop`
        padding: 0;
        margin: 0;
        `}
`;

const StyledHorizontalScrollContainer = styled(HorizontalScrollContainer)`
    padding: 0;

    ${forTablet`
        padding: 0;
    `}

    ${forDesktop`
        padding: 0;
    `}
`;

const Card = styled.div`
    margin-right: 10px;
    flex: 1 0 175px;
    &:last-child {
        flex-basis: 190px;
        padding-right: 15px;
    }
    ${forTablet`
        margin-right: 0;
        &:not(:first-child) {
            margin-left: 10px;
        }
        &:last-child {
            flex-basis: 220px;
            padding-right: 35px;
        }
    `}
    ${forDesktop`
        flex-basis: 212px;
        &:last-child {
            flex-basis: 212px;
            padding-right: 0;
        }
    `}
    ${forWideScreens`
        flex-basis: 303px;
        &:last-child {
            flex-basis: 303px;
            padding-right: 0;
        }
    `}
`;

const CardText = styled(BannerText)`
    padding: 5px 0 0;
    max-width: 175px;
    ${forDesktop`
        max-width: 212px;
    `}
    ${forWideScreens`
        max-width: 303px;
    `}
`;

const SeoSlider: React.FC<SeoSliderProps> = ({ sliderData }) => {
    const logger = useLogger();
    const onError = (error: Error) => {
        logger.warn(error.message);
    };
    return (
        sliderData && (
            <SeoSliderContainer>
                <StyledSliderHeader title={sliderData.mainTitle} showHeader showNavArrows>
                    <StyledHorizontalScrollContainer>
                        {sliderData.items.map(card => {
                            const cardImageMobile = changePreformattedImageProperties(
                                card.image,
                                {
                                    height: '606',
                                },
                                onError
                            );
                            return (
                                <Card key={card.title}>
                                    <CardImage
                                        key={card.title}
                                        desktopImage={card.image}
                                        mobileImage={cardImageMobile}
                                        targetUrl={card.link}
                                        trackingFn={() => {}}
                                    />
                                    <CardText {...card} targetUrl={card.link} trackingName="seo-slider" />
                                </Card>
                            );
                        })}
                    </StyledHorizontalScrollContainer>
                </StyledSliderHeader>
            </SeoSliderContainer>
        )
    );
};

export default SeoSlider;
