import { ProductsState, SearchType } from '../../redux/products/types';
import { DynamicSearchSuggestion, RedirectSuggestions } from 'Client/redux/header/dynamicSearch/types';
import {
    getDynamicSearchSubmissionMethod,
    mapToSearchTrackingData,
    RedirectMethod,
    SearchMethod,
    SearchTrackingData,
} from 'Common/ga/dynamicSearch';

const getSearchMethod = (searchType: SearchType, redirectMethod: RedirectMethod): SearchMethod => {
    if (redirectMethod === RedirectMethod.Manual) {
        return SearchMethod.RedirectManual;
    }
    if (redirectMethod === RedirectMethod.Automatic) {
        return SearchMethod.RedirectAutomatic;
    }
    if (searchType === SearchType.FUZZY) {
        return SearchMethod.Fuzzy;
    }
    if (searchType === SearchType.STRICT) {
        return SearchMethod.Strict;
    }

    return SearchMethod.Empty;
};

export const getNonEmptySuggestionKey = (searchRedirect: DynamicSearchSuggestion | RedirectSuggestions): string => {
    const redirectPage = Object.keys(searchRedirect).find(page => searchRedirect[page].length);
    return redirectPage || '';
};

export const getRedirectMethod = (searchRedirect: DynamicSearchSuggestion | RedirectSuggestions): RedirectMethod => {
    const redirectPage = getNonEmptySuggestionKey(searchRedirect);

    if (redirectPage) {
        return (searchRedirect[redirectPage][0].redirectMethod as RedirectMethod) || RedirectMethod.Automatic;
    }

    return RedirectMethod.Empty;
};

// eslint-disable-next-line max-params
export function buildDynamicSearchGaData(
    searchTerm: string,
    products: ProductsState,
    searchRedirect: RedirectSuggestions,
    isRealSearch: boolean,
    clickedSuggestion: string
): SearchTrackingData {
    const redirectPage = getNonEmptySuggestionKey(searchRedirect);
    const submissionMethod = getDynamicSearchSubmissionMethod(clickedSuggestion);

    return mapToSearchTrackingData({
        searchTerm,
        productsCount: products.totalCount,
        submissionMethod,
        searchMethod: getSearchMethod(products.searchType, getRedirectMethod(searchRedirect)),
        redirectPage,
        isRealSearch,
    });
}
