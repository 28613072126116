import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useUniversalEffect } from '../useUniversalEffect';
import { DataFetchingThunkAction, BellaThunkDispatch } from '../../redux/types';
import { FetchManagerContext } from './FetchManagerContext';

const useFetchingAction = (thunkAction?: DataFetchingThunkAction, deps?: any[] | undefined) => {
    const dispatch = useDispatch() as BellaThunkDispatch<Promise<void>>;
    const fetchManager = useContext(FetchManagerContext);
    useUniversalEffect(() => {
        if (thunkAction) {
            fetchManager.dispatchFetchingAction(thunkAction, dispatch);
        }
    }, deps);
};

export default useFetchingAction;
