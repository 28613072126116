import styled, { css } from 'styled-components';
import React, { ReactElement } from 'react';
import useListingPageUrlState from '../hooks/useListingPageUrlState';
import AppliedFiltersSlider from './AppliedFiltersSlider';
import useConfig from 'AppShell/hooks/useConfig';
import ClearAllFiltersButton from 'Client/components/Generic/AppliedFilters/ClearAllFiltersButton';
import useGa from 'Client/hooks/useGa';
import { pathFilterNames } from 'Client/redux/products/types';
import { forDesktop } from '@westwing/ui-kit/breakPoints';

const StyledFiltersSliderWrapper = styled.div`
    margin-bottom: 20px;

    ${({ theme }) =>
        theme.featureToggles.p100FreistellerImages &&
        css`
            padding: 0 10px;
            ${forDesktop`
                padding: 0;
            `}
        `}
`;

const StyledFiltersWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const AppliedFiltersSliderContainer = (): ReactElement => {
    const [{ appliedFilters, listingPageKey }, setListingPageStateFunc] = useListingPageUrlState();
    const { currency } = useConfig();
    const gaTracking = useGa();

    const appliedFilterValues = appliedFilters
        .map(filter => filter.values)
        .reduce((allValues, values) => [...allValues, ...values], []);

    const clearFilters = () => {
        gaTracking.trackFilters({
            action: gaTracking.actions.Filters.FilterReset,
            label: `/${listingPageKey}/`,
        });
        setListingPageStateFunc({ appliedFilters: [] });
    };

    const isOnlyCategoryFilterApplied =
        appliedFilters.length === 1 && appliedFilters[0].name === pathFilterNames.categories;

    return appliedFilters.length === 0 || isOnlyCategoryFilterApplied ? (
        <span data-testid="empty-filter" />
    ) : (
        <StyledFiltersSliderWrapper>
            <StyledFiltersWrapper>
                <AppliedFiltersSlider
                    appliedFiltersProps={appliedFilters}
                    updateFilters={setListingPageStateFunc}
                    currency={currency}
                />
            </StyledFiltersWrapper>
            {appliedFilterValues.length >= 3 ? (
                <ClearAllFiltersButton handleClearButtonClick={clearFilters} />
            ) : (
                <span />
            )}
        </StyledFiltersSliderWrapper>
    );
};

export default AppliedFiltersSliderContainer;
