import React, { FC, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { forDesktop } from '@westwing/ui-kit/breakPoints';
import StyledSlider from './StyledSlider';
import NavigationItem, { NavigationBaseData } from './NavigationItem';
import viewport from 'Common/helpers/viewport';

export interface BubblesSliderProps {
    bubbles: NavigationBaseData[];
    testId?: string;
    onItemClick?: (label: string, isSelected: boolean) => void;
    className?: string;
    height?: '35px' | '40px';
}

const scrollMargin = 30;

const BubbleWrapper = styled(StyledSlider)`
    & > a {
        margin: 0 7px 7px 0;
        background-color: ${({ theme }) => theme.colors.ivorey};
    }
`;

const BubblesSlider: FC<BubblesSliderProps> = ({
    bubbles,
    onItemClick = () => {},
    testId,
    className,
    height = '35px',
}) => {
    const sliderRef = useRef<HTMLDivElement>(null);
    const navigationItemInView = useRef<HTMLAnchorElement>();

    const isLeaf = bubbles.some(bubble => bubble.isSelected);
    const navItemInViewIndex = isLeaf ? bubbles.findIndex(bubble => bubble.isSelected) : 0;

    useEffect(() => {
        const setSliderScrollPosition = (scrollX: number): void => {
            if (sliderRef.current) {
                sliderRef.current.scrollLeft = scrollX;
            }
        };

        if (sliderRef.current && navigationItemInView.current) {
            viewport.scrollIntoViewHorizontally({
                container: sliderRef.current,
                child: navigationItemInView.current,
                margin: scrollMargin,
                setScrollPosition: setSliderScrollPosition,
            });
        }
    });

    return (
        <BubbleWrapper ref={sliderRef as any} data-testid={testId} className={className}>
            {bubbles.map((bubble, index) => (
                <NavigationItem
                    innerRef={node => {
                        if (index === navItemInViewIndex) {
                            navigationItemInView.current = node || undefined;
                        }
                    }}
                    to={bubble.link}
                    categoryName={bubble.name}
                    key={bubble.name}
                    isSelected={bubble.isSelected}
                    onClick={onItemClick}
                    height={height}
                />
            ))}
        </BubbleWrapper>
    );
};

export default React.memo(BubblesSlider);

export const CenteredBubblesSliderWrapper = styled.div`
    ${forDesktop`
        display: flex;
    `}
`;
