import React from 'react';
import styled from 'styled-components';
import { body2, plainTextStyle } from '@westwing/ui-kit/typography';
import { forDesktop } from '@westwing/ui-kit/breakPoints';
import useBellaTranslator from 'Client/hooks/useTranslator';
import useFeatureToggle from 'Client/hooks/useFeatureToggle';

const StyledPriceDisclaimer = styled.div`
    ${plainTextStyle}
    margin-top: 10px;
    padding: 0 10px;

    ${forDesktop`
        margin-top: 16px;
        font-size: 14px;
    `}
`;

const StyledPriceDisclaimerOmnibusDirective = styled.div`
    ${body2}
    color: ${({ theme }) => theme.colors.charcoal60};
    margin-top: 4px;
`;

const StyledOmnibusDirectivePrice = styled.span`
    display: inline-flex;
`;

const StyledPriceDisclaimerPageCentered = styled(StyledPriceDisclaimer)`
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
`;

type PriceDisclaimerProps = {
    pageHasSpecialPrice: boolean;
    className?: string;
};

const PriceDisclaimer = ({
    pageHasSpecialPrice,
    className,
    centered,
}: PriceDisclaimerProps & {
    centered?: boolean;
}) => {
    const t = useBellaTranslator();

    if (!pageHasSpecialPrice) {
        return null;
    }

    const PriceDisclaimerComponent = centered ? StyledPriceDisclaimerPageCentered : StyledPriceDisclaimer;

    return (
        <PriceDisclaimerComponent className={className} id="price-disclaimer">
            * {t('last30daysprice_footnote')}
        </PriceDisclaimerComponent>
    );
};

const PriceDisclaimerOmnibusDirective = ({ originalPrice }: { originalPrice?: string }) => {
    const t = useBellaTranslator();
    const { p100EnableNewOmnibusDirective } = useFeatureToggle();
    if (!p100EnableNewOmnibusDirective || !originalPrice) {
        return null;
    }

    return (
        <StyledPriceDisclaimerOmnibusDirective id="price-disclaimer">
            {t('last30daysprice_long')} <StyledOmnibusDirectivePrice>{originalPrice}</StyledOmnibusDirectivePrice>
        </StyledPriceDisclaimerOmnibusDirective>
    );
};

const PriceDisclaimerDefault = (props: PriceDisclaimerProps) => <PriceDisclaimer {...props} />;

const PriceDisclaimerCentered = (props: PriceDisclaimerProps) => <PriceDisclaimer {...props} centered />;

PriceDisclaimerDefault.Centered = PriceDisclaimerCentered;
PriceDisclaimerDefault.OmnibusDirective = PriceDisclaimerOmnibusDirective;

export default PriceDisclaimerDefault;
