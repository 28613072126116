import React, { ReactElement, MouseEvent } from 'react';
import styled, { css } from 'styled-components';
import { forDesktop, forIE, forSemiWideScreens, forTablet } from '@westwing/ui-kit/breakPoints';
import { caption1, plainTextStyle } from '@westwing/ui-kit/typography';
import ProductFilterIcon from '@westwing/ui-kit/Icons/Svgs/ProductFilterIcon';
import { AppliedFilter, Filter, NewFilterValues, Sorting, SortingParameters, Translator } from './types';
import SortBox from './SortBox';
import DesktopFiltersStrip from './DesktopFiltersStrip';
import ColumnSelection from 'Client/components/Generic/FilterAndSortBox/ColumnSelection';
import useP100FilterRedesign from 'Client/components/Generic/FilterAndSortBox/useP100FilterRedesign';
import { BellaTestIds } from 'Common/qaIds';
import useFeatureToggle from 'Client/hooks/useFeatureToggle';
import { useCurrentPageType } from 'Client/hooks/UrlManager/pageTypeHooks';
import { ProductListingPages } from 'Client/hooks/UrlManager/types';

const SHIFT_TO_TOP = 53;

const StyledFilterAndSortWrapper = styled.div<{ shiftToTop: boolean }>`
    ${({ shiftToTop }) =>
        shiftToTop &&
        css`
            ${forDesktop`
                margin-top: -${SHIFT_TO_TOP}px;
            `}
        `}
`;

const StyledSpan = styled.span`
    ${plainTextStyle}
    align-self: center;
`;

const StyledFilterAndSortStrip = styled.div<{ isBoxed?: boolean; preventShift?: boolean; withoutBackground?: boolean }>`
    padding: ${({ theme }) => (theme.featureToggles.p100FreistellerImages ? '7px 14px' : '9px 4px 7px')};
    color: ${({ theme }) => theme.colors.charcoal100};
    display: flex;
    align-self: center;
    border-top: ${({ theme, preventShift }) => (preventShift ? 'none' : `1px solid ${theme.colors.charcoal20}`)};

    ${({ isBoxed, withoutBackground, theme }) =>
        css`
            ${forDesktop`
                margin: 0 0 10px;
                padding: 0 ${isBoxed || theme.featureToggles.p100FreistellerImages ? 0 : '20px'} ${
                withoutBackground && '0px 0px'
            };
                background-color: ${isBoxed || withoutBackground ? 'transparent' : theme.colors.charcoal3};
            `}
        `}

    ${forIE`
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
    `}
`;

const SortingBox = styled(SortBox)<{ hide?: boolean }>`
    text-align: right;
    & > select {
        cursor: pointer;
    }

    ${({ hide }) =>
        css`
            ${forDesktop`
                grid-area: ${hide ? '' : 'sort'};
                display: ${hide ? 'none' : 'inherit'};
                margin-top: 12px;
                height: min-content;
            `}
        `}
`;

const DesktopFilterBox = styled(DesktopFiltersStrip)`
    flex-grow: 1;
    ${forIE`
        -ms-grid-column: 1;
        -ms-grid-row: 1;
    `}
`;

export const StyledFilterAndSortButton = styled.button`
    text-transform: capitalize;
    height: 32px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    ${forDesktop`
        display: none;
    `}
    ${forIE`
        -ms-grid-column: 2;
        -ms-grid-row: 1;
    `}
`;

const StyledFilterAndSortIcon = styled(ProductFilterIcon)<{ width: string }>`
    display: inline-block;
    width: ${props => props.width}px;
    height: 100%;
    margin-right: 12px;
    margin-bottom: 4px;
    color: ${({ theme }) => theme.colors.charcoal100};
    vertical-align: bottom;
`;

const StyledSortingBox = styled(SortingBox)<{ hide?: boolean }>`
    display: none;

    ${({ hide, theme }) =>
        css`
            ${forDesktop`
                display: ${hide ? 'none' : 'grid'};
                color: ${theme.colors.charcoal100};
                width: 241px;
                margin-left: auto;
                margin-bottom: 15px;
            `}
        `}
`;

const StyledProductCount = styled.span<{ $desktop?: boolean }>`
    ${caption1}
    color: ${({ theme }) => theme.colors.charcoal50};
    padding-left: 8px;

    ${({ theme }) =>
        theme.featureToggles.p100FreistellerImages
            ? css`
                  ${forTablet`
                    margin-top: 10px;
                    padding: 7px 4px 7px;
                  `}
              `
            : ''}
    ${({ $desktop }) =>
        $desktop &&
        css`
            display: none;
            ${forDesktop`
                display: inline;
            `}
        `}
`;

interface TotalCountProps {
    totalCount: number;
    translator: Translator;
    className?: string;
    $desktop?: boolean;
}

const TotalCount = ({ totalCount, translator, className, $desktop }: TotalCountProps): ReactElement | null => {
    // total count default value is -1 (src/client/redux/products/reducers.ts)
    if (totalCount <= 0) {
        return null;
    }

    return (
        <StyledProductCount
            data-testid={BellaTestIds.plpFilterProductsCountMobile}
            className={className}
            $desktop={$desktop}
        >
            {totalCount} {translator('Products')}
        </StyledProductCount>
    );
};

export interface FilterAndSortBoxProps {
    handleFiltersClick: (event: MouseEvent) => void;
    onSortValueChange?: (newSortValue: SortingParameters, labelKey: string) => void;
    sortingOptions?: Sorting[];
    sortingValue?: SortingParameters;
    filters: Filter[];
    translator: Translator;
    appliedFilters: AppliedFilter[];
    onFilterValueChange: (newValue: NewFilterValues) => void;
    handleOnSortClick?: () => void;
    handleExpandableFilterClick?: (filter: Filter) => void;
    handleShowAll?: () => void;
    className?: string;
    preventShift?: boolean;
    totalCount?: number;
}

const SortColumnWrapper = styled.div`
    display: flex;
    margin-left: auto;
    align-items: center;

    ${forDesktop`
        align-items: start;
    `}
`;

const ColumnSelectionWrapper = styled.div`
    display: none;
    margin-left: 32px;
    line-height: 24px;
    margin-top: -2px;

    ${forSemiWideScreens`
        display: block;
        margin-top: 10px;
    `}
`;

const FilterAndSortBox = ({
    handleFiltersClick,
    handleOnSortClick,
    handleExpandableFilterClick,
    handleShowAll,
    onSortValueChange,
    sortingValue,
    sortingOptions,
    filters,
    translator,
    appliedFilters,
    onFilterValueChange,
    className,
    preventShift,
    totalCount = 0,
}: FilterAndSortBoxProps): ReactElement => {
    const { isBoxed, hideDefaultSort } = useP100FilterRedesign();
    const { p100FourColumnPlp, p100FreistellerImages } = useFeatureToggle();
    const sendSortValueChange = (newSortValue: SortingParameters) => {
        const labelKey = `${newSortValue.sort}-${newSortValue.order}`;
        if (onSortValueChange) {
            onSortValueChange(newSortValue, labelKey);
        }
    };

    const { pageType } = useCurrentPageType();
    const isListingPage = ProductListingPages.includes(pageType);

    return (
        <StyledFilterAndSortWrapper shiftToTop={!preventShift && hideDefaultSort}>
            {/* this block is added for p100FiltersRedesign test */}
            {sortingValue && sortingOptions && (
                <StyledSortingBox
                    data-testid={BellaTestIds.externalSortBox}
                    hide={!hideDefaultSort}
                    value={sortingValue}
                    sorting={sortingOptions}
                    onSortOpen={handleOnSortClick}
                    onSortValueChange={sendSortValueChange}
                    translator={translator}
                />
            )}
            <StyledFilterAndSortStrip
                className={className}
                isBoxed={isBoxed}
                preventShift={preventShift}
                withoutBackground={p100FreistellerImages}
            >
                <StyledFilterAndSortButton
                    type="button"
                    onClick={handleFiltersClick}
                    data-testid={BellaTestIds.plpFilterToggleButton}
                >
                    <StyledFilterAndSortIcon width="20" data-testid="listing-page-filter-icon" />
                    <StyledSpan>
                        {translator('Filter')}
                        <TotalCount totalCount={totalCount} translator={translator} />
                    </StyledSpan>
                </StyledFilterAndSortButton>
                <DesktopFilterBox
                    filters={filters}
                    translator={translator}
                    appliedFilters={appliedFilters}
                    onFilterValueChange={onFilterValueChange}
                    handleExpandableFilterClick={handleExpandableFilterClick}
                    handleShowAll={handleShowAll}
                />
                <SortColumnWrapper>
                    {p100FreistellerImages && <TotalCount totalCount={totalCount} translator={translator} $desktop />}
                    {sortingValue && sortingOptions && (
                        <SortingBox
                            data-testid={BellaTestIds.inlineSortBox}
                            hide={hideDefaultSort}
                            value={sortingValue}
                            sorting={sortingOptions}
                            onSortOpen={handleOnSortClick}
                            onSortValueChange={sendSortValueChange}
                            translator={translator}
                        />
                    )}
                    {p100FourColumnPlp && isListingPage && (
                        <ColumnSelectionWrapper>
                            <ColumnSelection />
                        </ColumnSelectionWrapper>
                    )}
                </SortColumnWrapper>
            </StyledFilterAndSortStrip>
        </StyledFilterAndSortWrapper>
    );
};

export default FilterAndSortBox;
