import useListingPageUrlState from './useListingPageUrlState';
import {
    updateFilterValue,
    getAppliedFilterGroup,
} from 'Client/components/Generic/GenericFiltersFlyout/filterValueService';
import { AppliedFilter, Filter, NewFilterValues } from 'Client/components/Generic/FilterAndSortBox/types';

export const useFilterState = (filter: Filter): [AppliedFilter, (newValue: NewFilterValues) => void] => {
    const [urlState, setListingPageState] = useListingPageUrlState();
    const { appliedFilters } = urlState;

    const onFilterValueChange = (newValue: NewFilterValues): void => {
        const newAppliedFilters = updateFilterValue(newValue, appliedFilters);
        setListingPageState({ appliedFilters: newAppliedFilters }, true);
    };

    const appliedFilter = getAppliedFilterGroup(appliedFilters, filter);

    return [appliedFilter, onFilterValueChange];
};

export default useFilterState;
