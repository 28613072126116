import React from 'react';
import styled from 'styled-components';
import { header1, caption1 } from '@westwing/ui-kit/typography';
import ActionButton from '@westwing/ui-kit/ActionButton';
import useRouter from 'AppShell/hooks/useRouter';
import useTranslator from '../../hooks/useTranslator';

const MainText = styled.h1`
    color: ${({ theme }) => theme.colors.charcoal100};
    ${header1}
    text-align: center;
    margin: 2em 30px 0.5em 30px;
`;

const StyledButton = styled(ActionButton)`
    margin: 2em 30px;
    max-width: 200px;
`;

const SecondaryText = styled.p`
    color: ${({ theme }) => theme.colors.charcoal100};
    text-align: center;
    ${caption1}
`;

const Wrapper = styled.div`
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const NoFilterResults: React.FC = () => {
    const t = useTranslator();
    const { history } = useRouter();
    const handleBackBtnClick = () => {
        history.goBack();
    };

    return (
        <Wrapper>
            <MainText data-testid="plp-no-filter-results-main-text">{t('Sorry_we_cannot_find_products')}</MainText>
            <SecondaryText data-testid="plp-no-filter-results-secondary-text">{t('No_products_results')}</SecondaryText>
            <StyledButton data-testid="plp-no-filter-results-action-button" onClick={handleBackBtnClick} kind="primary">
                {t('Back')}
            </StyledButton>
        </Wrapper>
    );
};

export default NoFilterResults;
