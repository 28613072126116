import memoizeOne from 'memoize-one';
import { Dictionary } from 'Utils/types/utility';
import { CategoryEntity, NormalizedCategoriesData } from '../../../redux/category/types';
import { CrossCategoryNavigation } from '../../../redux/products/types';
import { getFirstLevelParent } from '../SideCategoryNavigation/SideNavigation';

const emptyParentCategory = { isRoot: true, link: '' };

const toLink = (urlKey: string) => `/${urlKey}/`;

const getChildrenAndParent = (
    categoryUrlKey: string,
    entities: Dictionary<CategoryEntity>,
    urlKeys: Dictionary<string>
) => {
    const categoryId = urlKeys[categoryUrlKey];
    const category = entities[categoryId];

    if (!category || category.isHidden) {
        return null;
    }

    if (category.children && category.children.length > 0) {
        // Filter out hidden categories, so that if there is no
        // child category, the sibling (parents children) categories
        // are shown.
        const children = category.children.filter((c: string) => entities[c] && !entities[c].isHidden);

        if (children.length > 0) {
            return { children, parent: category.parent };
        }
    }

    if (!category.parent) {
        return null;
    }

    const parentEntity = entities[category.parent];
    const siblings =
        parentEntity.children && parentEntity.children.filter((c: string) => entities[c] && !entities[c].isHidden);
    return { children: siblings, parent: parentEntity.id };
};

export const unmemoizedGetNavigationForCategory = (
    categoryUrlKey: string,
    categoriesData: NormalizedCategoriesData | null
): CrossCategoryNavigation => {
    if (!categoryUrlKey || !categoriesData) {
        return { categories: [], parentCategory: emptyParentCategory };
    }

    const { entities, urlKeys } = categoriesData;
    const categoryId = urlKeys[categoryUrlKey];
    const category = entities[categoryId];

    if (!category || getFirstLevelParent(category, entities).isHidden) {
        return { categories: [], parentCategory: emptyParentCategory };
    }

    const childrenAndParent = getChildrenAndParent(categoryUrlKey, entities, urlKeys);

    if (!childrenAndParent) {
        return { categories: [], parentCategory: emptyParentCategory };
    }

    const { children, parent } = childrenAndParent;
    const parentEntity = entities[parent || ''];

    const categories = (children || []).map(id => {
        const { name, urlKey, isHidden } = entities[id];
        const isSelected = urlKey === categoryUrlKey;
        const itemUrlKey = isSelected ? parentEntity.urlKey : urlKey;
        return {
            name,
            isHidden,
            isSelected: urlKey === categoryUrlKey,
            link: toLink(itemUrlKey),
        };
    });

    const parentCategory = parentEntity
        ? {
              isRoot: parent === null,
              link: toLink(parentEntity.urlKey),
          }
        : emptyParentCategory;
    return { categories, parentCategory };
};

export const getNavigationForCategory = memoizeOne(unmemoizedGetNavigationForCategory);

export default {};
