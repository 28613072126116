import { useLogger } from 'AppShell/logger/LoggerProvider';
import React, { useEffect } from 'react';

const MissingData: React.FC<{ message: string; data?: Object }> = React.memo(({ message, data }) => {
    const logger = useLogger();
    useEffect(() => logger.info(message, data), [logger, message, data]);

    return null;
});

export default MissingData;
