import React, { FC } from 'react';
import styled from 'styled-components';

const StyledCountLabel = styled.div`
    display: inline-flex;
    box-sizing: border-box;
    min-height: 100%;
    align-items: center;
`;

export interface CountLabelProps {
    currentPage: number;
    totalPagesCount: number;
}

const CountLabel: FC<CountLabelProps> = ({ currentPage, totalPagesCount }) =>
    totalPagesCount > 0 ? (
        <StyledCountLabel key={`${currentPage - totalPagesCount}`}>
            {currentPage}/{totalPagesCount}
        </StyledCountLabel>
    ) : null;

export default CountLabel;
