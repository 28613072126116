import React from 'react';
import styled, { css } from 'styled-components';
import { forDesktop, forIE, forTablet } from '@westwing/ui-kit/breakPoints';
import { button } from '@westwing/ui-kit/typography';
import ProductSortByIcon from '@westwing/ui-kit/Icons/Svgs/ProductSortByIcon';
import { Sorting, SortingParameters, Translator } from './types';
import useP100FilterRedesign from 'Client/components/Generic/FilterAndSortBox/useP100FilterRedesign';
import useFeatureToggle from 'Client/hooks/useFeatureToggle';

interface Props {
    onSortValueChange: (newSortValue: SortingParameters) => void;
    onSortOpen?: () => void;
    sorting: Sorting[];
    value: SortingParameters;
    translator: Translator;
    className?: string;
    'data-testid'?: string;
}

const StyledFilterAndSortIcons = styled(ProductSortByIcon)`
    display: inline-block;
    height: 24px;
    width: 30px;
    vertical-align: middle;
`;

const StyledSortText = styled.span<{ highSortPosition?: boolean }>`
    ${button}
    vertical-align: middle;
    white-space: nowrap;

    ${({ highSortPosition }) =>
        css`
            ${forDesktop`
                font-size:  ${highSortPosition ? '12px' : '14px'};
            `}
        `}
`;

export const StyledFilterAndSortButtons = styled.div`
    text-transform: capitalize;
    display: grid;
    position: relative;
    grid-template-columns: 1fr;
    align-items: center;

    ${forIE`
        display: -ms-grid;
        -ms-grid-columns: 1fr;
    `}
`;

export const StyledSortDropdown = styled.select`
    ${button}
    position: absolute;
    width: 100%;
    color: ${({ theme }) => theme.colors.charcoal100};
    height: 100%;
    opacity: 0;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row: 1;
    ${forIE`
        -ms-grid-row: 1;
        -ms-grid-column: 1;
        -ms-grid-column-span: 3;
    `}
`;

const StyledIconAndTextWrapper = styled.div<{ highSortPosition?: boolean }>`
    grid-row: 1;
    grid-column: 1;

    ${forIE`
        -ms-grid-row: 1;
        -ms-grid-column: 1;
    `}

    ${({ highSortPosition }) =>
        css`
            ${forDesktop`
                display: flex;
                justify-content: ${highSortPosition ? 'flex-start' : 'flex-end'};
                flex-direction: ${highSortPosition ? 'row-reverse' : 'row'};
                align-items: center;
                padding-bottom: 5px;
            `}
        `}
`;

const StyledSelectedSortOption = styled.span`
    display: none;
    ${forTablet`
        display: inline;
    `}
`;

const SortBox = ({
    value,
    onSortOpen,
    onSortValueChange,
    sorting,
    className,
    translator,
    'data-testid': dataTestId,
}: Props) => {
    const { hideDefaultSort } = useP100FilterRedesign();
    const { p100FreistellerImages } = useFeatureToggle();
    const sortingOptions: { [x: string]: SortingParameters } = sorting.reduce(
        (acc, val) => ({
            ...acc,
            [val.displayName]: { sort: val.name, order: val.order },
        }),
        {}
    );

    const selectedOption =
        Object.keys(sortingOptions).find(
            key => sortingOptions[key].order === value.order && sortingOptions[key].sort === value.sort
        ) || Object.keys(sortingOptions)[0];

    const sendSortValueChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newSortValue: SortingParameters = sortingOptions[e.target.value];
        onSortValueChange(newSortValue);
    };

    return (
        <StyledFilterAndSortButtons className={className} data-testid={dataTestId}>
            <StyledIconAndTextWrapper highSortPosition={hideDefaultSort}>
                <StyledFilterAndSortIcons />
                <StyledSortText highSortPosition={hideDefaultSort}>
                    {translator('Sort')}
                    {!p100FreistellerImages && (
                        <StyledSelectedSortOption>{`: ${selectedOption}`}</StyledSelectedSortOption>
                    )}
                </StyledSortText>
            </StyledIconAndTextWrapper>
            {/* eslint-disable-next-line styled-components-a11y/no-onchange */}
            <StyledSortDropdown value={selectedOption} onChange={sendSortValueChange} onClick={onSortOpen}>
                {Object.keys(sortingOptions).map(key => (
                    <option key={key} value={key}>
                        {translator(key)}
                    </option>
                ))}
            </StyledSortDropdown>
        </StyledFilterAndSortButtons>
    );
};

export default SortBox;
