import React, { FC, ReactElement } from 'react';
import styled from 'styled-components';
import BellaHybridLink from '../Generic/Links/BellaHybridLink';

type Direction = 'next' | 'prev';

interface OptionalLinkProps {
    to?: string;
    handleClick: (event: React.MouseEvent<HTMLElement>) => void;
    direction: Direction;
}

const OptionalLink: FC<OptionalLinkProps> = ({ to, handleClick, direction, ...props }): ReactElement =>
    to ? <BellaHybridLink testId={direction} onClick={handleClick} {...props} to={to} /> : <span {...props} />;

const StyledNavigationLink = styled(OptionalLink)`
    display: inline-block;
    box-sizing: border-box;
    width: 115px;
    height: 100%;
    border: 1px solid ${({ theme }) => theme.colors.charcoal100};
    font-size: 13px;
    letter-spacing: 0.06em;
    text-align: center;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.charcoal100};
    ${props => (props.to ? '' : 'visibility: hidden;')}
`;

export interface NavigationButtonProps {
    to?: string;
    handleClick: (event: React.MouseEvent<HTMLElement>) => void;
    direction: Direction;
}

const NavigationButton: FC<NavigationButtonProps> = ({ to, handleClick, direction, children }): ReactElement => (
    <StyledNavigationLink direction={direction} handleClick={handleClick} to={to} aria-hidden={!to}>
        {children}
    </StyledNavigationLink>
);

export default NavigationButton;
