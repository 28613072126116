import React from 'react';
import styled from 'styled-components';
import { body2Bold } from '@westwing/ui-kit/typography';
import { pathFilterNames } from '../../redux/products/types';
import ErrorBoundary from '../Generic/ErrorBoundaries';
import SkipRender from '../Generic/SkipRender';
import useTranslator from '../../hooks/useTranslator';
import { Filter, NewFilterValues } from '../Generic/FilterAndSortBox/types';
import useListingPageUrlState from './hooks/useListingPageUrlState';
import CategoryFilters from 'Client/components/Generic/GenericFiltersFlyout/CategoryFilters';
import {
    sanitizeFilters,
    normalizeFilters,
    getAppliedFilterGroup,
    updateFilterValue,
} from 'Client/components/Generic/GenericFiltersFlyout/filterValueService';
import { BellaTestIds } from 'Common/qaIds';

const Label = styled.label`
    ${body2Bold}
    color: ${({ theme }) => theme.colors.charcoal100};
    line-height: 3.5;
`;

interface Props {
    filters: Filter[];
}
const CategoryFilterBucket: React.FC<Props> = ({ filters }) => {
    const [urlState, setListingPageState] = useListingPageUrlState();
    const t = useTranslator();

    const onFilterValueChange = (newValue: NewFilterValues): void => {
        const newAppliedFilters = updateFilterValue(newValue, urlState.appliedFilters);
        setListingPageState({ appliedFilters: newAppliedFilters }, true);
    };

    const categoryFilter = filters
        .filter(filter => filter.name === pathFilterNames.categories)
        .filter(filter => sanitizeFilters(filter, urlState.listingPageType))
        .map(normalizeFilters)
        .pop();

    if (!categoryFilter) {
        return <SkipRender message={`Could not find category filter ${pathFilterNames.categories}`} />;
    }

    const maybeFilter = getAppliedFilterGroup(urlState.appliedFilters, categoryFilter);
    const filterValue = maybeFilter ? maybeFilter.values : [];

    return (
        <ErrorBoundary boundaryName={`Filters ${categoryFilter.name}`} fallbackComponent={null}>
            <div>
                <Label data-testid={BellaTestIds.plpSidebarCategoriesLabelDesktop}>{`${t('by')} ${
                    categoryFilter.displayName
                }`}</Label>
                <CategoryFilters
                    filterValue={filterValue}
                    values={categoryFilter.values}
                    handleValueChange={onFilterValueChange}
                    isDesktopFilter
                />
            </div>
        </ErrorBoundary>
    );
};

export default CategoryFilterBucket;
