import React from 'react';
import styled, { css } from 'styled-components';
import { forDesktop, forMidWideScreens } from '@westwing/ui-kit/breakPoints';
import { Direction, StyledArrowIcon } from '@westwing/ui-kit/Icons/StyledIcons';
import useListingPageTracking from './hooks/useListingPageTracking';
import { BellaTestIds } from 'Common/qaIds';
import { llpBackToTopBreakpoint } from 'Client/components/GlobalStyles/theme';
import useInView from 'Client/hooks/dom/useInView';

const Wrapper = styled.div`
    grid-area: back;
    display: none;
    height: 100%;
    padding-top: 200vh;
    top: 0;
    ${({ theme }) => css`
        ${forDesktop`
            display: block;
            width: 0;
            position: absolute;
            right: 70px;
            z-index: ${theme.zIndexBelowContentOverlay};
        `}
    `}

    ${forMidWideScreens`
        right: 94px;
    `}

    ${llpBackToTopBreakpoint`
        right: 32px;
    `}
`;

const Button = styled.button`
    position: sticky;
    top: calc(100vh - 60px);
    border: 1px solid ${({ theme }) => theme.colors.charcoal100};
    width: ${({ theme }) => theme.buttonHeight};
    height: ${({ theme }) => theme.buttonHeight};
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 4px;
    background: ${({ theme }) => theme.colors.white};
`;

const BackToTopButton = () => {
    const { trackBackToTopClick, trackBackToTopImpression } = useListingPageTracking();

    const containerRef = React.useRef<HTMLButtonElement>(null);
    useInView({
        containerRef,
        options: { threshold: 0.5 },
        onVisibleCallback: trackBackToTopImpression,
    });

    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        event.stopPropagation();
        trackBackToTopClick();
        handleScrollToTop();
    };

    return (
        <Wrapper data-testid={BellaTestIds.backToTopButton}>
            <Button ref={containerRef} onClick={handleClick}>
                <StyledArrowIcon direction={Direction.Up} size={9} />
            </Button>
        </Wrapper>
    );
};

export default BackToTopButton;
