import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { body2, body2Bold } from '@westwing/ui-kit/typography';
import { forIE } from '@westwing/ui-kit/breakPoints';
import RadioButton from '@westwing/ui-kit/RadioButton';
import { FilterValue } from './filterValueService';
import { ListFiltersLayout, CountLabel } from './CommonFilterElements';
import { StyledFilterItemWrapper } from './StandardFilters';
import { BellaTestIds } from 'Common/qaIds';
import { pathFilterNames } from 'Client/redux/products/types';
import StateInterface from 'Client/redux/types';
import { CategoryEntity } from 'Client/redux/category/types';
import { NewFilterValues } from 'Client/components/Generic/FilterAndSortBox/types';
import { Dictionary } from 'Utils/types/utility';

interface FromState {
    categoryEntities: Dictionary<CategoryEntity>;
    categoryUrlKeys: Dictionary<string>;
    categories: string[];
}

interface OwnProps {
    handleValueChange: (value: NewFilterValues) => void;
    values: Dictionary<number>;
    filterValue: FilterValue;
    isDesktopFilter?: boolean;
}

type Props = FromState & OwnProps;

const RadioButtonWithOffset = styled(RadioButton)<{ offsetLevel: number }>`
    padding-left: ${({ offsetLevel }) => offsetLevel * 20}px;
`;

const getOpenNodes = (
    activeUrlKey: string,
    categoryEntities: Dictionary<CategoryEntity>,
    categoryUrlKeys: Dictionary<string>
) => {
    if (!activeUrlKey) {
        return [];
    }

    const activeCategoryId = categoryUrlKeys[activeUrlKey];

    const openNodes: string[] = [];

    let nodeId = activeCategoryId;
    while (nodeId) {
        const currentNode = categoryEntities[nodeId];
        if (currentNode.children) {
            openNodes.push(nodeId);
        }
        nodeId = currentNode.parent || '';
    }

    return openNodes;
};

const CategoryText = styled.button<{ offsetLevel: number; isActive: boolean }>`
    appearance: none;
    outline: none;
    ${body2}
    text-align: left;
    cursor: pointer;
    padding-left: ${({ offsetLevel }) => offsetLevel * 20}px;
    color: ${({ theme }) => theme.colors.charcoal100};
    ${({ isActive }) => (isActive ? body2Bold : '')}
    ${({ isActive }) => forIE`
        font-weight: ${isActive ? 'bold' : ''};
    `}
`;

type FiltersArray = Array<CategoryEntity & { level: number }>;

const CategoryFilter: React.FunctionComponent<Props> = ({
    filterValue,
    values,
    categoryEntities,
    categories,
    categoryUrlKeys,
    handleValueChange,
    isDesktopFilter,
}) => {
    const activeUrlKey = Array.isArray(filterValue) ? filterValue[0] : filterValue || '';

    const openNodes = getOpenNodes(activeUrlKey, categoryEntities, categoryUrlKeys);
    const filters: FiltersArray = [];

    const fillFiltersList = (categoryIds: string[], level: number) => {
        const activeCategories = categoryIds.filter(cat => !!values[cat]);
        activeCategories.forEach(catId => {
            const category = categoryEntities[catId];
            filters.push({
                ...category,
                level,
            });
            if (openNodes.includes(catId) && category.children) {
                fillFiltersList(category.children, level + 1);
            }
        });
    };

    fillFiltersList(categories, 0);
    const visibleCategoryFilters = filters.filter(cat => !cat.isHidden);

    return (
        <ListFiltersLayout>
            {visibleCategoryFilters.map(filter => (
                <StyledFilterItemWrapper
                    key={`${filter.name}-${values[filter.id]}`}
                    data-testid={BellaTestIds.categoryFilterItem}
                >
                    {!isDesktopFilter ? (
                        <RadioButtonWithOffset
                            offsetLevel={filter.level}
                            value={filter.urlKey}
                            text={filter.name}
                            testId={BellaTestIds.radioBtnLabel}
                            isChecked={activeUrlKey === categoryEntities[filter.id].urlKey}
                            onChange={(_, value) => {
                                handleValueChange({
                                    name: pathFilterNames.categories,
                                    displayName: '',
                                    value,
                                    lastChangedOption: { name: pathFilterNames.categories, value },
                                });
                            }}
                        />
                    ) : (
                        <CategoryText
                            offsetLevel={filter.level}
                            isActive={activeUrlKey === categoryEntities[filter.id].urlKey}
                            onClick={() => {
                                handleValueChange({
                                    name: pathFilterNames.categories,
                                    displayName: '',
                                    value: filter.urlKey,
                                    lastChangedOption: { name: pathFilterNames.categories, value: filter.urlKey },
                                });
                            }}
                        >
                            {filter.name}
                        </CategoryText>
                    )}
                    <CountLabel count={values[filter.id]} />
                </StyledFilterItemWrapper>
            ))}
        </ListFiltersLayout>
    );
};

const mapStateToProps = (state: StateInterface): FromState => {
    if (!state.categories.normalized) {
        return {
            categories: [],
            categoryEntities: {},
            categoryUrlKeys: {},
        };
    }

    return {
        categoryEntities: state.categories.normalized.entities,
        categories: state.categories.normalized.categories,
        categoryUrlKeys: state.categories.normalized.urlKeys,
    };
};

const ConnectedCategoryFilter = connect(mapStateToProps)(CategoryFilter);

export default ConnectedCategoryFilter;
