export const getTotalPages = (totalCount: number, perPage: number) => Math.ceil(totalCount / perPage);

const isOutOfRange = (page: number, totalPages: number): boolean => page > totalPages || page < 1;

export const getCurrentPage = (totalCount: number, page: number, perPage: number): number => {
    const totalPages = getTotalPages(totalCount, perPage);

    if (isOutOfRange(page, totalPages)) {
        return 1;
    }

    return page;
};

export const getPageCountData = (totalCount: number, page: number, perPage: number) => {
    const currentPage = getCurrentPage(totalCount, page, perPage);
    const totalPages = getTotalPages(totalCount, perPage);

    const prevPageNumber = currentPage > 1 ? currentPage - 1 : null;
    const nextPageNumber = currentPage < totalPages ? currentPage + 1 : null;

    return {
        totalPages,
        currentPage,
        prevPageNumber,
        nextPageNumber,
    };
};

export default { getCurrentPage };
