import React from 'react';
import { LazyImageProps } from './HybridImage';
import BellaHybridLink from './Links/BellaHybridLink';
import { HomepageBannerImage } from 'Client/components/Homepage/HomepageBannerImage';

type ForwardLazyImageProps = Omit<
    LazyImageProps,
    'mobileSrc' | 'desktopSrc' | 'fallbackSrc' | 'shouldBeEagerlyLoaded' | 'alt'
>;

export interface BannerProps extends ForwardLazyImageProps {
    trackingFn: () => void;
    targetUrl?: string;
    altText?: string;
    mobileImage: string;
    desktopImage: string;
    shouldBeEagerlyLoaded?: boolean;
    trackImpressionFunction?: Function;
    openInNewTab?: boolean;
    tabletAsMobile?: boolean;
    className?: string;
}

const Banner = ({
    shouldBeEagerlyLoaded = false,
    openInNewTab = false,
    desktopImage,
    mobileImage,
    altText,
    targetUrl,
    trackingFn,
    className,
    trackImpressionFunction,
}: BannerProps) => {
    if (!targetUrl) {
        return (
            <HomepageBannerImage
                trackImpressionFunction={trackImpressionFunction}
                desktopSrc={desktopImage}
                mobileSrc={mobileImage}
                alt={altText}
                shouldBeEagerlyLoaded={shouldBeEagerlyLoaded}
                className={className}
            />
        );
    }

    return (
        <BellaHybridLink
            to={targetUrl}
            data-testid="anchor-link"
            onClick={trackingFn}
            target={openInNewTab ? '_blank' : undefined}
        >
            <HomepageBannerImage
                trackImpressionFunction={trackImpressionFunction}
                desktopSrc={desktopImage}
                mobileSrc={mobileImage}
                alt={altText}
                shouldBeEagerlyLoaded={shouldBeEagerlyLoaded}
                className={className}
            />
        </BellaHybridLink>
    );
};

export default Banner;
