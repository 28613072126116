import { AppliedFilter, Filter, FilterTypes, NewFilterValues } from '../FilterAndSortBox/types';
import {
    filterForTypeMapping,
    getPathFilterEntryForQueryFilter,
} from 'Client/components/ListingPage/services/filterUrlService';
import { FilterType, pathFilterNames } from 'Client/redux/products/types';
import { PageType } from 'AppShell/appshell_types/routingTypes';

export const rangeSeparator = '-';

export const isFilterGroupIgnored = (pageType: PageType, groupName: string) =>
    (groupName === pathFilterNames.categories && pageType === PageType.CATEGORY) ||
    (groupName === pathFilterNames.brands && pageType === PageType.BRANDS);

export const sanitizeFilters = (currentFilter: Filter, pageType: PageType) => {
    const { type, values, name } = currentFilter;

    if (!values || isFilterGroupIgnored(pageType, name)) {
        return false;
    }

    // Prevent filter groups with empty options from showing
    switch (type) {
        case FilterTypes.range:
            return values.min && values.max && values.min !== values.max;
        case FilterTypes.multiRange:
            return (
                Object.keys(values).length > 0 &&
                !Object.values(values).every((value, _, valuesArr) => value === valuesArr[0])
            );
        case FilterTypes.standard:
            return Object.keys(values).length > 0;
        default:
            return true;
    }
};

export const normalizeFilters = (currentFilter: Filter) => {
    const { name: currentFilterKey } = currentFilter;
    const [pathFilterType, pathFilterKey] = getPathFilterEntryForQueryFilter(currentFilterKey);

    if (pathFilterType && pathFilterKey) {
        return {
            ...currentFilter,
            name: pathFilterKey,
        };
    }

    return currentFilter;
};

export const getStandardFilterChangeValue = (oldValue: FilterValue, newValue: string) => {
    const currentValue = Array.isArray(oldValue) ? oldValue : [];
    const index = currentValue.map(current => current.toLocaleLowerCase()).indexOf(newValue.toLocaleLowerCase());

    return index > -1
        ? [...currentValue.slice(0, index), ...currentValue.slice(index + 1)]
        : [...currentValue, newValue];
};

export const getFiltersForPageType = (filters: Filter[], listingPageType: PageType) =>
    filters.filter(filter => sanitizeFilters(filter, listingPageType)).map(normalizeFilters);

export const getAppliedFilterGroup = (appliedFilters: AppliedFilter[], group: Filter) =>
    appliedFilters.find(filter => filter.name === group.name) || {
        name: 'not-applied',
        values: [],
        type: FilterTypes.notApplied,
    };

export type FilterValue = string | string[] | undefined;

const getFilterTypeForName = (name: string) => filterForTypeMapping[name] || FilterType.query;

export const updateFilterValue = (
    newValue: NewFilterValues,
    filtersState: AppliedFilter<FilterType>[]
): AppliedFilter<FilterType>[] => {
    const { name, value } = newValue;
    const newFilterValue = Array.isArray(value) ? value : [value];
    const copiedFilters = [...filtersState];

    return [
        ...copiedFilters.filter(({ name: filterName }) => filterName !== newValue.name),
        {
            type: getFilterTypeForName(name),
            values: newFilterValue,
            name,
        },
    ];
};
