import React, { useCallback, useRef, useState } from 'react';
import { getMediaStringValue } from '@westwing/ui-kit/breakPoints';
import UIkitPicture, { ImageFormat } from '@westwing/ui-kit/Picture';
import { getFormattedBannerImageSrc } from 'Client/hooks/imageFormatting';
import useConfig from 'AppShell/hooks/useConfig';
import useElementIntersection from 'Client/hooks/useElementIntersection';

export interface HomepageBannerProps {
    desktopSrc: string;
    mobileSrc?: string;
    alt?: string;
    shouldBeEagerlyLoaded?: boolean;
    className?: string;
    trackImpressionFunction?: Function;
}

export const HomepageBannerImage = ({
    desktopSrc,
    mobileSrc,
    alt = '',
    shouldBeEagerlyLoaded = false,
    className,
    trackImpressionFunction,
}: HomepageBannerProps) => {
    const { staticCmsHost } = useConfig();
    const desktopWebpSrc = getFormattedBannerImageSrc(desktopSrc, staticCmsHost, true);
    const desktopJpegSrc = getFormattedBannerImageSrc(desktopSrc, staticCmsHost);
    const mobileWebpSrc = getFormattedBannerImageSrc(mobileSrc || desktopSrc, staticCmsHost, true);
    const mobileJpegSrc = getFormattedBannerImageSrc(mobileSrc || desktopSrc, staticCmsHost);
    const isGIF = desktopWebpSrc.endsWith('.gif');

    const sources = [
        {
            media: getMediaStringValue('breakpointDesktop', true), // mobile and tablet
            url: mobileWebpSrc,
            value: 1,
            format: ImageFormat.WEBP,
        },
        {
            media: getMediaStringValue('breakpointDesktop', true), // mobile and tablet
            url: mobileJpegSrc,
            value: 1,
            format: isGIF ? ImageFormat.GIF : ImageFormat.JPEG,
        },
        {
            media: getMediaStringValue('breakpointDesktop'),
            url: desktopWebpSrc,
            value: 1,
            format: ImageFormat.WEBP,
        },
        {
            media: getMediaStringValue('breakpointDesktop'),
            url: desktopJpegSrc,
            value: 1,
            format: isGIF ? ImageFormat.GIF : ImageFormat.JPEG,
        },
    ];

    const [hasComeIntoView, setHasComeIntoView] = useState(false);
    const trackImpression = useCallback(() => {
        if (trackImpressionFunction) {
            trackImpressionFunction();
        }
    }, [trackImpressionFunction]);
    const imageRef = useRef<HTMLPictureElement>(null);

    const onIntersection = () => {
        setHasComeIntoView(true);
        trackImpression();
    };

    useElementIntersection(imageRef, () => {
        if (!hasComeIntoView) {
            onIntersection();
        }
    });

    return (
        <UIkitPicture
            ref={imageRef}
            className={className}
            loading={shouldBeEagerlyLoaded ? 'eager' : 'lazy'}
            sources={sources}
            fallback={{
                url: desktopSrc,
                alt,
            }}
        />
    );
};
