import styled from 'styled-components';
import { forTablet, forDesktop } from '@westwing/ui-kit/breakPoints';

export default styled.header`
    margin: 18px 0px 0;
    text-align: center;
    color: ${({ theme }) => theme.colors.charcoal100};
    vertical-align: bottom;
    padding: 10px 0 28px;
    display: grid;

    grid-template:
        'title title ' auto
        'back  x-cat ' auto
        / auto 1fr;

    ${forTablet`
        margin: 11px 0px 0;
        padding-bottom: 25px;
    `}

    ${forDesktop`
        padding: 20px 0;
    `}
`;
