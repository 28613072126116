import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import ActionButton from '@westwing/ui-kit/ActionButton';
import { Direction } from '@westwing/ui-kit/Icons/StyledIcons';
import Flyout, { TitlePosition } from '../../Overlays/GenericFlyout';
import { OverlayId } from '../../Overlays/types';
import FilterGroup from './FiltersGroup';
import { getFiltersForPageType } from 'Client/components/Generic/GenericFiltersFlyout/filterValueService';
import { hideOverlay } from 'Client/redux/header/overlays/actions';
import useTranslator from 'Client/hooks/useTranslator';
import useListingPageUrlState from 'Client/components/ListingPage/hooks/useListingPageUrlState';
import useGa from 'Client/hooks/useGa';
import ErrorBoundary from 'Client/components/Generic/ErrorBoundaries';
import { BellaTestIds } from 'Common/qaIds';
import { AppliedFilter, Filter } from 'Client/components/Generic/FilterAndSortBox/types';
import StateInterface from 'Client/redux/types';
import { sizeFacets } from 'Client/components/ListingPage/services/filterUrlService';
import { pathFilterNames } from 'Client/redux/products/types';

const StyledFiltersContainer = styled.div`
    padding: 20px 20px 0 20px;
`;

const MarginActionButton = styled(ActionButton)`
    margin-top: 10px;
`;

export const generateGaLabel = (newListingState: AppliedFilter[], filters: Filter[], listingPageKey: string) => {
    const filtersLabelsMap = filters.reduce((acc, { name, displayName: label }) => {
        // ,'facet_generic_size' is used in applied filters for all size related filters.
        const appliedFilterName = Object.values(sizeFacets).includes(name) ? pathFilterNames.sizes : name;
        return {
            ...acc,
            [appliedFilterName]: label,
        };
    }, {});

    return newListingState.reduce((acc, filter) => {
        const labelPrefix = filtersLabelsMap[filter.name];
        const joinedVals = filter.values.join(',');
        return `${acc}${labelPrefix} - ${joinedVals};`;
    }, `/${listingPageKey}/;`);
};

const emptyFiltersState = {
    appliedFilters: [],
};

const FiltersFlyout: React.FC = () => {
    const filters = useSelector<StateInterface, Filter[]>(state => state.products.filters);
    const [urlState, setListingPageState] = useListingPageUrlState();
    const dispatch = useDispatch();
    const t = useTranslator();
    const { appliedFilters, listingPageType, listingPageKey } = urlState;
    const [didInvalidate, setDidInvalidate] = useState(false);
    const gaTracking = useGa();

    const onFilterValueChange = (): void => {
        setDidInvalidate(true);
    };

    const onMenuItemClick = (displayName: string, isOpen: boolean) => {
        if (isOpen) {
            gaTracking.trackFilters({
                action: gaTracking.actions.Filters.FilterClick,
                label: `/${listingPageKey}/;${displayName}`,
            });
        }
    };

    const trackAppliedFilter = () => {
        if (didInvalidate) {
            gaTracking.trackFilters({
                action: gaTracking.actions.Filters.FilterActivated,
                label: generateGaLabel(appliedFilters, filters, listingPageKey),
            });
        }
    };

    const completeFiltering = () => {
        trackAppliedFilter();
        dispatch(hideOverlay(OverlayId.FILTERS_FLYOUT));
    };

    const clearFilters = () => {
        if (didInvalidate) {
            gaTracking.trackFilters({
                action: gaTracking.actions.Filters.FilterReset,
                label: `/${listingPageKey}/`,
            });
        }
        setDidInvalidate(false);
        setListingPageState(emptyFiltersState);

        dispatch(hideOverlay(OverlayId.FILTERS_FLYOUT));
    };

    const createFiltersNavItems = (): React.ReactElement[] =>
        getFiltersForPageType(filters, listingPageType).map(group => (
            <ErrorBoundary key={group.name} boundaryName={`Filters Flyout ${group.name}`} fallbackComponent={null}>
                <FilterGroup
                    key={group.name}
                    group={group}
                    handleValueChange={onFilterValueChange}
                    onMenuItemClick={isOpen => onMenuItemClick(group.displayName, isOpen)}
                />
            </ErrorBoundary>
        ));

    return (
        <Flyout
            identifier={OverlayId.FILTERS_FLYOUT}
            title={t('Filter')}
            closeBtnPosition={Direction.Right}
            titlePosition={TitlePosition.LEFT}
            showHeaderBorder
            denseHeader
            cleanup={trackAppliedFilter}
        >
            <StyledFiltersContainer>{createFiltersNavItems()}</StyledFiltersContainer>
            <StyledFiltersContainer>
                <ActionButton
                    data-testid={BellaTestIds.filtersResetBtn}
                    kind="secondary"
                    name="clear"
                    onClick={clearFilters}
                >
                    {t('Reset filter')}
                </ActionButton>
                <MarginActionButton
                    data-testid={BellaTestIds.filtersApplyBtn}
                    kind="primary"
                    name="submit"
                    onClick={completeFiltering}
                >
                    {t('Apply filters')}
                </MarginActionButton>
            </StyledFiltersContainer>
        </Flyout>
    );
};

export default FiltersFlyout;
