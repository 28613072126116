import React, { FC, ReactElement } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { forTablet, forDesktop } from '@westwing/ui-kit/breakPoints';
import { FilterType } from '../../../redux/products/types';
import useListingPageUrlState, { SetListingPageStateFunc } from '../hooks/useListingPageUrlState';
import StyledSlider from '../../Generic/StyledSlider';
import { CategoriesState } from '../../../redux/category/types';
import { getSingleDisplayValue } from '../../../filterDisplayValueService';
import StateInterface from '../../../redux/types';
import AppliedFilterItem from 'Client/components/Generic/AppliedFilters/AppliedFilterItem';
import { AppliedFilter, Filter } from 'Client/components/Generic/FilterAndSortBox/types';
import useP100FilterRedesign from 'Client/components/Generic/FilterAndSortBox/useP100FilterRedesign';
import useTranslator from 'Client/hooks/useTranslator';
import useGa from 'Client/hooks/useGa';
import { getFiltersForPageType } from 'Client/components/Generic/GenericFiltersFlyout/filterValueService';

const StyledSelectedFiltersText = styled.div`
    display: none;
    margin-right: 7px;

    ${forDesktop`
        display: inline-block;
    `}
`;

const AppliedFilterStyledSlider = styled(StyledSlider)`
    line-height: 40px;
    padding-left: 0;

    ${forTablet`
        padding: 0;
    `}
`;

const AppliedFilterStyledSliderWrapper = styled.div<{ hide: boolean }>`
    display: ${({ hide }) => (hide ? 'none' : 'block')};
    overflow-y: hidden;
    ${forTablet`
        height: auto;
        line-height: 2.8;
    `}
`;

interface OwnProps {
    updateFilters: SetListingPageStateFunc;
    appliedFiltersProps: AppliedFilter<FilterType>[];
    currency: string;
}

interface FromState {
    filters: Filter[];
    categories: CategoriesState;
}

export const removeValueFromFilters = (
    filterName: string,
    filterValue: string,
    appliedFilters: AppliedFilter<FilterType>[]
) => {
    const filterCopy = [...appliedFilters];
    const filterIndex = appliedFilters.findIndex(filter => filter.name === filterName);

    if (filterIndex < 0) {
        return filterCopy;
    }

    filterCopy[filterIndex].values = filterCopy[filterIndex].values.filter(value => value !== filterValue);
    return filterCopy.filter(filter => filter.values.length > 0);
};

const removeFilterFuncFactory = (
    filterName: string,
    filterValue: string,
    appliedFilters: AppliedFilter<FilterType>[],
    setListingPageStateFunc: SetListingPageStateFunc
    // eslint-disable-next-line max-params
): void => {
    setListingPageStateFunc({
        appliedFilters: removeValueFromFilters(filterName, filterValue, appliedFilters),
    });
};

type AppliedFiltersSliderProps = OwnProps & FromState;

const AppliedFiltersSlider: FC<AppliedFiltersSliderProps> = ({
    appliedFiltersProps,
    updateFilters,
    currency,
    categories,
    filters,
}): ReactElement => {
    const { isBoxed } = useP100FilterRedesign();
    const t = useTranslator();
    const [{ appliedFilters, listingPageKey, listingPageType }] = useListingPageUrlState();
    const gaTracking = useGa();

    const rows = appliedFiltersProps
        .map(filter =>
            filter.values.map(value => ({
                filter,
                value: getSingleDisplayValue(value, filter.name, { categories, currency, filters }),
                rawValue: value,
            }))
        )
        .reduce((allValues, values) => [...allValues, ...values], []);

    const onRemove = ({ filterName, filterValue }: { filterName: string; filterValue: string }) => {
        const filter = getFiltersForPageType(filters, listingPageType).find(filter => filter.name === filterName);

        if (filter) {
            gaTracking.trackFilters({
                action: gaTracking.actions.Filters.FilterRemoved,
                label: `/${listingPageKey}/;${filter.displayName} - ${filterValue}`,
            });
        }
        removeFilterFuncFactory(filterName, filterValue, appliedFiltersProps, updateFilters);
    };

    return (
        <AppliedFilterStyledSliderWrapper hide={rows.length === 0}>
            <AppliedFilterStyledSlider>
                {isBoxed && appliedFilters.length > 0 && (
                    <StyledSelectedFiltersText>{t('Selected filters')}</StyledSelectedFiltersText>
                )}
                {rows.map(({ filter, value, rawValue }) => (
                    <AppliedFilterItem
                        name={value}
                        key={value}
                        type={filter.type}
                        removeFilterFunc={() => onRemove({ filterName: filter.name, filterValue: rawValue })}
                    />
                ))}
            </AppliedFilterStyledSlider>
        </AppliedFilterStyledSliderWrapper>
    );
};

const mapStateToProps = (state: StateInterface): FromState => ({
    filters: state.products.filters,
    categories: state.categories,
});

export default connect(mapStateToProps)(React.memo(AppliedFiltersSlider));
