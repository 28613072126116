import useGa from 'Client/hooks/useGa';
import useRouter from 'AppShell/hooks/useRouter';

const useListingPageTracking = () => {
    const {
        location: { pathname },
    } = useRouter();

    const gaTracking = useGa();

    const trackBackToTopClick = (): void => {
        gaTracking.trackPLP({
            action: 'Back to top button - Click',
            label: pathname,
        });
    };

    const trackBackToTopImpression = (): void => {
        gaTracking.trackPLP({
            action: 'Back to top button - Impression',
            label: pathname,
        });
    };

    return {
        trackBackToTopClick,
        trackBackToTopImpression,
    };
};

export default useListingPageTracking;
