import { useSelector } from 'react-redux';
import useListingPageUrlState from './useListingPageUrlState';
import StateInterface from 'Client/redux/types';
import { getEntityByUrlKey } from 'Client/redux/category/categoriesNormalizer';
import useRouter from 'AppShell/hooks/useRouter';
import useConfig from 'AppShell/hooks/useConfig';
import { PageType, Config } from 'AppShell/appshell_types';

export enum TRACKING_PAGE_LABELS {
    LOOKS = 'LDP',
    HOME = 'index',
    PLP_DEFAULT = 'catalog',
    PLP_SEARCH = 'catalog - PLP-search',
    PLP_BRANDS = 'catalog - PLP-brand',
    PLP_NEW_PRODUCTS = 'catalog - PLP-new',
    PLP_LANDING = 'catalog - PLP-LP',
    PDP = 'detail',
}

export const listingPageTypesMap = {
    [PageType.CATEGORY]: TRACKING_PAGE_LABELS.PLP_DEFAULT,
    [PageType.ALLPRODUCTS]: TRACKING_PAGE_LABELS.PLP_SEARCH,
    [PageType.NEWPRODUCTS]: TRACKING_PAGE_LABELS.PLP_NEW_PRODUCTS,
    [PageType.BRANDS]: TRACKING_PAGE_LABELS.PLP_BRANDS,
    [PageType.LANDING]: TRACKING_PAGE_LABELS.PLP_LANDING,
};

export interface GetPageTypeOptions {
    pathname: string;
    search: string | undefined;
    listingPageType: PageType;
    categoryDepthLevel: number | null;
    generalPageTypesMap: { [key: string]: string };
    defaultPath: string;
}

export const getPageType = (options: GetPageTypeOptions) => {
    const { pathname, search, listingPageType, generalPageTypesMap, defaultPath, categoryDepthLevel } = options;

    if (listingPageType) {
        if (listingPageType === PageType.CATEGORY) {
            return `${TRACKING_PAGE_LABELS.PLP_DEFAULT} - PLP-${categoryDepthLevel || 1}`;
        }
        if (search) {
            return listingPageTypesMap[PageType.ALLPRODUCTS];
        }
        return listingPageTypesMap[listingPageType] || listingPageTypesMap[PageType.CATEGORY];
    }

    if (generalPageTypesMap[pathname]) {
        return generalPageTypesMap[pathname];
    }

    return generalPageTypesMap[defaultPath];
};

const useTrackingPageLabel = () => {
    const {
        location: { pathname },
    } = useRouter();
    const [listingPageUrlState] = useListingPageUrlState();
    const { listingPageType, search, listingPageKey } = listingPageUrlState;
    const { links }: Config = useConfig();
    const categoryDepthLevel = useSelector<StateInterface, number | null>(state => {
        const categoryEntity = getEntityByUrlKey(state.categories.normalized, listingPageKey);
        if (!categoryEntity) {
            return null;
        }
        return categoryEntity.depthLevel;
    });

    const defaultPath = links.home;
    const generalPageTypesMap = {
        [links.looks]: TRACKING_PAGE_LABELS.LOOKS,
        [links.home]: TRACKING_PAGE_LABELS.HOME,
    };

    return getPageType({ pathname, search, categoryDepthLevel, listingPageType, generalPageTypesMap, defaultPath });
};

export default useTrackingPageLabel;
