import React from 'react';
import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';
import styled from 'styled-components';
import { body2 } from '@westwing/ui-kit/typography';
import { isDesktop } from '@westwing/ui-kit/breakPoints';
import { NormalizedCategoriesData, CategoryEntity } from '../../../redux/category/types';
import StateInterface from '../../../redux/types';
import useListingPageUrlState from '../hooks/useListingPageUrlState';
import useGa from '../../../hooks/useGa';
import { getCategoriesUrl } from '../../../urlService';
import { Dictionary } from 'Utils/types/utility';
import { getNavigationForCategory } from '../CrossCategoryNavigation/crossCategoryNavigationService';
import { hideOverlay } from 'Client/redux/header/overlays/actions';
import BellaHybridLink from 'Client/components/Generic/Links/BellaHybridLink';

interface StateProps {
    normalized: NormalizedCategoriesData | null;
}

const CHILD_CATEGORY_PADDING = 20;
const NON_FIRST_LEVEL_DEPTH = 2;

const StyledNav = styled(BellaHybridLink)<{ depth: number }>`
    padding-left: ${({ depth }) => `${(depth - 1) * CHILD_CATEGORY_PADDING}px`};
    display: block;
    color: ${({ theme }) => theme.colors.charcoal100};
    ${body2}

    &.${props => props.activeClassName} {
        font-family: ${({ theme }) => theme.fonts.fontBrandonMedium};
        font-weight: 500;
    }
    :hover {
        text-decoration: underline;
    }
`;

const StyledList = styled.li`
    padding: 0px 0px 15px;
    border-bottom: none;
    list-style: none;
`;

export const getFirstLevelParent = (cat: CategoryEntity, entities: Dictionary<CategoryEntity>): CategoryEntity =>
    cat.parent ? getFirstLevelParent(entities[cat.parent], entities) : cat;

const CategoryListing: React.FC<StateProps> = ({ normalized }): React.ReactElement | null => {
    const [urlState] = useListingPageUrlState();
    const gaTracking = useGa();
    if (!normalized) {
        return null;
    }

    const buildNodeAndChildNodes = (
        currentCat: CategoryEntity | null,
        childWithGrandChildren: CategoryEntity[] = [],
        callingChildId?: string
    ): CategoryEntity[] => {
        if (!currentCat) {
            return childWithGrandChildren;
        }

        const categoryWithChildrenEntities: Array<CategoryEntity> = [];
        categoryWithChildrenEntities.push(currentCat); // [  me ]

        (currentCat.children || []).forEach(childId => {
            if (childId === callingChildId) {
                categoryWithChildrenEntities.push(...childWithGrandChildren);
            } else {
                categoryWithChildrenEntities.push(normalized.entities[childId]);
            }
        });

        const parentId = currentCat.parent;
        const parentCategory = parentId ? normalized.entities[parentId] : null;

        return buildNodeAndChildNodes(parentCategory, categoryWithChildrenEntities, currentCat.id);
    };

    const activeCategoryId: string = normalized.urlKeys[urlState.listingPageKey];
    let parentEntities: Array<CategoryEntity> = [];

    if (activeCategoryId) {
        const normalizedEntities = normalized.entities;
        const categoryEntity = normalizedEntities[activeCategoryId];
        const selectedCategoryDepthLevel = categoryEntity.depthLevel;

        if (
            selectedCategoryDepthLevel < NON_FIRST_LEVEL_DEPTH ||
            getFirstLevelParent(categoryEntity, normalizedEntities).isHidden
        ) {
            normalized.categories.forEach(catId => {
                const cat = normalizedEntities[catId];
                parentEntities.push(normalizedEntities[catId]);

                if (catId === activeCategoryId && !cat.isHidden) {
                    (cat.children || []).forEach(childId => {
                        parentEntities.push(normalizedEntities[childId]);
                    });
                }
            });
        } else {
            parentEntities = buildNodeAndChildNodes(categoryEntity);
        }
    } else {
        normalized.categories.forEach(catId => {
            parentEntities.push(normalized.entities[catId]);
        });
    }

    const trackableCategories = getNavigationForCategory(urlState.listingPageKey, normalized) || { categories: [] };
    const trackableCategoriesIds = trackableCategories.categories.map(c => c.name);

    const trackSubCategoryClick = (categoryName: string): void => {
        if (trackableCategoriesIds.includes(categoryName) && isDesktop()) {
            gaTracking.trackPLP({ action: 'Clicks on the left side category tree on PLP', label: categoryName });
        }
    };

    return (
        <ul>
            {parentEntities
                .filter(ent => !ent.isHidden)
                .map(category => (
                    <StyledList key={category.urlKey} data-testid={`side-category-${category.depthLevel}`}>
                        <StyledNav
                            key={category.urlKey}
                            activeClassName="activecategory"
                            to={getCategoriesUrl(category.urlKey)}
                            depth={category.depthLevel}
                            isNavLink
                            onClick={() => trackSubCategoryClick(category.name)}
                        >
                            {category.name}
                        </StyledNav>
                    </StyledList>
                ))}
        </ul>
    );
};

const mapStateToProps = (state: StateInterface): StateProps => {
    const {
        categories: { normalized },
    } = state;
    return { normalized };
};

const mapDispatchToProps = (dispatch: Dispatch): {} => ({
    hideNavigationFlyout: (id: string): Action => dispatch(hideOverlay(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryListing);
