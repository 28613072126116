import { LazyImageStateType, LazyImageActionType, INTERSECTION_ACTION, LOADED_ACTION } from './types';

export const getShowSpinnerValue = (hasComeIntoView: boolean, isLoaded: boolean) => hasComeIntoView && !isLoaded;

const lazyImagesReducer = (state: LazyImageStateType, action: LazyImageActionType) => {
    const { hasComeIntoView, isLoaded } = state;
    const { type, payload } = action;

    switch (type) {
        case INTERSECTION_ACTION:
            return { ...state, hasComeIntoView: payload, showSpinner: getShowSpinnerValue(payload, isLoaded) };
        case LOADED_ACTION:
            return { ...state, isLoaded: payload, showSpinner: getShowSpinnerValue(hasComeIntoView, payload) };
        default:
            return state;
    }
};
export default lazyImagesReducer;
