/* eslint-disable */
import {SEO_CONSTANTS} from "Client/components/ListingPage/services/seo/constant";
import { Helmet } from 'react-helmet-async';
import React from "react";

export const SeoPLPTeekannenStructuredData = () => {
    return CreateScript(ItemListTeekannen);
}
export const SeoPLPDuschvorhaengeStructuredData = () => {
    return CreateScript(ProductCollectionDuschvorhaenge);
}

const CreateScript = (innerHTML:Object) => {
    const itemListScript = {
        type: SEO_CONSTANTS.structureDataScriptType,
        innerHTML: JSON.stringify(innerHTML),
    };
    return <Helmet script={[itemListScript]}/>;
}

const ProductCollectionDuschvorhaenge = {
    "@context": "https://schema.org/",
    "@type": "ProductCollection",
    "name": "Duschvorhänge",
    "url": "https://www.westwing.de/duschvorhaenge/",
    "mainEntity" : {
        "@type":"ItemList",
        "numberOfItems": "27",
        "itemListorder": "ItemListUnordered",
        "itemListElement": [
            {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277/simple/33/0351/1270381.jpg",
                "URL" :  "https://www.westwing.de/duschvorhang-rosemarie-mit-blumen-muster-121401.html",
                "name":  "Duschvorhang Rosemarie mit Blumen-Muster",
                "offers": {
                    "@type": "Offer",
                    "price": "44.99",
                    "priceCurrency": "EUR"
                }
            },
            {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277/simple/70/2151/1362799.jpg",
                "URL" :  "https://www.westwing.de/einfarbiger-duschvorhang-granada-in-weiss-119983.html",
                "name":  "Einfarbiger Duschvorhang Granada in Weiß",
                "offers": {
                    "@type": "Offer",
                    "price": "14.99",
                    "priceCurrency": "EUR"
                }
            },
            {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277/simple/40/2151/1421754.jpg",
                "URL" :  "https://www.westwing.de/duschvorhang-coloris-aus-baumwoll-mix-119980.html",
                "name":  "Duschvorhang Coloris aus Baumwoll-Mix",
                "offers": {
                    "@type": "Offer",
                    "price": "29.99",
                    "priceCurrency": "EUR"
                }
            },
            {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277/simple/41/5171/1491007.jpg",
                "URL" :  "https://www.westwing.de/duschvorhang-gallery-mit-abstraktem-muster-136197.html",
                "name":  "Duschvorhang Gallery mit abstraktem Muster",
                "offers": {
                    "@type": "Offer",
                    "price": "59.99",
                    "priceCurrency": "EUR"
                }
            },
            {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277/simple/31/2151/1358411.jpg",
                "URL" :  "https://www.westwing.de/einfarbiger-duschvorhang-madeira-in-dunkelgrau-119989.html",
                "name":  "Einfarbiger Duschvorhang Granada in Dunkelgrau",
                "offers": {
                    "@type": "Offer",
                    "price": "14.99",
                    "priceCurrency": "EUR"
                }
            },
            {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277/simple/60/2151/1361750.jpg",
                "URL" :  "https://www.westwing.de/duschvorhang-coloris-aus-baumwoll-mix-119982.html",
                "name":  "Duschvorhang Coloris aus Baumwoll-Mix",
                "offers": {
                    "@type": "Offer",
                    "price": "28.99",
                    "priceCurrency": "EUR"
                }
            },
            {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277/simple/42/0351/1230782.jpg",
                "URL" :  "https://www.westwing.de/duschvorhang-anchor-mit-anker-print-121392.html",
                "name":  "Duschvorhang Anchor mit Anker-Print",
                "offers": {
                    "@type": "Offer",
                    "price": "25.99",
                    "priceCurrency": "EUR"
                }
            },
            {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277,pd_38_17_38_17/simple/35/8971/1588208.jpg",
                "URL" :  "https://www.westwing.de/duschvorhang-mind-mit-one-line-zeichnung-142183.html",
                "name":  "Duschvorhang Mind mit One Line Zeichnung",
                "offers": {
                    "@type": "Offer",
                    "price": "15.99",
                    "priceCurrency": "EUR"
                }
            },
            {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277/simple/92/0351/1373378.jpg",
                "URL" :  "https://www.westwing.de/duschvorhang-marble-mit-marmor-print-121397.html",
                "name":  "Duschvorhang Marble mit Marmor-Print",
                "offers": {
                    "@type": "Offer",
                    "price": "34.99",
                    "priceCurrency": "EUR"
                }
            },
            {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277,pd_38_17_38_17/simple/17/5661/1420355.jpg",
                "URL" :  "https://www.westwing.de/duschvorhang-danja-132175.html",
                "name":  "Duschvorhang Danja, gepunktet",
                "offers": {
                    "@type": "Offer",
                    "price": "24.99",
                    "priceCurrency": "EUR"
                }
            },
            {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277/simple/19/3401/1323554.jpg",
                "URL" :  "https://www.westwing.de/gemusterter-duschvorhang-allie-83633.html",
                "name":  "Gemusterter Duschvorhang Allie",
                "offers": {
                    "@type": "Offer",
                    "price": "23.99",
                    "priceCurrency": "EUR"
                }
            },
            {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277,pd_38_17_38_17/simple/03/1231/1316363.jpg",
                "URL" :  "https://www.westwing.de/karierter-duschvorhang-tiles-105466.html",
                "name":  "Karierter Duschvorhang Tiles",
                "offers": {
                    "@type": "Offer",
                    "price": "37.99",
                    "priceCurrency": "EUR"
                }
            },
            {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277/simple/00/4401/1356308.jpg",
                "URL" :  "https://www.westwing.de/gemusterter-duschvorhang-kenzie-83642.html",
                "name":  "Gemusterter Duschvorhang Kenzie",
                "offers": {
                    "@type": "Offer",
                    "price": "24.99",
                    "priceCurrency": "EUR"
                }
            },
            {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277,pd_38_17_38_17/simple/81/6871/1588206.jpg",
                "URL" :  "https://www.westwing.de/gestreifter-duschvorhang-hanne-in-schwarz-weiss-141175.html",
                "name":  "Gestreifter Duschvorhang Hanne in Schwarz/Weiß",
                "offers": {
                    "@type": "Offer",
                    "price": "24.99",
                    "priceCurrency": "EUR"
                }
            },
            {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277/simple/51/5171/1491013.jpg",
                "URL" :  "https://www.westwing.de/duschvorhang-gallery-mit-abstraktem-muster-136198.html",
                "name":  "Duschvorhang Gallery mit abstraktem Muster",
                "offers": {
                    "@type": "Offer",
                    "price": "59.99",
                    "priceCurrency": "EUR"
                }
            },
            {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277/simple/20/2151/1356248.jpg",
                "URL" :  "https://www.westwing.de/duschvorhang-twister-119978.html",
                "name":  "Duschvorhang Twister",
                "offers": {
                    "@type": "Offer",
                    "price": "27.99",
                    "priceCurrency": "EUR"
                }
            },
            {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277,pd_38_17_38_17/simple/40/4401/1588205.jpg",
                "URL" :  "https://www.westwing.de/gemusterter-duschvorhang-katie-83646.html",
                "name":  "Gemusterter Duschvorhang Katie",
                "offers": {
                    "@type": "Offer",
                    "price": "16.99",
                    "priceCurrency": "EUR"
                }
            },
            {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277,pd_38_17_38_17/simple/94/8971/1588212.jpg",
                "URL" :  "https://www.westwing.de/duschvorhang-face-mit-one-line-zeichnung-142179.html",
                "name":  "Duschvorhang Face mit One Line Zeichnung",
                "offers": {
                    "@type": "Offer",
                    "price": "34.99",
                    "priceCurrency": "EUR"
                }
            },
            {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277,pd_38_17_38_17/simple/25/8971/1588209.jpg",
                "URL" :  "https://www.westwing.de/duschvorhang-ayra-mit-pflanzen-muster-142182.html",
                "name":  "Duschvorhang Ayra mit Pflanzen-Muster",
                "offers": {
                    "@type": "Offer",
                    "price": "34.99",
                    "priceCurrency": "EUR"
                }
            },
            {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277/simple/29/1431/1217899.jpg",
                "URL" :  "https://www.westwing.de/kurzer-duschvorhang-golf-mit-punkten-semi-transparent-107091.html",
                "name":  "Kurzer Duschvorhang Golf mit Punkten, semi-transparent",
                "offers": {
                    "@type": "Offer",
                    "price": "13.99",
                    "priceCurrency": "EUR"
                }
            },
            {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277,pd_38_17_38_17/simple/45/8971/1588207.jpg",
                "URL" :  "https://www.westwing.de/duschvorhang-brave-in-weiss-schwarz-142184.html",
                "name":  "Duschvorhang Brave in Weiß/Schwarz",
                "offers": {
                    "@type": "Offer",
                    "price": "19.99",
                    "priceCurrency": "EUR"
                }
            },
            {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277,pd_38_17_38_17/simple/15/8971/1588210.jpg",
                "URL" :  "https://www.westwing.de/duschvorhang-flow-in-weiss-142181.html",
                "name":  "Duschvorhang Flow in Weiß",
                "offers": {
                    "@type": "Offer",
                    "price": "29.99",
                    "priceCurrency": "EUR"
                }
            },
            {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277,pd_38_17_38_17/simple/55/8971/1588211.jpg",
                "URL" :  "https://www.westwing.de/duschvorhang-zebra-in-schwarz-weiss-142185.html",
                "name":  "Duschvorhang Zebra in Schwarz/Weiß",
                "offers": {
                    "@type": "Offer",
                    "price": "19.99",
                    "priceCurrency": "EUR"
                }
            },
            {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277/simple/82/0351/1298336.jpg",
                "URL" :  "https://www.westwing.de/duschvorhang-zora-in-transparent-weiss-121396.html",
                "name":  "Duschvorhang Zora in Transparent/Weiß",
                "offers": {
                    "@type": "Offer",
                    "price": "16.99",
                    "priceCurrency": "EUR"
                }
            },
            {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277,pd_38_17_38_17/simple/05/8971/1623912.jpg",
                "URL" :  "https://www.westwing.de/duschvorhang-mare-in-rosa-142180.html",
                "name":  "Duschvorhang Mare in Rosa",
                "offers": {
                    "@type": "Offer",
                    "price": "24.99",
                    "priceCurrency": "EUR"
                }
            },
            {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277,pd_38_17_38_17/simple/10/6871/1565238.jpg",
                "URL" :  "https://www.westwing.de/duschvorhang-amelie-mit-abstraktem-muster-141158.html",
                "name":  "Duschvorhang Amelie mit abstraktem Muster",
                "offers": {
                    "@type": "Offer",
                    "price": "49.99",
                    "priceCurrency": "EUR"
                }
            },
            {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/f_auto/c_pad,w_208,h_277/simple/53/0351/1326304.jpg",
                "URL" :  "https://www.westwing.de/duschvorhang-ringe-crystal-12-stueck-121403.html",
                "name":  "Duschvorhang-Ringe Crystal, 12 Stück",
                "offers": {
                    "@type": "Offer",
                    "price": "5.99",
                    "priceCurrency": "EUR"
                }
            },
            {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277/simple/49/3401/1361058.jpg",
                "URL" :  "https://www.westwing.de/gestreifter-duschvorhang-maggie-83636.html",
                "name":  "Gestreifter Duschvorhang Maggie",
                "offers": {
                    "@type": "Offer",
                    "price": "24.99",
                    "priceCurrency": "EUR"
                }
            }
        ]
    }};

const ItemListTeekannen = {
    "@context": "https://schema.org/",
    "@type": "ItemList",
    "url": "https://www.westwing.de/teekannen/",
    "numberOfItems": "35",
    "itemListElement": [
        {
            "@type": "ListItem",
            "position": 1,
            "item": {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277/simple/77/676/1356997.jpg",
                "url": "https://www.westwing.de/handgemachte-teekanne-nordic-sea-54547.html",
                "name": "Handgemachte Teekanne Nordic Sea aus Steingut, 1.3 L",
                "offers": {
                    "type": "Offer",
                    "price": "39.99",
                    "priceCurrency": "EUR"
                }
            }
        },
        {
            "@type": "ListItem",
            "position": 2,
            "item": {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_104,h_139/simple/36/412/1510700.jpg",
                "url": "https://www.westwing.de/design-teekanne-kettle-aus-glas-mit-tee-ei-18259.html",
                "name": "Design Teekanne Kettle aus Glas mit Tee-Ei, 1.5 L",
                "offers": {
                    "type": "Offer",
                    "price": "69.99",
                    "priceCurrency": "EUR"
                }
            }
        },
        {
            "@type": "ListItem",
            "position": 3,
            "item": {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_104,h_139/simple/38/9851/1465619.jpg",
                "url": "https://www.westwing.de/teekanne-nordika-126207.html",
                "name": "Kleine Steingut Teekanne Nordika mit Akazienholzsockel, 700 ml",
                "offers": {
                    "type": "Offer",
                    "price": "24.99",
                    "priceCurrency": "EUR"
                }
            }
        },
        {
            "@type": "ListItem",
            "position": 4,
            "item": {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_104,h_139/simple/28/9851/1568402.jpg",
                "url": "https://www.westwing.de/teekanne-nordika-126206.html",
                "name": "Kleine Steingut Teekanne Nordika mit Akazienholzsockel, 700 ml",
                "offers": {
                    "type": "Offer",
                    "price": "24.99",
                    "priceCurrency": "EUR"
                }
            }
        },
        {
            "@type": "ListItem",
            "position": 5,
            "item": {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_104,h_139/simple/67/676/1372679.jpg",
                "url": "https://www.westwing.de/handgemachte-teekanne-nordic-sand-54546.html",
                "name": "Handgemachte Teekanne Nordic Sand aus Steingut, 1.3 L",
                "offers": {
                    "type": "Offer",
                    "price": "39.99",
                    "priceCurrency": "EUR"
                }
            }
        },
        {
            "@type": "ListItem",
            "position": 6,
            "item": {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_104,h_139/simple/26/3271/1494518.jpg",
                "url": "https://www.westwing.de/teekanne-pilar-aus-keramik-in-beige-1-l-136878.html",
                "name": "Teekanne Pilar aus Keramik in Beige matt/glänzend, 1 L",
                "offers": {
                    "type": "Offer",
                    "price": "44.99",
                    "priceCurrency": "EUR"
                }
            }
        },
        {
            "@type": "ListItem",
            "position": 7,
            "item": {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277/simple/63/7371/1497834.jpg",
                "url": "https://www.westwing.de/teekanne-chili-aus-glas-mit-teesieb-1-l-137774.html",
                "name": "Teekanne Chili aus Glas mit goldfarbenem Teesieb, 1 L",
                "offers": {
                    "type": "Offer",
                    "price": "49.99",
                    "priceCurrency": "EUR"
                }
            }
        },
        {
            "@type": "ListItem",
            "position": 8,
            "item": {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277,pd_38_17_38_17/simple/07/0251/1429855.jpg",
                "url": "https://www.westwing.de/teekanne-good-morning-in-gold-120647.html",
                "name": "Steingut Teekanne Good Morning in Gold, 1 L",
                "offers": {
                    "type": "Offer",
                    "price": "69.99",
                    "priceCurrency": "EUR"
                }
            }
        },
        {
            "@type": "ListItem",
            "position": 9,
            "item": {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277,pd_38_17_38_17/simple/26/9571/1543032.jpg",
                "url": "https://www.westwing.de/handgefertigte-porzellan-teekanne-nippon-mit-struktur-in-weiss-goldfarben-1-3-l-139173.html",
                "name": "Handgemachte Porzellan-Teekanne Nippon mit Struktur in Weiß/Goldfarben, 1.3 L",
                "offers": {
                    "type": "Offer",
                    "price": "49.99",
                    "priceCurrency": "EUR"
                }
            }
        },
        {
            "@type": "ListItem",
            "position": 10,
            "item": {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277/simple/18/6851/1367751.jpg",
                "url": "https://www.westwing.de/stoevchen-warm-up-125914.html",
                "name": "Designer Stövchen Warm-Up in Schwarz",
                "offers": {
                    "type": "Offer",
                    "price": "49.99",
                    "priceCurrency": "EUR"
                }
            }
        },
        {
            "@type": "ListItem",
            "position": 11,
            "item": {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277/simple/49/6271/1491195.jpg",
                "url": "https://www.westwing.de/teezubereiter-emma-in-beige-1-l-137088.html",
                "name": "Teezubereiter Emma in Beige/matt, 1 L",
                "offers": {
                    "type": "Offer",
                    "price": "109.00",
                    "priceCurrency": "EUR"
                }
            }
        },
        {
            "@type": "ListItem",
            "position": 12,
            "item": {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277/simple/68/0201/1315522.jpg",
                "url": "https://www.westwing.de/handgemachte-teekanne-nordic-coal-81615.html",
                "name": "Handgemachte Teekanne Nordic Coal aus Steingut, 1.3 L",
                "offers": {
                    "type": "Offer",
                    "price": "39.99",
                    "priceCurrency": "EUR"
                }
            }
        },
        {
            "@type": "ListItem",
            "position": 13,
            "item": {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277/simple/62/5921/1443918.jpg",
                "url": "https://www.westwing.de/teekanne-maya-103282.html",
                "name": "Handbemalte Steingut Teekanne Maya, 1.2 L",
                "offers": {
                    "type": "Offer",
                    "price": "27.99",
                    "priceCurrency": "EUR"
                }
            }
        },
        {
            "@type": "ListItem",
            "position": 14,
            "item": {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277,pd_38_17_38_17/simple/62/3051/1213300.jpg",
                "url": "https://www.westwing.de/handgemachte-teekanne-70s-119322.html",
                "name": "Handgemachte Teekanne 70's im Retro Style, 1,2 L",
                "offers": {
                    "type": "Offer",
                    "price": "34.99",
                    "priceCurrency": "EUR"
                }
            }
        },
        {
            "@type": "ListItem",
            "position": 15,
            "item": {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277/simple/23/3101/1315020.jpg",
                "url": "https://www.westwing.de/teezubereiter-emma-in-schwarz-glaenzend-81030.html",
                "name": "Teezubereiter Emma in Schwarz glänzend, 1 L",
                "offers": {
                    "type": "Offer",
                    "price": "109.00",
                    "priceCurrency": "EUR"
                }
            }
        },
        {
            "@type": "ListItem",
            "position": 16,
            "item": {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277,pd_38_17_38_17/simple/91/1521/1325838.jpg",
                "url": "https://www.westwing.de/teekanne-picot-99706.html",
                "name": "Teekanne Picot, 800 ml",
                "offers": {
                    "type": "Offer",
                    "price": "44.99",
                    "priceCurrency": "EUR"
                }
            }
        },
        {
            "@type": "ListItem",
            "position": 17,
            "item": {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277/simple/96/8901/1364156.jpg",
                "url": "https://www.westwing.de/handgemachte-teekanne-salt-88185.html",
                "name": "Handgemachte Porzellan Teekanne Salt mit schwarzem Rand, 1.3 L",
                "offers": {
                    "type": "Offer",
                    "price": "44.99",
                    "priceCurrency": "EUR"
                }
            }
        },
        {
            "@type": "ListItem",
            "position": 18,
            "item": {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277/simple/78/777/1356361.jpg",
                "url": "https://www.westwing.de/teekanne-patrizia-62506.html",
                "name": "Handbemalte Teekanne Patrizia mit verspieltem Muster, 1.2 L",
                "offers": {
                    "type": "Offer",
                    "price": "26.99",
                    "priceCurrency": "EUR"
                }
            }
        },
        {
            "@type": "ListItem",
            "position": 19,
            "item": {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277/simple/40/4461/1393105.jpg",
                "url": "https://www.westwing.de/handgefertigte-teekanne-karine-mit-kleinem-muster-130485.html",
                "name": "Handbemalte Teekanne Karine mit kleinem Muster, 1.2 L",
                "offers": {
                    "type": "Offer",
                    "price": "24.99",
                    "priceCurrency": "EUR"
                }
            }
        },
        {
            "@type": "ListItem",
            "position": 20,
            "item": {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277/simple/12/8971/1577237.jpg",
                "url": "https://www.westwing.de/teekanne-coppa-sencha-aus-porzellan-mit-holzgriff-1-l-142151.html",
                "name": "Teekanne Coppa sencha aus Porzellan mit Holzgriff, 1 L",
                "offers": {
                    "type": "Offer",
                    "price": "39.99",
                    "priceCurrency": "EUR"
                }
            }
        },
        {
            "@type": "ListItem",
            "position": 21,
            "item": {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277/simple/96/6661/1414005.jpg",
                "url": "https://www.westwing.de/teekanne-bizz-1-2-l-132244.html",
                "name": "Steingut Teekanne Bizz in Hellbeige gesprenkelt und Holzgriff, 1.2 L",
                "offers": {
                    "type": "Offer",
                    "price": "59.99",
                    "priceCurrency": "EUR"
                }
            }
        },
        {
            "@type": "ListItem",
            "position": 22,
            "item": {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277/simple/26/0571/1509886.jpg",
                "url": "https://www.westwing.de/teekanne-fleur-aus-porzellan-1-l-138584.html",
                "name": "Teekanne Fleur aus Porzellan, 1 L",
                "offers": {
                    "type": "Offer",
                    "price": "44.99",
                    "priceCurrency": "EUR"
                }
            }
        },
        {
            "@type": "ListItem",
            "position": 23,
            "item": {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277,pd_38_17_38_17/simple/77/7941/1289202.jpg",
                "url": "https://www.westwing.de/teekanne-baltika-mit-teesieb-und-deckel-118858.html",
                "name": "Steingut Teekanne Baltika mit Teesieb und Deckel, 700 ml",
                "offers": {
                    "type": "Offer",
                    "price": "29.99",
                    "priceCurrency": "EUR"
                }
            }
        },
        {
            "@type": "ListItem",
            "position": 24,
            "item": {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277/simple/22/8971/1577254.jpg",
                "url": "https://www.westwing.de/stoevchen-sonoko-aus-porzellan-142152.html",
                "name": "Stövchen Sonoko aus Porzellan",
                "offers": {
                    "type": "Offer",
                    "price": "17.99",
                    "priceCurrency": "EUR"
                }
            }
        },
        {
            "@type": "ListItem",
            "position": 25,
            "item": {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277,pd_38_17_38_17/simple/72/861/1244164.jpg",
                "url": "Stövchen Sonoko aus Porzellan",
                "name": "Teezubereiter Emma in Weiß mit Blaustich, 1 L",
                "offers": {
                    "type": "Offer",
                    "price": "109.00",
                    "priceCurrency": "EUR"
                }
            }
        },
        {
            "@type": "ListItem",
            "position": 26,
            "item": {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277/simple/73/861/1250998.jpg",
                "url": "https://www.westwing.de/teezubereiter-emma-in-grau-glaenzend-14593.html",
                "name": "Teezubereiter Emma in Grau glänzend, 1 L",
                "offers": {
                    "type": "Offer",
                    "price": "109.00",
                    "priceCurrency": "EUR"
                }
            }
        },
        {
            "@type": "ListItem",
            "position": 27,
            "item": {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277/simple/71/5301/1242698.jpg",
                "url": "https://www.westwing.de/teekanne-nordic-kitchen-in-schwarz-matt-82898.html",
                "name": "Steingut Teekanne Nordic Kitchen in Schwarz matt, 1 L",
                "offers": {
                    "type": "Offer",
                    "price": "49.99",
                    "priceCurrency": "EUR"
                }
            }
        },
        {
            "@type": "ListItem",
            "position": 28,
            "item": {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277/simple/60/9221/1358344.jpg",
                "url": "https://www.westwing.de/handgemachte-teekanne-bold-basic-97929.html",
                "name": "Handgemachte Keramik Teekanne Bold & Basic, 840 ml",
                "offers": {
                    "type": "Offer",
                    "price": "49.99",
                    "priceCurrency": "EUR"
                }
            }
        },
        {
            "@type": "ListItem",
            "position": 29,
            "item": {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277/simple/52/4461/1393286.jpg",
                "url": "https://www.westwing.de/handgemachte-teekanne-rani-mit-craquele-glasur-130505.html",
                "name": "Handgemachte Teekanne Rani mit Craquelé Glasur und Teesieb",
                "offers": {
                    "type": "Offer",
                    "price": "39.99",
                    "priceCurrency": "EUR"
                }
            }
        },
        {
            "@type": "ListItem",
            "position": 30,
            "item": {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277/simple/83/1071/1509467.jpg",
                "url": "https://www.westwing.de/teekanne-elsa-1-2-l-135000.html",
                "name": "Steingut-Teekanne Elsa mit Blumenmuster, 1.2 L",
                "offers": {
                    "type": "Offer",
                    "price": "27.99",
                    "priceCurrency": "EUR"
                }
            }
        },
        {
            "@type": "ListItem",
            "position": 31,
            "item": {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277/simple/17/3271/1494516.jpg",
                "url": "https://www.westwing.de/teekanne-pilar-aus-keramik-in-dunkelgrau-1-l-136887.html",
                "name": "Teekanne Pilar aus Keramik in Dunkelgrau matt/glänzend, 1 L",
                "offers": {
                    "type": "Offer",
                    "price": "44.99",
                    "priceCurrency": "EUR"
                }
            }
        },
        {
            "@type": "ListItem",
            "position": 32,
            "item": {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277/simple/28/4351/1356796.jpg",
                "url": "https://www.westwing.de/handgemachte-teekanne-camellia-mit-bambusgriff-121724.html",
                "name": "Handgemachte Steingut Teekanne Camellia mit Bambusgriff, 800 ml",
                "offers": {
                    "type": "Offer",
                    "price": "32.99",
                    "priceCurrency": "EUR"
                }
            }
        },
        {
            "@type": "ListItem",
            "position": 33,
            "item": {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277/simple/05/8541/1510699.jpg",
                "url": "https://www.westwing.de/teekanne-theo-in-weiss-matt-116083.html",
                "name": "Steingut Teekanne Theo in Weiß matt, 1.25 L",
                "offers": {
                    "type": "Offer",
                    "price": "94.00",
                    "priceCurrency": "EUR"
                }
            }
        },
        {
            "@type": "ListItem",
            "position": 34,
            "item": {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277/simple/34/2321/1241617.jpg",
                "url": "https://www.westwing.de/xs-teekanne-pixie-98264.html",
                "name": "XS-Teekanne Pixie aus Steingut, 820 ml",
                "offers": {
                    "type": "Offer",
                    "price": "23.99",
                    "priceCurrency": "EUR"
                }
            }
        },
        {
            "@type": "ListItem",
            "position": 35,
            "item": {
                "@type": "Product",
                "image": "https://static.westwingnow.de/image/upload/e_trim/f_auto/c_pad,w_208,h_277/simple/98/4351/1455803.jpg",
                "url": "https://www.westwing.de/handgefertigte-teekanne-aura-mit-sieb-und-bambusgriff-121730.html",
                "name": "Handgemachte Porzellan Teekanne Aura mit Teesieb und Bambusgriff, 1 L",
                "offers": {
                    "type": "Offer",
                    "price": "34.99",
                    "priceCurrency": "EUR"
                }
            }
        }
    ]
};
