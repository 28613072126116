import React, { ReactElement, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { forDesktop, isDesktop, isSemiWideScreen } from '@westwing/ui-kit/breakPoints';
import ErrorBoundary from '../Generic/ErrorBoundaries';
import { NavigationBaseData } from '../Generic/NavigationItem';
import { OverlayId } from '../Overlays/types';
import CategoryFilterBucket from './CategoryFilterBucket';
import FilterAndSortBox from './FilterAndSortBox';
import useBreadcrumbsState from './hooks/useBreadcrumbsState';
import useListingPageTitle from './hooks/useListingPageTitle';
import { computeSeoIndexableAndFollow } from './services/seo';
import useListingPageUrlState from './hooks/useListingPageUrlState';
import useListingPageBackToTopButton from './hooks/useListingPageBackToTopButton';
import BackButton from './BackButton';
import PLPGrid from './ProductGrid/index';
import SideNavigation from './SideCategoryNavigation/SideNavigation';
import PageTitle from './Header/PageTitle';
import PageHeader from './Header/StyledPageHeader';
import Pagination from './Pagination/index';
import RegularTitle from './Header/RegularTitle';
import DidYouMeanTitle from './Header/DidYouMeanTitle';
import BreadCrumbs from './BreadCrumbs';
import AppliedFiltersSliderContainer from './AppliedFilters';
import Banners from './Banners/index';
import ShopToClubBanner from './Banners/ShopToClubBanner';
import ListingPageSeoHead from './Seo/SeoHead';
import CrossCategoryNavigation from './CrossCategoryNavigation';
import getCategoryPageBackOptions from './backOptions';
import shouldRenderSeoText, { SeoTextBottom, SeoTextTop } from './Seo';
import {
    BreadCrumbsWrapper,
    ListingPageContainer,
    ListingPageContentWrapper,
    MainContent,
    SeoTextBottomWrapper,
    SeoTextTopWrapper,
    SideBar,
    StyledCategoryNavContainer,
    StyleGridWrapper,
} from './ListingPageElements';
import SkuDiverProvider from './SkuDiver/SkuDiverContext';
import SeoSlider from './SeoSlider';
import ListingPageBubbleSlider from './ListingPageBubbleSlider';
import BackToTopButton from './BackToTopButton';
import { useListingPageInfusions } from './hooks/useListingPageInfusions';
import { SHOP_TO_CLUB_FOOTER_LINK } from 'Client/redux/cms/constants';
import { ROBOTS_INDEX_CONTENT } from 'Client/redux/seo/types';
import { PageType } from 'AppShell/appshell_types';
import { NormalizedEntitiesAndUrlKeys } from 'Client/redux/category/types';
import useConfig from 'AppShell/hooks/useConfig';
import { showOverlay } from 'Client/redux/header/overlays/actions';
import { ProductList } from 'Common/ga/types';
import useFeatureToggle from 'Client/hooks/useFeatureToggle';
import { useBellaSelector } from 'Client/redux/hooks';
import useGa from 'Client/hooks/useGa';
import useP100FilterRedesign from 'Client/components/Generic/FilterAndSortBox/useP100FilterRedesign';
import useBellaTranslator from 'Client/hooks/useTranslator';
import PriceDisclaimer from 'Client/components/Generic/PriceDisclaimer';
import { ColumnLayoutVariants, ProductsState } from 'Client/redux/products/types';
import { BellaTestIds } from 'Common/qaIds';
import { getNavigationForCategory } from 'Client/components/ListingPage/CrossCategoryNavigation/crossCategoryNavigationService';
import { reformatImage } from '@westwing/ui-kit/utils/image';

const StyledCrossCategoryNavigation = styled(CrossCategoryNavigation)<{
    $categoryBubblesDisabledOnDesktop?: boolean;
    $hideOnDesktop: boolean;
}>`
    ${({ $hideOnDesktop, $categoryBubblesDisabledOnDesktop }) =>
        ($hideOnDesktop || $categoryBubblesDisabledOnDesktop) &&
        css`
            ${forDesktop`
                display: none;
            `}
        `}
`;

const OPEN_GRAPH_IMAGE = {
    height: '215',
    width: '259',
    placeholder: 'https://static.westwingnow.de/ww-shop/assets/images/westwing-now-logo.svg',
};

// eslint-disable-next-line complexity
const ListingPage = (): ReactElement => {
    const gaTracking = useGa();
    const {
        p100ImageOnHoverPlp,
        p100FiltersRedesign,
        p100FourColumnPlp,
        p100AATest1,
        p100FreistellerImages,
        p100EnableNewOmnibusDirective,
    } = useFeatureToggle();
    const categories = useBellaSelector(state => state.categories);
    const fallbackCrossCategoryNavigation = useBellaSelector(state => state.products.crossCategoryNavigation);
    const filters = useBellaSelector(state => state.products.filters);
    const productsState = useBellaSelector<ProductsState>(state => state.products);
    const isProductsFetchingComplete = useBellaSelector(state => state.products.isFetchingComplete) as boolean;
    const shopToClubFooterBanners = useBellaSelector(state => state.cms.linkedBanners.data[SHOP_TO_CLUB_FOOTER_LINK]);
    const { seoData } = useBellaSelector(state => state.seoData);
    const totalCount = useBellaSelector(state => state.products.totalCount);
    const identifier = useBellaSelector(state => state.products.identifier);
    const isMoodImageHovered = useBellaSelector(state => state.products.isMoodImageHovered);
    const plpGridColumnView = useBellaSelector(state => state.products.plpGridColumnView);
    const showFourColumn = plpGridColumnView === ColumnLayoutVariants.FOUR && p100FourColumnPlp;
    const { hideDefaultSort } = useP100FilterRedesign();
    const { infusions } = useListingPageInfusions();
    const mediaInfusion = useBellaSelector(state => state.mediaInfusion);
    const t = useBellaTranslator();
    const [urlState] = useListingPageUrlState();
    const config = useConfig();
    const dispatch = useDispatch();
    const { listingPageKey, listingPageType } = urlState;
    const { listingGridRef, showBackToTopButton } = useListingPageBackToTopButton(isProductsFetchingComplete);

    const [pageHeaderTitle] = useListingPageTitle(urlState, seoData);
    const titleTextDescription = listingPageType === PageType.BRANDS ? seoData.text : '';

    if (listingPageType === PageType.NEWPRODUCTS) {
        seoData.seoDescription = t('newProductsPageSeoDescription');
    }

    const shouldHideCategoryBubblesOnDesktop = ![PageType.NEWPRODUCTS, PageType.ALLPRODUCTS, PageType.LANDING].includes(
        listingPageType
    );

    const crossCategoryNavigation =
        listingPageType === PageType.CATEGORY
            ? getNavigationForCategory(listingPageKey, categories.normalized)
            : fallbackCrossCategoryNavigation;

    useEffect(() => {
        if (isDesktop()) {
            gaTracking.trackVariation('p100FiltersRedesign', p100FiltersRedesign);
        }

        if (isSemiWideScreen()) {
            gaTracking.trackVariation('p100AATest1', p100AATest1);
            gaTracking.trackVariation('p100FourColumnPlp', p100FourColumnPlp);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [identifier]);

    useEffect(() => {
        const robotsIndexableContent = computeSeoIndexableAndFollow(urlState);
        dispatch({ type: ROBOTS_INDEX_CONTENT, payload: robotsIndexableContent });
    }, [dispatch, urlState]);

    const isSearch = listingPageType === PageType.ALLPRODUCTS;

    const handleFiltersClick = () => {
        dispatch(showOverlay(OverlayId.FILTERS_FLYOUT));
    };
    const firstProductImage = productsState.products[0]?.productImage?.path;
    const firstProductImageUrl = firstProductImage
        ? reformatImage(firstProductImage, config.staticCmsHost, {
              height: OPEN_GRAPH_IMAGE.height,
              padding: true,
              width: OPEN_GRAPH_IMAGE.width,
          })
        : OPEN_GRAPH_IMAGE.placeholder;

    const { entities = {}, urlKeys = {} } = categories.normalized || ({} as NormalizedEntitiesAndUrlKeys);
    const { breadcrumbList } = useBreadcrumbsState({ entities, urlKeys }, urlState, {
        pageHeaderTitle,
        host: '/',
    });

    const isCategoryPage = listingPageType === PageType.CATEGORY;
    const [backLink, isRootLink] = isCategoryPage
        ? getCategoryPageBackOptions(breadcrumbList, listingPageKey, categories.normalized)
        : [crossCategoryNavigation.parentCategory.link, crossCategoryNavigation.parentCategory.isRoot];

    const pageHasCategoryFilter = listingPageType !== PageType.CATEGORY;
    const { seoText = '', seoTextBottom = '', similarPages = [], seoSlider } = seoData;
    const bubbles: NavigationBaseData[] = similarPages.map(item => ({
        name: item.similar_page_text,
        link: item.similar_page_link,
        isSelected: false,
    }));

    useEffect(() => {
        if (isMoodImageHovered) {
            gaTracking.trackVariation('p100ImageOnHoverPlp', p100ImageOnHoverPlp);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMoodImageHovered]);

    const hasDiscountedProductOnPage = useCallback(
        () => productsState.products.some(product => product.numericPrice < product.numericOriginalPrice),
        [productsState]
    );

    return (
        <ListingPageContainer>
            <SkuDiverProvider>
                <ListingPageContentWrapper>
                    <BreadCrumbsWrapper data-testid="bread_crumbs_wrapper">
                        {isCategoryPage ? <BreadCrumbs breadcrumbList={breadcrumbList} /> : ''}
                    </BreadCrumbsWrapper>
                    <SideBar>
                        {!pageHasCategoryFilter ? (
                            <StyledCategoryNavContainer data-testid={BellaTestIds.plpSidebarCategoriesNavDesktop}>
                                <SideNavigation />
                            </StyledCategoryNavContainer>
                        ) : (
                            <CategoryFilterBucket filters={filters} />
                        )}
                    </SideBar>
                    {shouldRenderSeoText(urlState) && (
                        <SeoTextTopWrapper>
                            <SeoTextTop rawHtml={seoText} />
                        </SeoTextTopWrapper>
                    )}
                    <MainContent>
                        <ListingPageSeoHead
                            pageTitle=""
                            pageHeaderTitle={pageHeaderTitle}
                            urlState={urlState}
                            seoData={seoData}
                            config={config}
                            totalCount={totalCount}
                            entities={entities}
                            urlKeys={urlKeys}
                            openGraphImage={firstProductImageUrl}
                        />
                        <ErrorBoundary boundaryName="ListingPageHeader" fallbackComponent={null}>
                            <Banners
                                banners={seoData.linkedBanners}
                                bannerTitle={pageHeaderTitle}
                                mediaInfusion={mediaInfusion}
                            />

                            <PageHeader>
                                {!!crossCategoryNavigation?.categories?.length && (
                                    <ErrorBoundary boundaryName="CrossCategoryNavBackBtn" fallbackComponent={null}>
                                        <BackButton
                                            hideOnDesktop={hideDefaultSort}
                                            to={backLink}
                                            isRoot={isRootLink}
                                            categoryBubblesDisabledOnDesktop={shouldHideCategoryBubblesOnDesktop}
                                        />
                                    </ErrorBoundary>
                                )}

                                <PageTitle>
                                    <RegularTitle
                                        pageTitle={pageHeaderTitle}
                                        totalCount={totalCount}
                                        isSearch={isSearch}
                                        isRoot={isRootLink}
                                        titleTextDescription={titleTextDescription}
                                    />
                                    <DidYouMeanTitle />
                                </PageTitle>
                                <ErrorBoundary boundaryName="CrossCategoryNav" fallbackComponent={null}>
                                    <StyledCrossCategoryNavigation
                                        $hideOnDesktop={hideDefaultSort}
                                        $categoryBubblesDisabledOnDesktop={shouldHideCategoryBubblesOnDesktop}
                                        categories={crossCategoryNavigation.categories}
                                    />
                                </ErrorBoundary>
                            </PageHeader>
                        </ErrorBoundary>
                        <StyleGridWrapper $noPadding={p100FreistellerImages} ref={listingGridRef}>
                            <FilterAndSortBox handleFiltersClick={handleFiltersClick} totalCount={totalCount} />
                            <AppliedFiltersSliderContainer />
                            <PLPGrid
                                products={productsState.products}
                                alternativeProducts={productsState.alternativeProducts}
                                list={ProductList.products}
                                infusions={infusions}
                                showFourColumn={showFourColumn}
                            />
                            {!p100EnableNewOmnibusDirective && (
                                <PriceDisclaimer pageHasSpecialPrice={hasDiscountedProductOnPage()} />
                            )}
                            <Pagination totalCount={totalCount} />
                            <SeoSlider sliderData={seoSlider} />
                            <ListingPageBubbleSlider bubbles={bubbles} />
                            <ShopToClubBanner shopToClubFooterBanners={shopToClubFooterBanners} />
                        </StyleGridWrapper>
                    </MainContent>
                    {shouldRenderSeoText(urlState) && (
                        <SeoTextBottomWrapper>
                            <SeoTextBottom rawHtml={seoTextBottom} />
                        </SeoTextBottomWrapper>
                    )}
                    {showBackToTopButton && <BackToTopButton />}
                </ListingPageContentWrapper>
            </SkuDiverProvider>
        </ListingPageContainer>
    );
};

export default ListingPage;
