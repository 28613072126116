import React, { FC, ReactElement, useRef } from 'react';
import useLazyImageReducer from '../../hooks/lazyImages';
import useElementIntersection from '../../hooks/useElementIntersection';
import { lazyImageAction } from '../../hooks/lazyImages/actions';
import { INTERSECTION_ACTION } from '../../hooks/lazyImages/types';
import { ObserverOptions } from '../../../common/helpers/viewport';

interface BannerImpressionProps {
    className?: string;
    action: () => void;
    intersectionOptions?: ObserverOptions;
}

const defaultIntersectionOptions = { rootMargin: '0px 0px 10px 0px' };

const GenericImpressionWrapper: FC<BannerImpressionProps> = ({
    children,
    className,
    action,
    intersectionOptions,
}): ReactElement => {
    const bannerElement = useRef<HTMLDivElement>(null);
    const [, dispatch] = useLazyImageReducer({
        isLoaded: false,
        hasComeIntoView: false,
        showSpinner: false,
    });

    useElementIntersection(
        bannerElement,
        () => {
            dispatch(lazyImageAction(INTERSECTION_ACTION, true));
            action();
        },
        intersectionOptions || defaultIntersectionOptions
    );

    return (
        <div className={className} ref={bannerElement}>
            {children}
        </div>
    );
};

export default GenericImpressionWrapper;
