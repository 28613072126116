import React, { ReactElement, FC } from 'react';
import GenericPagination from '../../Pagination/index';
import useListingPageState from '../hooks/useListingPageUrlState';
import { convertListingPageStateToUrl } from '../services/listingPageUrlService';
import { getPageCountData } from '../../../../common/paginationService';
import useFeatureToggle from 'Client/hooks/useFeatureToggle';

interface PaginationStateProps {
    totalCount: number;
}

export const Pagination: FC<PaginationStateProps> = ({ totalCount }): ReactElement => {
    const [listingPageState] = useListingPageState();
    const { p100Plp120PerPage } = useFeatureToggle();
    const perPage = p100Plp120PerPage ? 120 : 90;
    const { currentPage, prevPageNumber, nextPageNumber, totalPages } = getPageCountData(
        totalCount,
        listingPageState.page,
        perPage
    );

    const toPrevPage =
        prevPageNumber === null
            ? undefined
            : convertListingPageStateToUrl({ ...listingPageState, page: prevPageNumber });

    const toNextPage =
        nextPageNumber === null
            ? undefined
            : convertListingPageStateToUrl({ ...listingPageState, page: nextPageNumber });

    return (
        <GenericPagination
            toPrevPage={toPrevPage}
            toNextPage={toNextPage}
            currentPage={currentPage}
            totalPages={totalPages}
        />
    );
};

export default Pagination;
