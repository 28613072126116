import React, { ReactElement, FunctionComponent, useReducer, useContext, useMemo } from 'react';

interface SkuDiverState {
    selectedSKUs: Array<string>;
}

enum SkuDriverActions {
    add = 'add',
    remove = 'remove',
    clear = 'clear',
}

interface SkuDiver {
    state: SkuDiverState;
    add: (sku: string) => void;
    remove: (sku: string) => void;
    clearList: () => void;
}

export const SkuDiverContext = React.createContext<SkuDiver>({
    state: {
        selectedSKUs: [],
    },
    add: () => null,
    remove: () => null,
    clearList: () => null,
});

function reducer(state: SkuDiverState, action: { type: string; sku?: string }): SkuDiverState {
    switch (action.type) {
        case SkuDriverActions.add:
            if (!action.sku) {
                return state;
            }
            return { selectedSKUs: [...state.selectedSKUs, action.sku] };
        case SkuDriverActions.remove:
            return { selectedSKUs: state.selectedSKUs.filter(sku => sku !== action.sku) };
        case SkuDriverActions.clear:
            return { selectedSKUs: [] };
        default:
            throw new Error();
    }
}

const initialState: SkuDiverState = {
    selectedSKUs: [],
};
export const useSkuDiver = () => useContext(SkuDiverContext);

export const SkuDiverProvider: FunctionComponent = ({ children }): ReactElement => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const add = (sku: string) => dispatch({ type: SkuDriverActions.add, sku });
    const remove = (sku: string) => dispatch({ type: SkuDriverActions.remove, sku });
    const clearList = () => dispatch({ type: SkuDriverActions.clear });
    const memoizedValue = useMemo(
        () => ({
            state,
            add,
            remove,
            clearList,
        }),
        [state]
    );
    return <SkuDiverContext.Provider value={memoizedValue}>{children}</SkuDiverContext.Provider>;
};

export default SkuDiverProvider;
