import React, { ReactElement, useEffect, useState } from 'react';
import loadable from '@loadable/component';
import styled, { css } from 'styled-components';
import { button } from '@westwing/ui-kit/typography';
import { forDesktop, forIE } from '@westwing/ui-kit/breakPoints';
import { Direction, StyledArrowIcon, StyledPlusIcon } from '@westwing/ui-kit/Icons/StyledIcons';
import { scroll } from '@westwing/ui-kit/utils';
import { Filter, Translator, NewFilterValues, AppliedFilter } from './types';
import FilterContainer from 'Client/components/Generic/GenericFiltersFlyout/FilterContainer';
import { getAppliedFilterGroup } from 'Client/components/Generic/GenericFiltersFlyout/filterValueService';
import useP100FilterRedesign from 'Client/components/Generic/FilterAndSortBox/useP100FilterRedesign';
import { BellaTestIds } from 'Common/qaIds';
import useRouter from 'AppShell/hooks/useRouter';
import usePrevious from 'Client/hooks/usePrevious';

const DEFAULT_FILTERS_COUNT = 4;

const DropdownOverlay = loadable(() => import('../Containers/DropdownOverlay'));

const FilterItemContainer = styled.div<{ isBoxed?: boolean }>`
    display: inline-block;
    padding: ${({ isBoxed }) => (isBoxed ? '5px' : '7px')} 0;
    margin-right: ${({ isBoxed }) => (isBoxed ? '8px' : '25px')};
`;

const FiltersContainer = styled.div<{ isBoxed?: boolean }>`
    display: none;

    ${({ isBoxed }) =>
        css`
            ${forDesktop`
                display: block;
                align-items: center;
                padding: 7px 0;

                /* left align of the first filter */
                ${FilterItemContainer}:first-of-type {
                    div[class^="DropdownOverlay"] {
                        left: ${isBoxed ? 0 : '-10px'};
                    }
                }
        `}
        `}
`;

const FilterItemWrapper = styled.button<{ isBoxed?: boolean }>`
    text-align: left;
    display: flex;
    cursor: pointer;
    align-items: center;
    color: ${({ theme }) => theme.colors.charcoal100};
    box-sizing: border-box;

    ${({ isBoxed }) =>
        isBoxed &&
        css`
            ${forDesktop`
                border: 1px solid ${({ theme }) => theme.colors.charcoal20};
                padding: 10px 15px;
            `}
        `}

    ${forIE`
        padding-right: 20px;
    `}
`;

const MoreFiltersButton = styled(FilterItemWrapper)`
    padding-right: 0px;
`;

const makeBold = css`
    font-family: ${({ theme }) => theme.fonts.fontBrandonMedium};
    font-weight: 500;
`;
const DisplayName = styled.div<{ bold?: boolean }>`
    ${button}
    line-height: 1.4;
    letter-spacing: normal;
    ${({ bold }) => (bold ? makeBold : '')}
`;

const IconButtonWrapper = styled.div`
    margin: 0 0 3px 10px;
    display: flex;
    align-self: center;
`;

const PlusIcon = styled(StyledPlusIcon)`
    &:before,
    &:after {
        width: 9px;
        height: 9px;
    }
`;

const PlusIconWrapper = styled(IconButtonWrapper)`
    margin-bottom: 0;
    margin-left: 5px;
`;

const OverlayWrapper = styled.div`
    background: ${({ theme }) => theme.colors.white};
    width: 268px;
    border: 1px solid ${({ theme }) => theme.colors.charcoal20};
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    margin-top: 10px;
    padding: 15px;
    max-height: 400px;
    overflow: hidden;
    ${scroll('y', true)}
`;

interface ExpandableFilterProps {
    filter: Filter;
    appliedFilter: AppliedFilter;
    onFilterValueChange: (newValue: NewFilterValues) => void;
    onFilterOpen?: (filter: Filter) => void;
}

const ExpandableFilter = ({ filter, appliedFilter, onFilterValueChange, onFilterOpen }: ExpandableFilterProps) => {
    const { isBoxed } = useP100FilterRedesign();
    const [isExpanded, setIsExpanded] = useState(false);
    const arrowDirection = isExpanded ? Direction.Up : Direction.Down;

    const onExpand = () => {
        if (isExpanded) {
            return;
        }
        if (onFilterOpen) {
            onFilterOpen(filter);
        }
        setIsExpanded(true);
    };

    const onClose = () => {
        setIsExpanded(false);
    };

    return (
        <FilterItemContainer data-testid="desktop-filter-container" isBoxed={isBoxed}>
            <FilterItemWrapper onClick={onExpand} isBoxed={isBoxed} data-testid={BellaTestIds.boxedFilter}>
                <DisplayName bold={isExpanded}>{filter.displayName}</DisplayName>
                <IconButtonWrapper>
                    <StyledArrowIcon direction={arrowDirection} size={8} />
                </IconButtonWrapper>
            </FilterItemWrapper>
            {isExpanded && (
                <DropdownOverlay onOverlayClose={onClose} rightPosition={-151}>
                    <OverlayWrapper>
                        <FilterContainer
                            group={filter}
                            filterValue={appliedFilter.values}
                            handleValueChange={onFilterValueChange}
                        />
                    </OverlayWrapper>
                </DropdownOverlay>
            )}
        </FilterItemContainer>
    );
};

interface DesktopFiltersStripProps {
    className?: string;
    filters: Filter[];
    translator: Translator;
    appliedFilters: AppliedFilter[];
    onFilterValueChange: (newValue: NewFilterValues) => void;
    handleExpandableFilterClick?: (filter: Filter) => void;
    handleShowAll?: () => void;
}

const DesktopFiltersStrip = ({
    className,
    filters,
    translator,
    appliedFilters,
    onFilterValueChange,
    handleExpandableFilterClick,
    handleShowAll = () => {},
}: DesktopFiltersStripProps): ReactElement => {
    const {
        location: { pathname },
    } = useRouter();
    const previousPathname = usePrevious(pathname);
    const { hideDefaultSort } = useP100FilterRedesign();
    const { isBoxed } = useP100FilterRedesign();
    const hasMoreFiltersToShow = filters.length - DEFAULT_FILTERS_COUNT > 1;
    const activeFilterLength = hasMoreFiltersToShow ? DEFAULT_FILTERS_COUNT : filters.length;
    const activeFilters = filters.slice(0, activeFilterLength);
    const [showHiddenFilters, setShowHiddenFilters] = useState(hideDefaultSort);

    const showAllFilters = () => {
        handleShowAll();
        setShowHiddenFilters(true);
    };

    useEffect(() => {
        if (previousPathname) {
            const previousPlp = previousPathname.split('/')[1];
            const currentPlp = pathname.split('/')[1];

            if (previousPlp === currentPlp) {
                return;
            }
        }
        if (!hideDefaultSort) {
            setShowHiddenFilters(false);
        }
    }, [pathname, previousPathname, hideDefaultSort]);

    const filtersToDisplay = showHiddenFilters ? filters : activeFilters;

    return (
        <FiltersContainer className={className} isBoxed={isBoxed}>
            {filtersToDisplay.map(filter => (
                <ExpandableFilter
                    key={filter.name}
                    filter={filter}
                    appliedFilter={getAppliedFilterGroup(appliedFilters, filter)}
                    onFilterValueChange={onFilterValueChange}
                    onFilterOpen={handleExpandableFilterClick}
                />
            ))}
            {hasMoreFiltersToShow && !showHiddenFilters && (
                <FilterItemContainer>
                    <MoreFiltersButton data-testid={BellaTestIds.moreFiltersButton} onClick={showAllFilters}>
                        <DisplayName bold>{translator('More_filters')}</DisplayName>
                        <PlusIconWrapper>
                            <PlusIcon />
                        </PlusIconWrapper>
                    </MoreFiltersButton>
                </FilterItemContainer>
            )}
        </FiltersContainer>
    );
};

export default DesktopFiltersStrip;
