import { useRef, useEffect, useState } from 'react';

const useListingPageBackToTopButton = (pageDataFetchingIsComplete: boolean) => {
    const listingGridRef = useRef<HTMLDivElement>(null);
    const [showBackToTopButton, setShowBackToTopButton] = useState(false);

    useEffect(() => {
        if (listingGridRef && listingGridRef.current) {
            setShowBackToTopButton(listingGridRef.current.offsetHeight >= 2 * window.innerHeight);
        }
    }, [pageDataFetchingIsComplete]);

    return {
        listingGridRef,
        showBackToTopButton,
    };
};

export default useListingPageBackToTopButton;
