import { forDesktop } from '@westwing/ui-kit/breakPoints';
import { header6, plainTextStyle } from '@westwing/ui-kit/typography';
import React, { FC } from 'react';
import styled, { css, DefaultTheme, FlattenInterpolation, ThemeProps } from 'styled-components';
import BellaHybridLink from '../Generic/Links/BellaHybridLink';

export type Typography = FlattenInterpolation<ThemeProps<DefaultTheme>>;

const Title = styled.h2<{ desktopTypography?: Typography }>`
    ${header6}
    ${({ desktopTypography }) =>
        desktopTypography
            ? css`
                  ${forDesktop`
                    ${desktopTypography};
                `}
              `
            : ''}
    line-height: 25px;
    margin-bottom: 0;
    color: ${({ theme }) => theme.colors.charcoal100};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    ${forDesktop`
        margin-top: 8px;
    `}
`;

const SubTitle = styled.p`
    ${plainTextStyle}
    line-height: 19.6px;
    margin: 3px 0 5px;
    color: ${({ theme }) => theme.colors.charcoal100};
`;

const Cta = styled.p`
    ${plainTextStyle}
    line-height: 14px;
    text-decoration: underline;
    margin: 0;
    padding-top: 2px;
    color: ${({ theme }) => theme.colors.charcoal100};
`;

const BannerTextWrapper = styled.div`
    padding-bottom: 40px;
    ${forDesktop`
        padding-top: 0;
    `}
`;

interface TextProps {
    title: string;
    subtitle?: string;
    ctaText: string;
    className?: string;
    'data-testid'?: string;
}

interface TextLinkProps extends TextProps {
    targetUrl: string;
    onClick?: () => void;
    desktopTypography?: Typography;
    openInNewTab?: boolean;
}

const SecondaryBannerTextInner: FC<{
    title: string;
    subTitle?: string;
    ctaText: string;
    desktopTypography?: Typography;
}> = ({ title, subTitle, ctaText, desktopTypography }) => (
    <>
        <Title desktopTypography={desktopTypography}>{title}</Title>
        {subTitle ? <SubTitle>{subTitle}</SubTitle> : ''}
        <Cta>{ctaText}</Cta>
    </>
);

export const BannerTitleLink: FC<TextLinkProps> = ({
    title,
    subtitle,
    ctaText,
    targetUrl,
    onClick,
    className,
    desktopTypography,
    openInNewTab,
    ...rest
}) => (
    <BannerTextWrapper className={className}>
        <BellaHybridLink
            to={targetUrl}
            onClick={onClick}
            testId={rest['data-testid']}
            target={openInNewTab ? '_blank' : undefined}
        >
            <SecondaryBannerTextInner
                title={title}
                subTitle={subtitle}
                ctaText={ctaText}
                desktopTypography={desktopTypography}
            />
        </BellaHybridLink>
    </BannerTextWrapper>
);

export const BannerTitle: FC<TextProps> = ({ title, subtitle, ctaText, className, ...rest }) => (
    <BannerTextWrapper className={className} data-testid={rest['data-testid']}>
        <div className={className}>
            <SecondaryBannerTextInner title={title} subTitle={subtitle} ctaText={ctaText} />
        </div>
    </BannerTextWrapper>
);
