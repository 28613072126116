/* eslint-disable camelcase */
import { Action } from 'redux';
import { GenericShopApiResponse } from '../../../common/apiAdapters/types';
import { FilterType, pathFilterNames } from '../products/types';
import { AppliedFilter } from 'Client/components/Generic/FilterAndSortBox/types';

export const FETCH_SEO = 'FETCH_SEO';
export const FETCH_SEO_DEFAULT = 'FETCH_SEO_DEFAULT';
export const FETCH_SEO_SUCCESS = 'FETCH_SEO_SUCCESS';
export const FETCH_SEO_FAILED = 'FETCH_SEO_FAILED';
export const ROBOTS_INDEX_CONTENT = 'ROBOTS_INDEX_CONTENT';

export type SeoResponse = GenericShopApiResponse<SeoDataRawResponse>;

export interface SimilarPage {
    similar_page_text: string;
    similar_page_link: string;
}

export interface SliderCardItemRaw {
    title: string;
    topic_title: string;
    cta_text: string;
    image: string;
    link: string;
}

export interface SliderDataRaw {
    main_title?: string;
    items?: SliderCardItemRaw[];
}

export interface SimilarLink {
    text: string;
    url_key: string;
    filters: AppliedFilter<FilterType>[];
}

export interface SeoHomePageRawResponce {
    seoTitle: string;
    seoDescription: string;
    seoText?: '';
    seoTextBottom?: '';
    linkedBanners?: LinkedBannersRaw[];
    similarPages: SimilarPage[];
    metaRobots?: string;
    infusionSliders: string;
    mobilePageHeader: string;
    canonical: string;
}

export type LanguageLocalizedFilters = Partial<Record<pathFilterNames, { values: string[] }>>;
export type LocalizedFiltersData = Record<string, LanguageLocalizedFilters>;

export interface SeoDataRawResponse {
    seoTitle: string;
    seoDescription: string;
    text?: string;
    seoText?: string;
    seoTextBottom?: string;
    linkedBanners?: LinkedBannersRaw[];
    available_in: {};
    mobilePageHeader: string;
    seoHeadline: string;
    seoRobots?: string;
    similarPages: SimilarPage[];
    similarLinks: SimilarLink[];
    seoSlider: SliderDataRaw;
    localized_filters: LocalizedFiltersData;
    category_available_in?: {};
    isPartOfABTest?: boolean;
}
export interface LinkedBannersRaw {
    linked_banners_banner_image: string;
    linked_banners_banner_link: string;
    linked_banners_banner_desktop_image: string;
}
export interface LinkedBanners {
    bannerImage: string;
    desktopBannerImage: string;
    bannerLink: string;
}

export interface SliderCardItem {
    title: string;
    topicTitle: string;
    ctaText: string;
    image: string;
    link: string;
}

export interface SliderData {
    mainTitle: string;
    items: SliderCardItem[];
}

export interface SeoData {
    seoTitle: string;
    seoDescription: string;
    text?: string;
    seoText?: string;
    seoTextBottom?: string;
    linkedBanners?: LinkedBanners[];
    availableIn: { [key: string]: string };
    categoryAvailableIn?: { [key: string]: string };
    mobilePageHeader: string;
    seoHeadline: string;
    metaRobots?: string;
    canonical?: string;
    similarPages: SimilarPage[];
    seoSlider: SliderData | null;
    localizedFilters: LocalizedFiltersData;
    similarLinks: SimilarLink[];
    isPartOfABTest?: boolean;
}

export interface SeoState {
    seoData: SeoData;
    error?: any;
    isFetching: boolean;
    didInvalidate: boolean;
    identifier: string;
}

export interface SetDefaultSeoAction extends Action {
    type: typeof FETCH_SEO_DEFAULT;
    data: SeoData;
    identifier: string;
}
export interface FetchSeoAction extends Action {
    type: typeof FETCH_SEO;
    identifier: string;
}
export interface FetchRobotIndex extends Action {
    type: typeof ROBOTS_INDEX_CONTENT;
    payload: string;
}

export interface FetchSeoSuccessAction extends Action {
    type: typeof FETCH_SEO_SUCCESS;
    payload: SeoResponse;
}

export interface FetchSeoFailedAction extends Action {
    type: typeof FETCH_SEO_FAILED;
    payload: Error;
}

export type SeoActionTypes =
    | FetchSeoAction
    | FetchSeoSuccessAction
    | FetchSeoFailedAction
    | FetchRobotIndex
    | SetDefaultSeoAction;
