import React, { FC, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import loadable from '@loadable/component';
import { plainTextStyle } from '@westwing/ui-kit/typography';
import { StyledPlusIcon, StyledMinusIcon } from '@westwing/ui-kit/Icons/StyledIcons';
import StateInterface from '../../../redux/types';
import { CategoriesState } from '../../../redux/category/types';
import TextWrapperPadding from '../../Generic/BoxHelpers';
import { getCombinedDisplayValue } from '../../../filterDisplayValueService';
import useConfig from 'AppShell/hooks/useConfig';
import { useFilterState } from '../hooks/useFilterState';
import FilterContainer, { UnknownFilter } from '../../Generic/GenericFiltersFlyout/FilterContainer';
import { Filter, FilterTypes, NewFilterValues } from 'Client/components/Generic/FilterAndSortBox/types';

const RangeFilters = loadable(() => import('../../Generic/GenericFiltersFlyout/RangeFilters'));

const FiltersGroupContainer = styled.div`
    border-top: 1px solid ${({ theme }) => theme.colors.charcoal20};
    padding: 15px 0;

    :first-child {
        border-top: none;
    }
`;

export const StyledMenuItem = styled.button`
    display: grid;
    width: 100%;
    grid-template-columns: auto 1fr 10px;
    grid-gap: 4px;
`;

const StyledTextSpan = styled.span`
    ${plainTextStyle}
`;

const StyledRestrictedLengthSpan = styled(StyledTextSpan)`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: ${({ theme }) => theme.colors.turquoise100};
`;

const StyledFilterDescription = styled.div`
    color: ${({ theme }) => theme.colors.turquoise100};
    display: grid;
    grid-template-columns: auto auto auto 1fr;
    text-transform: capitalize;
`;

const StyledParentContainer = styled.div`
    padding-top: 10px;
`;

type Props = OwnProps;

interface OwnProps {
    group: Filter;
    handleValueChange: (value: NewFilterValues) => void;
    onMenuItemClick: (isOpen: boolean) => void;
}

interface DescriptionProps {
    children: string;
}

const Description: FC<DescriptionProps> = ({ children }) => (
    <StyledFilterDescription>
        (<StyledRestrictedLengthSpan>{children}</StyledRestrictedLengthSpan>)
    </StyledFilterDescription>
);

export const FilterGroup: FC<Props> = ({ group, handleValueChange, onMenuItemClick }) => {
    const filters = useSelector<StateInterface, Filter[]>(state => state.products.filters);
    const categories = useSelector<StateInterface, CategoriesState>(state => state.categories);

    useEffect(() => {
        RangeFilters.preload();
    });

    const [showChildren, setShowChildren] = useState(false);
    const { currency } = useConfig();

    const [appliedFilter, onFilterChange] = useFilterState(group);
    const filterValueText = getCombinedDisplayValue(appliedFilter, { categories, filters, currency });

    const onFilterValueChange = (newFilterValue: NewFilterValues) => {
        handleValueChange(newFilterValue);
        onFilterChange(newFilterValue);
    };

    if (!Object.values(FilterTypes).includes(group.type)) {
        return <UnknownFilter filter={group} />;
    }

    const onMenuClick = () => {
        onMenuItemClick(!showChildren);
        setShowChildren(!showChildren);
    };

    return (
        <FiltersGroupContainer data-testid={group.name}>
            <StyledMenuItem onClick={onMenuClick}>
                <TextWrapperPadding leftPadding={0} rightPadding={10}>
                    <StyledTextSpan>{group.displayName}</StyledTextSpan>
                </TextWrapperPadding>
                {filterValueText ? <Description>{filterValueText}</Description> : <div />}
                {!showChildren ? <StyledPlusIcon /> : <StyledMinusIcon />}
            </StyledMenuItem>
            {showChildren && (
                <StyledParentContainer>
                    <FilterContainer
                        filterValue={appliedFilter.values}
                        group={group}
                        handleValueChange={onFilterValueChange}
                    />
                </StyledParentContainer>
            )}
        </FiltersGroupContainer>
    );
};

export default FilterGroup;
