import useListingPageUrlState from './useListingPageUrlState';
import { ContentInfusionsAllowedPageTypes } from 'Client/hooks/UrlManager/types';
import { PageType } from 'AppShell/appshell_types';
import { useBellaSelector } from 'Client/redux/hooks';
import useFeatureToggle from 'Client/hooks/useFeatureToggle';

export const useListingPageInfusions = () => {
    const { p100CISLPPosition } = useFeatureToggle();
    const [{ page, listingPageType, appliedFilters }] = useListingPageUrlState();
    const infusionsFromState = useBellaSelector(state => state.infusions.infusion);

    const pageNumberIsEvenAndGreaterThan5 = page > 5 && page % 2 === 0;

    // enabled for landing pages on pages with number 6, 8, 10, ...
    // and for unfiltered not landing on page 1
    const infusionEnabled =
        ContentInfusionsAllowedPageTypes.includes(listingPageType) &&
        appliedFilters.length === 0 &&
        (p100CISLPPosition && listingPageType === PageType.LANDING ? pageNumberIsEvenAndGreaterThan5 : page === 1);

    if (!infusionEnabled) {
        return { infusionEnabled };
    }

    const infusions =
        infusionsFromState && infusionsFromState.items && infusionsFromState.items.length >= 2
            ? infusionsFromState
            : undefined;

    return { infusionEnabled, infusions };
};
