import React, { useRef } from 'react';
import styled from 'styled-components';
import { LinkedBanners } from '../../../redux/seo/types';
import useUserTracking from '../../../hooks/flags/useUserTracking';
import Banner from '../../Generic/Banner';
import useGa from '../../../hooks/useGa';
import useSegmentFeatureTracking from 'Common/segment/useSegmentFeatureTracking';
import { SegmentFeatureCategory, SegmentFeatureInteractionType } from 'AppShell/appshell_types';

const StyledContainer = styled.div`
    padding: 20px 10px;
`;

interface Props {
    shopToClubFooterBanners: LinkedBanners[];
    isFullWidthBanner?: boolean;
}

const ShopToClubBanner: React.FunctionComponent<Props> = ({ shopToClubFooterBanners = [] }) => {
    const { isRegistered } = useUserTracking();
    const bannerRef = useRef<HTMLDivElement>(null);
    const gaTracker = useGa();
    const { trackFeatureImpression, trackFeatureInteractions } = useSegmentFeatureTracking();

    return !isRegistered ? (
        <StyledContainer ref={bannerRef}>
            {shopToClubFooterBanners.map(banner => (
                <Banner
                    key={banner.bannerLink}
                    targetUrl={banner.bannerLink}
                    mobileImage={banner.bannerImage}
                    desktopImage={banner.desktopBannerImage}
                    trackImpressionFunction={() => {
                        trackFeatureImpression({
                            feature_category: SegmentFeatureCategory.Banner,
                            feature_name: 'Shop2Club Registration Banner',
                            feature_label: '',
                        });
                        gaTracker.shopToClub.trackProductListingBannerImpression(banner.bannerLink);
                    }}
                    trackingFn={() => {
                        trackFeatureInteractions({
                            interaction_type: SegmentFeatureInteractionType.Click,
                            interaction_label: '',
                            feature_category: SegmentFeatureCategory.Banner,
                            feature_name: 'Shop2Club Registration Overlay',
                            feature_label: '',
                        });
                        gaTracker.shopToClub.trackProductListingBannerClick(banner.bannerLink);
                    }}
                />
            ))}
        </StyledContainer>
    ) : (
        <></>
    );
};

export default ShopToClubBanner;
