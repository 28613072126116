import React, { FC, ReactElement } from 'react';
import { Helmet } from 'react-helmet-async';
import { Dictionary } from 'Utils/types/utility';
import { ListingPageUrlState } from '../../../redux/products/types';
import { CategoryEntity } from '../../../redux/category/types';
import { SEO_CONSTANTS } from '../../ListingPage/services/seo/constant';
import useBreadcrumbsState from '../../ListingPage/hooks/useBreadcrumbsState';

interface Props {
    host: string;
    pageHeaderTitle: string;
    entities: Dictionary<CategoryEntity>;
    urlKeys: Dictionary<string>;
    urlState: ListingPageUrlState;
}

export interface SeoListItem {
    '@type': string;
    position: number;
    name: string;
    item: string;
}
const SeoBreadcrumbScript: FC<Props> = ({ entities, urlKeys, host, urlState, pageHeaderTitle }): ReactElement => {
    const { showSeoScript, breadcrumbList } = useBreadcrumbsState({ entities, urlKeys }, urlState, {
        pageHeaderTitle,
        host,
    });

    const breadcrumbsObject = {
        [SEO_CONSTANTS.structureDataContextKey]: SEO_CONSTANTS.structureDataContextValue,
        '@type': SEO_CONSTANTS.breadcrumbListType,
        itemListElement: breadcrumbList,
    };

    const scriptTag = {
        type: SEO_CONSTANTS.structureDataScriptType,
        innerHTML: JSON.stringify(breadcrumbsObject),
    };
    const scripts = showSeoScript ? [scriptTag] : [];
    return <Helmet script={scripts} />;
};

export default SeoBreadcrumbScript;
