import styled, { css } from 'styled-components';
import { forDesktop, forTablet, safari10MinMaxFallBack, forMidWideScreens } from '@westwing/ui-kit/breakPoints';
import { midWideScreenPadding, pagePadding } from '../Generic/BoxHelpers';

export const StyleGridWrapper = styled.div<{ $noPadding?: boolean }>`
    ${({ $noPadding }) =>
        $noPadding
            ? ''
            : css`
                  padding: 0 10px;
                  ${forTablet`
                padding-left: 0;
                padding-right: 0;
            `}
              `}
`;

export const ListingPageContentWrapper = styled.div`
    display: grid;
    grid-template:
        'breadcrumbs' min-content
        'main' auto
        'sidebar' auto
        'seotexttop' auto
        'seotextbot' auto
        / 100%;

    ${forDesktop`
        ${safari10MinMaxFallBack`
            grid-template:
                'breadcrumbs breadcrumbs' 38px
                'sidebar     main       ' auto
                'seotexttop  main       ' auto
                'blank       main       ' auto
                'blank seotextbot       ' auto
                / minmax(159px, 239px) minmax(710px, 1fr);
        `}
    `}
`;

export const ListingPageContainer = styled.div`
    ${pagePadding}
    ${midWideScreenPadding}
`;

export const SideBar = styled.aside`
    grid-area: sidebar;
    display: none;

    ${forDesktop`
        display: block;
    `}
`;

export const SeoTextTopWrapper = styled.section`
    color: ${({ theme }) => theme.colors.charcoal100};
    grid-area: seotexttop;
    padding: 0 10px;
    ${forDesktop`
        padding: 0;
        margin-top: 10px;
    `}
`;

export const SeoTextBottomWrapper = styled.section`
    color: ${({ theme }) => theme.colors.charcoal100};
    grid-area: seotextbot;
    padding: 0 10px;
`;

export const MainContent = styled.main`
    grid-area: main;

    ${forDesktop`
        margin-left: 30px;
    `}

    ${({ theme }) => css`
        ${forMidWideScreens`
            margin-left: ${theme.desktopWideScreensPadding};
        `}
    `}
`;

export const BreadCrumbsWrapper = styled.div`
    grid-area: breadcrumbs;
    display: block;
`;

export const StyledCategoryNavContainer = styled.nav`
    padding: 10px 30px;
    ${forDesktop`
        padding: 32px 0;
        position: sticky;
    `}
`;
