import React, { FunctionComponent, ReactElement } from 'react';
import styled from 'styled-components';

const StyledPageHeadingWrapper = styled.div`
    grid-area: title;
    position: relative;
`;

const PageTitle: FunctionComponent = ({ children }): ReactElement => (
    <StyledPageHeadingWrapper>{children}</StyledPageHeadingWrapper>
);

export default PageTitle;
