import React, { FC } from 'react';
import { isDesktop, forTablet } from '@westwing/ui-kit/breakPoints';
import styled from 'styled-components';
import { BellaTestIds } from 'Common/qaIds';
import useGa from 'Client/hooks/useGa';
import BubblesSlider from 'Client/components/Generic/BubblesSlider';
import { NavigationBaseData } from 'Client/components/Generic/NavigationItem';

interface CrossCategoryNavigationProps {
    categories: NavigationBaseData[];
    className?: string;
}

const StyledSliderWithCategoryBubbles = styled(BubblesSlider)`
    height: 42px;
    overflow-y: hidden;
    grid-area: x-cat;
    margin-top: 10px;

    ${forTablet`
        height: auto;
    `}
`;
const CrossCategoryNavigation: FC<CrossCategoryNavigationProps> = ({ categories, className }) => {
    const gaTracking = useGa();

    if (categories.length < 1) {
        return <></>;
    }

    const handleCrossCategoryBubblesTracking = (label: string, isSelected: boolean) => {
        const action = isSelected
            ? gaTracking.actions.Users.CrossCatNavDeselect
            : gaTracking.actions.Users.CrossCatNavClick;
        gaTracking.trackUsers({
            action,
            label,
        });
        if (isDesktop()) {
            gaTracking.trackPLP({ action: 'Clicks on the category bubbles on PLP', label });
        }
    };

    return (
        <StyledSliderWithCategoryBubbles
            bubbles={categories}
            onItemClick={handleCrossCategoryBubblesTracking}
            testId={BellaTestIds.crossCategoryNavigation}
            className={className}
            height="40px"
        />
    );
};

export default React.memo(CrossCategoryNavigation);
