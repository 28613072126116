import styled from 'styled-components';
import React, { FC, ReactElement } from 'react';
import useTranslator from 'Client/hooks/useTranslator';

interface ClearAllFiltersButtonProps {
    handleClearButtonClick: () => void;
}

const StyledClearFiltersButton = styled.button`
    padding: 8px 2px;
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.charcoal100};
    cursor: pointer;
`;

const ClearAllFiltersButton: FC<ClearAllFiltersButtonProps> = ({ handleClearButtonClick }): ReactElement => {
    const t = useTranslator();
    return (
        <StyledClearFiltersButton type="button" onClick={handleClearButtonClick}>
            {t('Reset filter')}
        </StyledClearFiltersButton>
    );
};

export default ClearAllFiltersButton;
