import { SeoListItem } from '../Generic/SeoHead/SeoBreadcrumbScript';
import { NormalizedCategoriesData } from '../../redux/category/types';
import { getFirstLevelParent } from './SideCategoryNavigation/SideNavigation';

const getCategoryPageBackOptions = (
    seoBreadcrumbs: SeoListItem[],
    urlKey: string,
    categories: NormalizedCategoriesData | null
) => {
    if (!urlKey || !categories) {
        return ['', true] as [string, boolean];
    }

    const { entities, urlKeys } = categories;
    const categoryId = urlKeys[urlKey];
    const category = entities[categoryId];

    if (!category || getFirstLevelParent(category, entities).isHidden) {
        return ['', true] as [string, boolean];
    }

    const backItemIndex = seoBreadcrumbs.length - 2;
    const backLink = seoBreadcrumbs[backItemIndex] ? seoBreadcrumbs[backItemIndex].item : '';
    const isRoot = seoBreadcrumbs.length <= 2 || !backLink;

    return [backLink, isRoot] as [string, boolean];
};

export default getCategoryPageBackOptions;
