import React from 'react';
import styled from 'styled-components';
import Breadcrumbs, { BreadcrumbItem } from '@westwing/ui-kit/Breadcrumbs';
import { forTablet } from '@westwing/ui-kit/breakPoints';
import BellaHybridLink from '../../Generic/Links/BellaHybridLink';
import { SeoListItem } from '../../Generic/SeoHead/SeoBreadcrumbScript';

const StyledBreadcrumbs = styled(Breadcrumbs)`
    padding-bottom: 0;

    ${forTablet`
        padding-bottom: 9px;
    `}
`;

interface BreadCrumbsProps {
    breadcrumbList: SeoListItem[];
}

const mapToBreadcrumbItems = (breadcrumbList: SeoListItem[]) =>
    breadcrumbList.map(({ name, item }, index) => ({
        tag: BellaHybridLink,
        name,
        ...(breadcrumbList.length !== index + 1 && { props: { to: item || '/' } }),
    }));

const BreadCrumbs: React.FC<BreadCrumbsProps> = ({ breadcrumbList }) => {
    const breadcrumbs: BreadcrumbItem[] = mapToBreadcrumbItems(breadcrumbList);

    return <StyledBreadcrumbs items={breadcrumbs} />;
};

export default BreadCrumbs;
