import React, { FC } from 'react';
import styled from 'styled-components';
import { useCheckbox } from '@westwing/ui-kit/Checkbox';
import CheckBox from '../Input/CheckBox';
import { FilterValue } from './filterValueService';
import { ListFiltersLayout, CountLabel } from './CommonFilterElements';
import { NewFilterValues } from 'Client/components/Generic/FilterAndSortBox/types';
import { BellaTestIds } from 'Common/qaIds';

export interface StandardFilterGroupProps {
    filterOptions: { [name: string]: number };
    valueKeys?: { [name: string]: string };
    value: FilterValue;
    displayName: string;
    name: string;
    handleValueChange: (value: NewFilterValues) => void;
}

export interface StandardFilterGroupItemProps {
    filterOptions: StandardFilterGroupProps['filterOptions'];
    item: string;
    isChecked: boolean;
    valueOnChecked: string;
    handleCheckBoxValueChange: (isChecked: boolean, value: string) => void;
    index: number;
}

export const StyledFilterItemWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    min-height: fit-content;
    margin-bottom: 15px;
`;

const StandardFilterGroupItem = ({
    filterOptions,
    item,
    isChecked,
    valueOnChecked,
    handleCheckBoxValueChange,
    index,
}: StandardFilterGroupItemProps) => {
    const useCheckboxProps = useCheckbox({
        isChecked,
        valueOnChecked,
        onChangeCallback: handleCheckBoxValueChange,
    });
    const id = `checkbox-${item}`;

    return (
        <StyledFilterItemWrapper key={item}>
            <CheckBox
                {...useCheckboxProps}
                id={id}
                data-testid={id}
                wrapperDataTestId={`${BellaTestIds.plpStandardFilterGroupItem}${index}`}
                label={item}
            />
            <CountLabel count={filterOptions[item]} />
        </StyledFilterItemWrapper>
    );
};

const StandardFilterGroup: FC<StandardFilterGroupProps> = ({
    filterOptions,
    value,
    displayName,
    name,
    valueKeys,
    handleValueChange,
}) => {
    const currentValue = Array.isArray(value) ? value : [];

    const getValue = (valueKey: string) => {
        const value = valueKeys?.[valueKey];
        return value || valueKey;
    };
    const handleCheckBoxValueChange = (isChecked: boolean, newValue: string) => {
        const index = currentValue.map(current => current.toLocaleLowerCase()).indexOf(newValue.toLocaleLowerCase());
        if (index > -1) {
            currentValue.splice(index, 1);
        } else {
            currentValue.push(newValue);
        }
        handleValueChange({
            name,
            value: currentValue,
            displayName,
            lastChangedOption: { value: isChecked, name: newValue.toLocaleLowerCase() },
        });
    };
    return (
        <ListFiltersLayout>
            {Object.keys(filterOptions).map((item, index) => {
                const valueOnChecked = getValue(item);
                const isChecked = currentValue.some(
                    current => current.toLocaleLowerCase() === getValue(item).toLocaleLowerCase()
                );

                return (
                    <StandardFilterGroupItem
                        key={item}
                        valueOnChecked={valueOnChecked}
                        isChecked={isChecked}
                        filterOptions={filterOptions}
                        item={item}
                        handleCheckBoxValueChange={handleCheckBoxValueChange}
                        index={index}
                    />
                );
            })}
        </ListFiltersLayout>
    );
};

export default StandardFilterGroup;
