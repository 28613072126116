import React, { FC } from 'react';
import RadioButton from '@westwing/ui-kit/RadioButton';
import { FilterValue } from './filterValueService';
import { NewFilterValues } from 'Client/components/Generic/FilterAndSortBox/types';

interface Props {
    name: string;
    label: string;
    radio: string;
    value: FilterValue;
    displayName: string;
    handleValueChange: (value: NewFilterValues) => void;
}

export const MultiRangeFilters: FC<Props> = ({
    radio,
    label,
    name,
    handleValueChange,
    value,
    displayName,
}): React.ReactElement => {
    let activeValue = '';
    if (value !== undefined && Array.isArray(value)) {
        activeValue = value[0] || '';
    }

    const isChecked = activeValue.toLocaleLowerCase() === radio.toLocaleLowerCase();

    return (
        <RadioButton
            isChecked={isChecked}
            text={label}
            value={radio}
            onChange={() =>
                handleValueChange({
                    name,
                    value: radio,
                    displayName,
                    lastChangedOption: { name: activeValue, value: true },
                })
            }
        />
    );
};

export default MultiRangeFilters;
