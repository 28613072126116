import { button } from '@westwing/ui-kit/typography';
import React from 'react';
import styled from 'styled-components';
import { BellaTestIds } from 'Common/qaIds';

interface CountLabelProps {
    count: number;
    className?: string;
}

const CountLabelSpan = styled.span`
    ${button}
    text-align: right;
`;

export const CountLabel: React.FunctionComponent<CountLabelProps> = ({ count }) => (
    <CountLabelSpan data-testid={BellaTestIds.filterCount}>({count})</CountLabelSpan>
);

export const ListFiltersLayout = styled.div`
    display: flex;
    flex-flow: column;
    color: ${({ theme }) => theme.colors.charcoal100};
    min-height: fit-content;
    margin-bottom: -15px;
`;
