import React, { FC } from 'react';
import styled from 'styled-components';
import loadable from '@loadable/component';
import { forDesktop } from '@westwing/ui-kit/breakPoints';
import MultiRangeFilters from './MultiRangeFilters';
import StandardFilters from './StandardFilters';
import { FilterValue } from './filterValueService';
import CategoryFilter from './CategoryFilters';
import { pathFilterNames } from 'Client/redux/products/types';
import SkipRender from 'Client/components/Generic/SkipRender';
import { Filter, FilterTypes, NewFilterValues } from 'Client/components/Generic/FilterAndSortBox/types';

const RangeFilters = loadable(() => import('./RangeFilters'));

export const StyledMenuItem = styled.div`
    display: grid;
    grid-template-columns: auto 1fr 10px;
    grid-gap: 4px;
`;

const StyledFilterContainer = styled.div`
    padding: 14px 0;
    display: flex;

    ${forDesktop`
        padding: 7px 0;
    `}
`;

interface FilterContainerProps {
    group: Filter;
    filterValue: FilterValue;
    handleValueChange: (value: NewFilterValues) => void;
}

export const UnknownFilter: FC<{ filter: Filter }> = React.memo(({ filter }) => (
    <SkipRender message={`Unknown filter type "${filter.type}" was tried to be rendered`} data={{ group: filter }} />
));

const getValue = (valueKey: string, valueKeys: object | undefined) => {
    const value = valueKeys?.[valueKey];
    return value || valueKey;
};

const FilterContainer: FC<FilterContainerProps> = ({ group, handleValueChange, filterValue }): React.ReactElement => {
    const { displayName, name, type, values, valueKeys } = group;

    if (name === pathFilterNames.categories) {
        return <CategoryFilter filterValue={filterValue} values={values} handleValueChange={handleValueChange} />;
    }

    switch (type) {
        case FilterTypes.range:
            return (
                <RangeFilters value={filterValue} type={name} values={values} handleValueChange={handleValueChange} />
            );
        case FilterTypes.standard:
            return (
                <StandardFilters
                    value={filterValue}
                    filterOptions={values}
                    valueKeys={valueKeys}
                    displayName={displayName}
                    name={name}
                    handleValueChange={handleValueChange}
                />
            );
        case FilterTypes.multiRange:
            return (
                <>
                    {Object.keys(values).map(valueKey => (
                        <StyledFilterContainer key={valueKey}>
                            <MultiRangeFilters
                                value={filterValue}
                                displayName={displayName}
                                name={name}
                                label={valueKey}
                                radio={getValue(valueKey, valueKeys)}
                                handleValueChange={handleValueChange}
                            />
                        </StyledFilterContainer>
                    ))}
                </>
            );
        default:
            return <UnknownFilter filter={group} />;
    }
};

export default FilterContainer;
