import { capitalize } from '../common/formatting/formattingService';
import { isBrandsFilter, BrandsFilters, pathFilterNames } from './redux/products/types';
import { CategoriesState } from './redux/category/types';
import { FilterFacetsNames } from './hooks/UrlManager/types';
import { getEntityByUrlKey } from './redux/category/categoriesNormalizer';
import { AppliedFilter, Filter } from './components/Generic/FilterAndSortBox/types';

const convertBrandValue = (hydratedBrands: BrandsFilters | undefined, urlKey: string) => {
    if (!hydratedBrands) {
        return urlKey;
    }

    const hydratedBrand = Object.values(hydratedBrands.hydratedValues).find(hydrated => hydrated.url_key === urlKey);
    return hydratedBrand ? hydratedBrand.name : urlKey;
};

const convertCategoryValue = (categories: CategoriesState, urlKey: string) => {
    const category = getEntityByUrlKey(categories.normalized, urlKey);
    if (!category) {
        return urlKey;
    }
    return category.name;
};

const convertPriceValue = (currency: string, value: string): string => `${value} ${currency}`;

interface DisplayValueData {
    categories: CategoriesState;
    filters: Filter[];
    currency: string;
}

export const getSingleDisplayValue = (
    value: string,
    filterName: string,
    { categories, filters, currency }: DisplayValueData
) => {
    if (!value) {
        return '';
    }

    switch (filterName) {
        case pathFilterNames.brands: {
            const hydratedBrands = (filters || []).find(isBrandsFilter);
            return convertBrandValue(hydratedBrands, value);
        }
        case pathFilterNames.categories:
            return convertCategoryValue(categories, value);
        case FilterFacetsNames.PRICE:
            return convertPriceValue(currency, value);
        default:
            return capitalize(value);
    }
};

export const getCombinedDisplayValue = (filter: AppliedFilter | undefined, displayValueData: DisplayValueData) => {
    if (!filter) {
        return '';
    }

    return filter.values.map(value => getSingleDisplayValue(value, filter.name, displayValueData)).join(', ');
};

export default { getDisplayValue: getCombinedDisplayValue };
