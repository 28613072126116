import React, { useReducer } from 'react';
import { LazyImageStateType, LazyImageActionType } from './types';
import lazyImagesReducer from './reducer';

const useLazyImageReducer = (
    initialState: LazyImageStateType
): [LazyImageStateType, React.Dispatch<LazyImageActionType>] => {
    const [state, dispatch] = useReducer(lazyImagesReducer, initialState);

    return [state, dispatch];
};

export default useLazyImageReducer;
