import React, { FC, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { body2, header2 } from '@westwing/ui-kit/typography';
import { forDesktop, forSemiWideScreens, forTablet } from '@westwing/ui-kit/breakPoints';
import useListingPageUrlState from '../hooks/useListingPageUrlState';
import { HorizontalGrid } from '../ProductGrid';
import { getDefaultSeoData } from '../services/seo';
import useListingPageTitle from '../hooks/useListingPageTitle';
import ShopToClubBanner from '../Banners/ShopToClubBanner';
import ListingPageSeoHead from '../Seo/SeoHead';
import HorizontalScrollableElement from 'Client/components/ScrollableElement';
import Separator from 'Client/components/Generic/LineSeparator';
import { LinkedBanners } from 'Client/redux/seo/types';
import StateInterface, { BellaThunkDispatch } from 'Client/redux/types';
import { PriceFormatConfigOptions, ProductInterface, SecondaryProductsIdentifier } from 'Client/redux/products/types';
import { fetchSecondaryProductsDataIfNeeded } from 'Client/redux/products/actions';
import useTranslator from 'Client/hooks/useTranslator';
import { getNewProductsUrl } from 'Client/urlService';
import { PageType } from 'AppShell/appshell_types/routingTypes';
import useConfig from 'AppShell/hooks/useConfig';
import { ProductList } from 'Common/ga/types';
import { SHOP_TO_CLUB_FOOTER_LINK } from 'Client/redux/cms/constants';
import { OverlayActionTypes, showOverlay as showOverlayAction } from 'Client/redux/header/overlays/actions';
import { isHeroProduct } from 'Client/components/Generic/ProductsGrid/heroProducts';

export const LIST_ITEMS_LIMIT = 15;

export interface ReduxActions {
    showOverlay: (id: string) => OverlayActionTypes;
    dispatchFetchProducts: (
        identifier: SecondaryProductsIdentifier,
        currencyFormatReducerOptions: PriceFormatConfigOptions
    ) => void;
}

export interface ReduxStateProps {
    newProducts: ProductInterface[];
    allProducts: ProductInterface[];
    isNewProductsFetching: boolean;
    shopToClubFooterBanners: LinkedBanners[];
}

const LineSeparator = styled(Separator)`
    width: calc(100% - 20px);
    margin: 0 0 0 20px;

    ${forDesktop`
        width: calc(100% - 40px);
        margin: 0 auto;
    `}
`;

const StyledTextContainer = styled.div`
    padding: 3.5em 3.87em;
    ${forTablet`
        padding-left: 0;
    `}
    ${forDesktop`
        padding: 40px 20px;
    `}
`;

const StyledText = styled.div`
    ${body2}
    line-height: 1.95em;
    color: ${({ theme }) => theme.colors.charcoal100};
    text-align: center;
    margin: 0;
    ${forTablet`
        text-align: left;
    `}
`;

const TitleText = styled(StyledText)`
    ${forTablet`
        ${header2}
        display: block;
        text-align: left;
        margin-bottom: 10px;
    `}
`;

const ContentWrapper = styled.div`
    ${forTablet`
        margin-left: 20px;
    `}
    ${forSemiWideScreens`
        margin-left: 20px;
    `}
`;

export type NoResultsProps = ReduxActions & ReduxStateProps;

const PaddedWrapper = styled.div`
    padding: 0 10px;
    ${forDesktop`
        padding-left: 0;
    `}
`;

const NoResults: FC<NoResultsProps> = ({
    newProducts,
    allProducts,
    isNewProductsFetching,
    dispatchFetchProducts,
    shopToClubFooterBanners,
}) => {
    const t = useTranslator();
    const config = useConfig();
    const [urlState] = useListingPageUrlState();
    const searchTerm = urlState.search || '';
    const seoData = getDefaultSeoData(PageType.ALLPRODUCTS, t, config);

    const [pageHeaderTitle, pageMetaTitle] = useListingPageTitle(urlState, seoData);
    const newProductsToShow = newProducts.filter((_, index) => !isHeroProduct(index)).slice(0, LIST_ITEMS_LIMIT);

    const randomizeProducts = (products: ProductInterface[]) =>
        products
            .filter((_, index) => !isHeroProduct(index))
            .sort(() => 0.5 - Math.random())
            .slice(0, LIST_ITEMS_LIMIT);
    const bestSellers = useMemo(() => randomizeProducts(allProducts), [allProducts]);

    const { currency, countryCode } = config;
    useEffect(() => {
        const { ALL_PRODUCTS, NEW_PRODUCTS } = SecondaryProductsIdentifier;

        dispatchFetchProducts(NEW_PRODUCTS, { countryCode, currency });
        dispatchFetchProducts(ALL_PRODUCTS, { countryCode, currency });
    }, [searchTerm, dispatchFetchProducts, currency, countryCode]);

    const mainText = t('0 result for "%s". Do not give up yet!', [searchTerm]);
    const allProductsLinkText = t('See all %s new products', [newProducts.length]);

    return (
        <ContentWrapper>
            <ListingPageSeoHead
                pageTitle={pageMetaTitle}
                pageHeaderTitle={pageHeaderTitle}
                urlState={urlState}
                seoData={seoData}
                config={config}
                totalCount={0}
                entities={{}}
                urlKeys={{}}
            />
            <StyledTextContainer>
                <TitleText>{mainText}</TitleText>
                <StyledText>{t('Check the spelling')}</StyledText>
                <StyledText>{t('Try more general words')}</StyledText>
            </StyledTextContainer>
            <LineSeparator />
            <div>
                <HorizontalScrollableElement
                    title={t('Our novelties')}
                    showNavArrows
                    showHeader
                    ctaOptions={{
                        loading: isNewProductsFetching,
                        text: allProductsLinkText,
                        link: getNewProductsUrl(),
                    }}
                >
                    <HorizontalGrid products={newProductsToShow} list={ProductList.noResults} />
                </HorizontalScrollableElement>
                <LineSeparator />
                <HorizontalScrollableElement title={t('Our bestseller for you')} showNavArrows showHeader>
                    <HorizontalGrid products={bestSellers} />
                </HorizontalScrollableElement>
                <PaddedWrapper>
                    <ShopToClubBanner shopToClubFooterBanners={shopToClubFooterBanners} />
                </PaddedWrapper>
            </div>
        </ContentWrapper>
    );
};

const mapStateToProps = (state: StateInterface): ReduxStateProps => {
    const { products } = state.secondaryProducts;
    const { newProducts, allProducts } = products;

    return {
        newProducts: newProducts.products,
        allProducts: allProducts.products,
        isNewProductsFetching: newProducts.isFetching,
        shopToClubFooterBanners: state.cms.linkedBanners.data[SHOP_TO_CLUB_FOOTER_LINK],
    };
};

const mapDispatchToProps = (dispatch: BellaThunkDispatch): ReduxActions => ({
    showOverlay: (id: string) => dispatch(showOverlayAction(id)),
    dispatchFetchProducts: (
        identifier: SecondaryProductsIdentifier,
        currencyFormatReducerOptions: PriceFormatConfigOptions
    ) => dispatch(fetchSecondaryProductsDataIfNeeded(identifier, currencyFormatReducerOptions)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NoResults);
