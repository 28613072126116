import { useCallback } from 'react';
import { useSegmentTracking } from './useSegment';

export type ProductListViewedProduct = {
    product_id: string;
    sku: string;
    position: number;
};

export type ProductListViewedFilter = {
    type: string;
    value: string | string[];
};

export type ProductListViewedData = {
    listId?: string;
    listLabel?: string;
    filters?: ProductListViewedFilter[];
    sorts?: ProductListViewedFilter[];
    products: ProductListViewedProduct[];
    pageLabel?: string;
    pageType?: string;
};

export function useSegmentProductListViewedTracking(baseListId?: string) {
    const segment = useSegmentTracking();

    return useCallback(
        (data: ProductListViewedData) => {
            const trackingData = {
                list_id: data.listId || baseListId,
                list_label: data.listLabel,
                filters: data.filters,
                sorts: data.sorts,
                products: data.products,
                page_label: data.pageLabel,
                page_type: data.pageType,
            };

            segment.track('Product List Viewed', trackingData);
        },
        [segment, baseListId]
    );
}
