import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import useBellaTranslator from 'Client/hooks/useTranslator';
import { ColumnLayoutVariants } from 'Client/redux/products/types';
import { useBellaSelector } from 'Client/redux/hooks';
import { setPLPGridColumnView } from 'Client/redux/products/actions';
import useGa from 'Client/hooks/useGa';
import useRouter from 'AppShell/hooks/useRouter';

interface ColumnCountProps {
    value: number;
    isSelected: boolean;
    onClick: () => void;
}

const Separator = styled.div`
    margin-left: 8px;
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 3px;
`;

const ColumnCountWrapper = styled.button<{ isSelected: boolean }>`
    margin-left: 8px;
    padding-bottom: 1px;
    text-decoration: ${({ isSelected }) => (isSelected ? 'underline' : 'none')};
    font-weight: ${({ isSelected }) => (isSelected ? 'bold' : 'normal')};
    text-underline-offset: 1px;
    cursor: pointer;
    & {
        color: ${({ theme }) => theme.colors.charcoal100};
    }
`;

const ColumnSelectionWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const ColumnCount = ({ value, isSelected, onClick }: ColumnCountProps) => (
    <ColumnCountWrapper onClick={onClick} isSelected={isSelected} aria-label={`${value} column grid`}>
        {value}
    </ColumnCountWrapper>
);

const ColumnSelection = () => {
    const t = useBellaTranslator();
    const dispatch = useDispatch();
    const gaTracking = useGa();
    const {
        location: { pathname },
    } = useRouter();
    const plpGridColumnView = useBellaSelector(state => state.products.plpGridColumnView);

    const handleColumnClick = (value: ColumnLayoutVariants) => {
        dispatch(setPLPGridColumnView(value));
        gaTracking.trackPLP({ action: `View in ${value} columns - click`, label: pathname });
    };

    const getProps = (value: ColumnLayoutVariants) => ({
        onClick: () => handleColumnClick(value),
        isSelected: plpGridColumnView === value,
        value,
    });

    return (
        <ColumnSelectionWrapper>
            <div>{t('View')}</div>
            <ColumnCount {...getProps(ColumnLayoutVariants.THREE)} />
            <Separator> | </Separator>
            <ColumnCount {...getProps(ColumnLayoutVariants.FOUR)} />
        </ColumnSelectionWrapper>
    );
};

export default ColumnSelection;
