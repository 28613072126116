import React, { FC, ReactElement } from 'react';
import UiKitBubble, { bubbleHeightDefault, BubbleType } from '@westwing/ui-kit/Bubble';
import BellaHybridLink from './Links/BellaHybridLink';

export interface NavigationBaseData {
    name: string;
    link: string;
    isHidden?: boolean;
    isSelected: boolean;
}

export interface NavigationItemProps {
    categoryName: NavigationBaseData['name'];
    to: NavigationBaseData['link'];
    isSelected: NavigationBaseData['isSelected'];
    onClick: (label: string, isSelected: boolean) => void;
    innerRef?: (node: HTMLAnchorElement | null) => void;
    height?: '35px' | '40px';
}

const NavigationItem: FC<NavigationItemProps> = ({
    to,
    onClick,
    isSelected,
    categoryName,
    innerRef,
    height = bubbleHeightDefault,
}): ReactElement => {
    const handleClick = () => onClick(categoryName, isSelected);

    return (
        <UiKitBubble
            text={categoryName}
            as={BellaHybridLink}
            bubbleType={BubbleType.GREY}
            selected={isSelected}
            innerRef={innerRef}
            to={to}
            onClick={handleClick}
            height={height}
        />
    );
};

export type NavigationItemType = typeof NavigationItem;

export default NavigationItem;
