import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import FilterAndSortBox from '../Generic/FilterAndSortBox/FilterAndSortBox';
import { Filter, NewFilterValues, Sorting, SortingParameters } from '../Generic/FilterAndSortBox/types';
import useListingPageUrlState from './hooks/useListingPageUrlState';
import useTrackingPageLabel from './hooks/useTrackingPageLabel';
import useTranslator from 'Client/hooks/useTranslator';
import {
    getFiltersForPageType,
    updateFilterValue,
} from 'Client/components/Generic/GenericFiltersFlyout/filterValueService';
import { pathFilterNames } from 'Client/redux/products/types';
import StateInterface from 'Client/redux/types';
import useGa from 'Client/hooks/useGa';
import { Actions } from 'Common/ga/types';

enum SORT_KEYS {
    BOOST_SORT_KEY = 'score_product_boost-desc',
    NOVELTY_SORT_KEY = 'score_novelty-desc',
    PRICE_DESC_SORT_KEY = 'facet_price-desc',
    PRICE_ASC_SORT_KEY = 'facet_price-asc',
}

const sortNameToGALabelMap = {
    [SORT_KEYS.BOOST_SORT_KEY]: Actions.Filters.SortingActivatedFav,
    [SORT_KEYS.NOVELTY_SORT_KEY]: Actions.Filters.SortingActivatedNew,
    [SORT_KEYS.PRICE_DESC_SORT_KEY]: Actions.Filters.SortingActivatedPriceDesc,
    [SORT_KEYS.PRICE_ASC_SORT_KEY]: Actions.Filters.SortingActivatedPriceAsc,
};

export interface FilterAndSortBoxProps {
    handleFiltersClick: (event: React.MouseEvent<HTMLDivElement>) => void;
    totalCount?: number;
}

const ConnectedFilterAndSortBox = ({ handleFiltersClick, totalCount }: FilterAndSortBoxProps): ReactElement => {
    const [urlState, setListingPageState] = useListingPageUrlState();
    const pageType = useTrackingPageLabel();
    const t = useTranslator();
    const gaTracking = useGa();

    const filters = useSelector<StateInterface, Filter[]>(state => state.products.filters);
    const sorting = useSelector<StateInterface, Sorting[]>(state => state.products.sorting);

    const allowedFilters = getFiltersForPageType(filters, urlState.listingPageType).filter(
        filter => filter.name !== pathFilterNames.categories
    );

    const sendSortValueChange = (newSortingParams: SortingParameters, labelKey: string) => {
        setListingPageState({ sortingParams: newSortingParams }, true);
        gaTracking.trackFilters({
            action: sortNameToGALabelMap[labelKey],
            label: pageType,
        });
    };

    const onFilterValueChange = (newValue: NewFilterValues): void => {
        if (newValue.lastChangedOption?.value) {
            gaTracking.trackFilters({
                action: gaTracking.actions.Filters.FilterActivated,
                label: `/${urlState.listingPageKey}/;${newValue.displayName} - ${newValue.lastChangedOption.name}`,
            });
        }

        const newAppliedFilters = updateFilterValue(newValue, urlState.appliedFilters);
        setListingPageState({ appliedFilters: newAppliedFilters }, true);
    };

    const onHandleFiltersClick = (event: React.MouseEvent<HTMLDivElement>) => {
        gaTracking.trackFilters({
            action: gaTracking.actions.Filters.MobileFilterClick,
            label: `/${urlState.listingPageKey}/`,
        });
        handleFiltersClick(event);
    };

    const handleOnSortClick = () =>
        gaTracking.trackFilters({
            action: gaTracking.actions.Filters.SortingClick,
            label: pageType,
        });

    const handleExpandableFilterClick = (filter: Filter) => {
        gaTracking.trackFilters({
            action: gaTracking.actions.Filters.FilterClick,
            label: `/${urlState.listingPageKey}/;${filter.displayName}`,
        });
    };

    const handleShowAll = () => {
        gaTracking.trackFilters({
            action: gaTracking.actions.Filters.FilterClick,
            label: `/${urlState.listingPageKey}/;${t('More_filters')} +`,
        });
    };

    return (
        <FilterAndSortBox
            sortingValue={urlState.sortingParams}
            sortingOptions={sorting}
            handleOnSortClick={handleOnSortClick}
            onSortValueChange={sendSortValueChange}
            handleFiltersClick={onHandleFiltersClick}
            filters={allowedFilters}
            translator={t}
            appliedFilters={urlState.appliedFilters}
            onFilterValueChange={onFilterValueChange}
            handleExpandableFilterClick={handleExpandableFilterClick}
            handleShowAll={handleShowAll}
            totalCount={totalCount}
        />
    );
};

export default ConnectedFilterAndSortBox;
