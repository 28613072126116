import React, { FC, ReactElement } from 'react';
import styled, { css } from 'styled-components';
import { button } from '@westwing/ui-kit/typography';
import { forDesktop } from '@westwing/ui-kit/breakPoints';
import { FilterType } from '../../../redux/products/types';
import StyledCloseButton from '../Buttons/StyledCloseButton';
import useP100FilterRedesign from 'Client/components/Generic/FilterAndSortBox/useP100FilterRedesign';

const StyledNavLink = styled.button<{ colored?: boolean; filterType?: FilterType }>`
    height: 32px;
    border-radius: 3px;
    border: ${({ theme }) => `1px solid ${theme.colors.charcoal100}`};
    color: ${({ theme }) => theme.colors.charcoal100};
    position: relative;
    margin-right: 7px;
    padding-right: 8px;
    padding-left: 10px;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    text-transform: ${({ filterType }) => (filterType === FilterType.sizes ? 'none' : 'capitalize')};
    cursor: pointer;

    ${({ theme, colored }) =>
        colored &&
        css`
            ${forDesktop`
                border: none;
                background-color: ${theme.colors.charcoal3};
            `}
        `}
`;

const StyledLabel = styled.div`
    ${button}
    margin: 0 auto;
`;

interface AppliedFilterItemProps {
    name: string;
    type?: FilterType;
    removeFilterFunc: () => void;
}

const AppliedFilterItem: FC<AppliedFilterItemProps> = ({ name, type, removeFilterFunc }): ReactElement => {
    const { isBoxed: colored } = useP100FilterRedesign();

    return (
        <StyledNavLink
            type="button"
            colored={colored}
            filterType={type}
            onClick={removeFilterFunc}
            data-testid="applied-filter-item"
        >
            <StyledCloseButton />
            <StyledLabel>{name}</StyledLabel>
        </StyledNavLink>
    );
};
export default AppliedFilterItem;
