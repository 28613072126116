import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { forTablet } from '@westwing/ui-kit/breakPoints';
import { body2, caption1, header2, header6 } from '@westwing/ui-kit/typography';
import StateInterface from '../../../redux/types';
import useTranslator from '../../../hooks/useTranslator';
import { BellaTestIds } from 'Common/qaIds';
import { ReducedDidYouMeanInterface } from 'Client/redux/products/types';
import useFeatureToggle from 'Client/hooks/useFeatureToggle';

const PRODUCT_COUNT_HEIGHT = 17;
interface Props {
    pageTitle: string;
    titleTextDescription?: string;
    totalCount: number;
    didYouMean?: ReducedDidYouMeanInterface[];
    isSearch?: boolean;
    isRoot: boolean;
}

const StyledPageTitle = styled.h1<{ isSearch: boolean }>`
    max-width: 80%;
    margin: 0 0 0 28px;
    min-height: 26px;
    ${header6}
    ${forTablet`
        margin: 0;
        ${header2}
        min-height: 34px;
    `}
`;

const RegularTitleContainer = styled.div<{ isSearch: boolean; isRoot: boolean }>`
    margin: ${({ isRoot }) => (isRoot ? '0' : '0 50px 0 0')};
    text-align: left;
    ${forTablet`
        padding-left: 0;
        padding-bottom: 0;
    `}
`;

const StyledProductCount = styled.div`
    display: none;
    ${caption1}
    margin-top: 4px;
    ${forTablet`
        display:block;
        margin-top: 8px;
    `}
    vertical-align: bottom;
    min-height: ${PRODUCT_COUNT_HEIGHT}px;
`;

const StyledBrandText = styled.div`
    ${body2}
    display: none;
    ${forTablet`
        display: block;
        margin: 10px 0;
        max-width: 75%;
        color: ${({ theme }) => theme.colors.charcoal100};
    `}
`;

export const RegularTitle = ({
    pageTitle,
    totalCount,
    didYouMean = [],
    isSearch = false,
    titleTextDescription,
    isRoot,
}: Props): ReactElement | null => {
    const t = useTranslator();
    const { p100FreistellerImages } = useFeatureToggle();
    const totalCountText = totalCount < 0 ? ' ' : `${totalCount} ${t('Products')}`;

    if (didYouMean.length !== 0) {
        return null;
    }
    return (
        <RegularTitleContainer isSearch={false} isRoot={isRoot}>
            <StyledPageTitle isSearch={isSearch}>{pageTitle}</StyledPageTitle>
            {titleTextDescription && <StyledBrandText>{titleTextDescription}</StyledBrandText>}
            {!p100FreistellerImages && (
                <StyledProductCount data-testid={BellaTestIds.plpFilterProductsCountDesktop}>
                    {totalCountText}
                </StyledProductCount>
            )}
        </RegularTitleContainer>
    );
};
const mapStateToProps = (state: StateInterface): { didYouMean: ReducedDidYouMeanInterface[] } => {
    const { didYouMean } = state.products;
    return { didYouMean };
};

export default compose(connect(mapStateToProps))(RegularTitle);
