import React, { FunctionComponent, ReactElement } from 'react';
import styled, { css } from 'styled-components';
import { forDesktop, forTablet } from '@westwing/ui-kit/breakPoints';
import { StyledArrowIcon } from '@westwing/ui-kit/Icons/StyledIcons';
import BellaHybridLink from '../Generic/Links/BellaHybridLink';

const StyledLink = styled(BellaHybridLink)`
    display: block;
    width: 100%;
    height: 100%;
    line-height: 42px;
    ${forTablet`
        line-height: 38px;
    `}
`;

const StyledWrapper = styled.div<{ $categoryBubblesDisabledOnDesktop?: boolean; $hideOnDesktop?: boolean }>`
    grid-area: back;
    margin-left: 18px;
    display: block;
    width: 42px;
    height: 42px;
    margin-top: 9px;
    text-align: center;
    margin-right: -19px;
    background: linear-gradient(90deg, ${({ theme }) => theme.colors.white} 63.21%, rgba(255, 255, 255, 0) 103.12%);
    mix-blend-mode: normal;
    z-index: 2;

    ${forTablet`
        margin: 10px 0 0;
        text-align: left;
        width: 30px;
        height: 42px;
        padding: 0 5px;
        background: transparent;
    `}
    ${forDesktop`
        margin: 10px 0 0;
    `}

    ${({ $hideOnDesktop, $categoryBubblesDisabledOnDesktop }) =>
        ($hideOnDesktop || $categoryBubblesDisabledOnDesktop) &&
        css`
            ${forDesktop`
                display: none;
            `}
        `}
`;

export interface GoBackLinkProps {
    to: string;
    isRoot: boolean;
    categoryBubblesDisabledOnDesktop?: boolean;
    hideOnDesktop?: boolean;
}

const BackButton: FunctionComponent<GoBackLinkProps> = ({
    to,
    isRoot,
    categoryBubblesDisabledOnDesktop,
    hideOnDesktop,
}): ReactElement | null =>
    isRoot ? null : (
        <StyledWrapper
            $categoryBubblesDisabledOnDesktop={categoryBubblesDisabledOnDesktop}
            $hideOnDesktop={hideOnDesktop}
        >
            <StyledLink to={to}>
                <StyledArrowIcon size={9} />
            </StyledLink>
        </StyledWrapper>
    );
export default BackButton;
