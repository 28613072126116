export enum FilterTypes {
    notApplied = 'not-applied',
    standard = 'standard',
    range = 'range',
    multiRange = 'multi-range',
}

export interface FilterLink {
    name: string;
    link: string;
}

export interface Filter {
    name: string;
    displayName: string;
    type: FilterTypes;
    values: { [key: string]: number };
    valueKeys?: { [key: string]: string };
    links?: FilterLink[];
}

export interface AppliedFilter<T = string> {
    type: T;
    name: string;
    values: Array<string>;
}

export interface NewFilterValues {
    name: string;
    value: string | string[];
    displayName?: string;
    lastChangedOption?: { value: number | string | boolean; name: string };
}

export type SortingOrderType = 'asc' | 'desc';

export interface SortingParameters {
    sort?: string;
    order?: SortingOrderType;
}

export interface AppliedSorting {
    name: string;
    order: SortingOrderType;
}

export interface Sorting extends AppliedSorting {
    displayName: string;
}

export type Translator = (poKey: string) => string;
