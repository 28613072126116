import styled from 'styled-components';
import { Color } from '../../GlobalStyles/theme';

interface Props {
    color?: Color;
}

export default styled.div<Props>`
    position: relative;
    display: inline-block;
    width: 10px;
    height: 10px;
    overflow: hidden;
    margin-right: 7px;

    &:before,
    &:after {
        content: '';
        position: absolute;
        height: 1px;
        width: 100%;
        top: 50%;
        left: 0;
        background: ${({ theme, color }) => theme.colors[color || 'charcoal100']};
    }

    &:before {
        transform: rotate(45deg);
    }

    &:after {
        transform: rotate(-45deg);
    }
`;
