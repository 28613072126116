export const INTERSECTION_ACTION = 'INTERSECTION';
export const LOADED_ACTION = 'LOADED';
export interface LazyImageStateType {
    isLoaded: boolean;
    showSpinner: boolean;
    hasComeIntoView: boolean;
}
export interface LazyImageActionType {
    type: string;
    payload: boolean;
}
export default {};
