import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { forDesktop } from '@westwing/ui-kit/breakPoints';
import ArrowIcon from '@westwing/ui-kit/Icons/Svgs/ArrowIcon';
import { button } from '@westwing/ui-kit/typography';
import useTranslator from '../../hooks/useTranslator';
import useGa from '../../hooks/useGa';
import NavigationButton from './NavigationButton';
import CountLabel from './CountLabel';

const StyledPaginationWrapper = styled.nav`
    display: flex;
    justify-content: space-between;
    height: ${({ theme }) => theme.buttonHeight};
    margin-top: 40px;
    padding: ${({ theme }) => (theme.featureToggles.p100FreistellerImages ? '0 15px' : '0 5px')};

    ${forDesktop`
        padding: 0;
    `}

    font-family: ${({ theme }) => theme.fonts.fontBrandonRegular};
    font-weight: 300;
    color: ${({ theme }) => theme.colors.charcoal100};
`;

export const StyledNavButtonWrapper = styled.span`
    ${button}
    display: inline-flex;
    min-height: 100%;
    align-items: center;
`;

const StyledRightArrow = styled(ArrowIcon)`
    width: 7px;
    height: 14px;
    margin-left: 7px;
`;

const StyledLeftArrow = styled(StyledRightArrow)`
    margin-right: 7px;
    margin-left: 0;
    transform: scale(-1, 1);
`;

export interface PaginationProps {
    currentPage: number;
    totalPages: number;
    toPrevPage?: string;
    toNextPage?: string;
}

const Pagination = ({ toPrevPage, toNextPage, currentPage, totalPages }: PaginationProps): ReactElement => {
    const gaTracking = useGa();
    const t = useTranslator();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        const { currentTarget } = event;
        const { pagination } = currentTarget.dataset;
        let action = gaTracking.actions.Users.PaginationClickNext;
        if (pagination === 'prev') {
            action = gaTracking.actions.Users.PaginationClickPrev;
        }
        gaTracking.trackUsers({
            action,
            label: `Page ${currentPage}`,
        });
    };

    return (
        <StyledPaginationWrapper>
            <NavigationButton to={toPrevPage} handleClick={handleClick} direction="next">
                <StyledNavButtonWrapper>
                    <StyledLeftArrow /> {t('Pagination_Previous')}
                </StyledNavButtonWrapper>
            </NavigationButton>
            <CountLabel currentPage={currentPage} totalPagesCount={totalPages} />
            <NavigationButton to={toNextPage} handleClick={handleClick} direction="prev">
                <StyledNavButtonWrapper>
                    {t('Pagination_Next')} <StyledRightArrow />
                </StyledNavButtonWrapper>
            </NavigationButton>
        </StyledPaginationWrapper>
    );
};

export default Pagination;
