import { forDesktop } from '@westwing/ui-kit/breakPoints';
import React, { FC, useCallback } from 'react';
import styled, { DefaultTheme, FlattenInterpolation, ThemeProps } from 'styled-components';
import GenericImpressionWrapper from '../Generic/GenericImpressionWrapper';
import { BannerTitle, BannerTitleLink } from './BannerTitle';
import { BellaTestIds } from 'Common/qaIds';
import { BannerTrackingVariations } from 'Common/ga/homepage';
import useGa from 'Client/hooks/useGa';
import Banner from 'Client/components/Generic/Banner';
import useSegmentFeatureTracking from 'Common/segment/useSegmentFeatureTracking';
import { SegmentFeatureCategory, SegmentFeatureInteractionType } from 'AppShell/appshell_types';

export type Typography = FlattenInterpolation<ThemeProps<DefaultTheme>>;

export interface ImgProps {
    title: string;
    targetUrl: string;
    mobileImage: string;
    desktopImage: string;
    className?: string;
    trackingName: string;
}

const SecondaryBannerImgInner = styled(Banner)`
    display: block;
    position: relative;
    &:before {
        display: block;
        content: '';
        width: 100%;
        padding-top: 133%;
        ${forDesktop`
            padding-top: 75%;
        `}
    }
    img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
`;

export const SecondaryBanner: FC<ImgProps> = ({
    title,
    mobileImage,
    desktopImage,
    targetUrl,
    className,
    trackingName,
}) => {
    const gaTracking = useGa();
    const { trackFeatureImpression, trackFeatureInteractions } = useSegmentFeatureTracking();

    const bannerProps = {
        desktopImage,
        fallbackSrc: desktopImage,
        mobileImage,
        alt: title,
        breakPoint: 'breakpointDesktop',
        trackingFn: () => {
            trackFeatureInteractions({
                interaction_type: SegmentFeatureInteractionType.Click,
                interaction_label: '',
                feature_category: SegmentFeatureCategory.Banner,
                feature_name: 'Homepage 2',
                feature_label: trackingName,
            });
            gaTracking.homepage.getBannerTrackingFn(BannerTrackingVariations.General, trackingName)();
        },
        targetUrl,
    };

    const trackImpression = useCallback(() => {
        gaTracking.homepage.trackBannerImpression(trackingName);

        trackFeatureImpression({
            feature_category: SegmentFeatureCategory.Banner,
            feature_name: 'Homepage 2',
            feature_label: trackingName,
        });
    }, [gaTracking.homepage, trackFeatureImpression, trackingName]);

    return (
        <GenericImpressionWrapper className={className} action={trackImpression}>
            <SecondaryBannerImgInner {...bannerProps} />
        </GenericImpressionWrapper>
    );
};

export interface TextProps {
    title: string;
    subtitle?: string;
    ctaText: string;
    targetUrl: string;
    className?: string;
    desktopTypography?: Typography;
    trackingName: string;
    openInNewTab?: boolean;
    trackingSegmentFn?: () => void;
}

export const BannerText: FC<TextProps> = ({
    title,
    subtitle,
    ctaText,
    targetUrl,
    className,
    desktopTypography,
    trackingName,
    openInNewTab,
    trackingSegmentFn,
}) => {
    const gaTracking = useGa();
    const commonProps = {
        title,
        subtitle,
        ctaText,
        className,
        'data-testid': BellaTestIds.homepageSecondaryBanner,
    };

    return targetUrl ? (
        <BannerTitleLink
            onClick={() => {
                gaTracking.homepage.getBannerTrackingFn(BannerTrackingVariations.General, trackingName);
                if (trackingSegmentFn) {
                    trackingSegmentFn();
                }
            }}
            targetUrl={targetUrl}
            desktopTypography={desktopTypography}
            openInNewTab={openInNewTab}
            {...commonProps}
        />
    ) : (
        <BannerTitle {...commonProps} />
    );
};
