import styled from 'styled-components';
import { forTablet } from '@westwing/ui-kit/breakPoints';
import { scroll } from '@westwing/ui-kit/utils';

// padding-bottom needs to be set explicitly to cover the scrollbar on iOS
export const StyledSlider = styled.div`
    position: relative;
    padding-left: 20px;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    ${scroll('x', true)}
    ${forTablet`
        padding-left: 0;
        white-space: normal;
        padding-bottom: 0;
    `}
`;

export default StyledSlider;
