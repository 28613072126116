import React from 'react';
import styled from 'styled-components';
import NormalizedWrapper from './Normalizer';

const StyledNormalizedWrapper = styled(NormalizedWrapper)`
    margin-top: 1em;
`;

const SeoTextBottom: React.FC<{ rawHtml: string }> = ({ rawHtml }) => {
    if (rawHtml === '') {
        return null;
    }

    return <StyledNormalizedWrapper data-testid="seo_bot_content" dangerouslySetInnerHTML={{ __html: rawHtml }} />;
};

export default SeoTextBottom;
