import useFeatureToggle from 'Client/hooks/useFeatureToggle';
import { p100FiltersRedesignVariant } from 'AppShell/appshell_types/featureToggles';

const useP100FilterRedesign = () => {
    const { p100FiltersRedesign } = useFeatureToggle();
    const isBoxed = p100FiltersRedesign === p100FiltersRedesignVariant.rectangleFilters;
    const hideDefaultSort =
        p100FiltersRedesign === p100FiltersRedesignVariant.rectangleFilters ||
        p100FiltersRedesign === p100FiltersRedesignVariant.externalSorting;

    return { isBoxed, hideDefaultSort };
};

export default useP100FilterRedesign;
