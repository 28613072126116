import { buildListForSeoBreadcrumbs } from '../services/seo';
import { ListingPageUrlState } from 'Client/redux/products/types';
import { NormalizedEntitiesAndUrlKeys } from 'Client/redux/category/types';
import useBellaTranslator from 'Client/hooks/useTranslator';
import { PageType } from 'AppShell/appshell_types';

export interface BreadCrumbStateOptions {
    pageHeaderTitle: string;
    host: string;
}

const useBreadcrumbsState = (
    normalized: NormalizedEntitiesAndUrlKeys,
    urlState: ListingPageUrlState,
    { pageHeaderTitle, host }: BreadCrumbStateOptions
) => {
    const t = useBellaTranslator();
    const urlTitle = urlState.listingPageType === PageType.BRANDS ? t('Brands') : '';

    return buildListForSeoBreadcrumbs(normalized, urlState, { host, pageHeaderTitle, urlTitle });
};

export default useBreadcrumbsState;
