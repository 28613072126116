import React, { FC } from 'react';
import styled from 'styled-components';
import { forTablet, forDesktop } from '@westwing/ui-kit/breakPoints';
import BubblesSlider, { CenteredBubblesSliderWrapper, BubblesSliderProps } from '../Generic/BubblesSlider';

const StyledPageBubbleSlider = styled(BubblesSlider)`
    margin: 30px 0;

    ${forTablet`
        margin: 30px;
    `}

    ${forDesktop`
        margin: 30px auto 0;
    `}
`;

const ListingPageBubbleSlider: FC<BubblesSliderProps> = ({ bubbles }) => (
    <CenteredBubblesSliderWrapper>
        <StyledPageBubbleSlider bubbles={bubbles} />
    </CenteredBubblesSliderWrapper>
);

export default ListingPageBubbleSlider;
