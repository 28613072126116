import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { isRedirected } from '../../redux/redirect/actions';
import StateInterface from '../../redux/types';
import { LandingRedirectsData } from '../../redux/redirect/types';
import useRouter from 'AppShell/hooks/useRouter';
import useListingPageUrlState from './hooks/useListingPageUrlState';
import { fetchDynamicSearchRedirectsIfNeeded } from 'Client/redux/header/dynamicSearch/actions';
import { MINIMUM_DYNAMIC_SEARCH_QUERY_LENGTH, RedirectSuggestions } from 'Client/redux/header/dynamicSearch/types';
import { getSuggestionRedirect } from 'OneWestwingHeader/DynamicSearch/searchService';
import findLandingPageRedirect from 'Common/LandingPageRedirectService';
import { parseRedirect } from 'Common/apiAdapters/shopApiAdapter';
import usePrevious from 'Client/hooks/usePrevious';
import { PageType } from 'AppShell/appshell_types/routingTypes';

// eslint-disable-next-line complexity
const useLandingPageRedirects = () => {
    const dispatch = useDispatch();
    const {
        location: { pathname, search: queryString },
    } = useRouter();
    const [urlState] = useListingPageUrlState();
    const landingRedirects = useSelector<StateInterface, LandingRedirectsData | undefined>(
        state => state.redirect.landingRedirects
    );
    const dynamicSearchIsEnterKeySearch = useSelector<StateInterface, boolean>(
        state => state.header.dynamicSearchSuggestions.isEnterKeySearch
    );
    const dynamicSearchSuggestions = useSelector<StateInterface, RedirectSuggestions | undefined>(
        state => state.header.dynamicSearchSuggestions.searchRedirect
    );

    const wasEnterKeySearch = usePrevious(dynamicSearchIsEnterKeySearch);

    const search = urlState.search || '';

    useEffect(() => {
        if (search.length > MINIMUM_DYNAMIC_SEARCH_QUERY_LENGTH) {
            dispatch(fetchDynamicSearchRedirectsIfNeeded(search));
        }
    }, [dispatch, search]);

    if (!search || !landingRedirects || !pathname.includes(PageType.ALLPRODUCTS)) {
        return false;
    }

    /**
     * as mentioned in https://westwing.jira.com/wiki/spaces/DEV/pages/1932328985/Bella+AppShell+Redirects this is where redirect for style / landing
     * pages are handled
     */
    let landingRedirectPath = findLandingPageRedirect(landingRedirects, search);
    if (landingRedirectPath) {
        landingRedirectPath = parseRedirect({
            currentPathname: pathname,
            currentSearch: queryString,
            targetPath: landingRedirectPath,
        });
        // TODO(P100-3749): check if this is still needed
        // redirects for Styles->ALL_PRODUCTS are handled in routeRedirect.ts
        if (landingRedirectPath.startsWith(`/${PageType.STYLES}`)) {
            return false;
        }

        dispatch(
            isRedirected({
                targetPath: landingRedirectPath,
                requestPath: pathname,
                httpCode: 302,
                source: 'landing page redirect from ex bob landing page redirects config',
            })
        );
        return true;
    }

    if (!dynamicSearchSuggestions || wasEnterKeySearch) {
        return false;
    }

    /**
     * as mentioned in https://westwing.jira.com/wiki/spaces/DEV/pages/1932328985/Bella+AppShell+Redirects this is where redirect for search queries
     * are handled
     */
    const searchRedirect = getSuggestionRedirect(dynamicSearchSuggestions, search, queryString);
    if (searchRedirect && searchRedirect.path && searchRedirect.path !== '') {
        dispatch(
            isRedirected({
                targetPath: searchRedirect.path,
                requestPath: pathname,
                httpCode: 302,
                source: 'search redirect configured inside search service',
                replace: true,
            })
        );
        return true;
    }

    return false;
};

export default useLandingPageRedirects;
