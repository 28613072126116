import React, { useState, useRef, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { forDesktop, getMediaStringValue } from '@westwing/ui-kit/breakPoints';
import useElementIntersection from '../../../hooks/useElementIntersection';

const StyledPicture = styled.picture`
    width: 100%;
    display: flex;
`;

const StyledImage = styled.img<{ hideOnDesktop?: boolean; hideOnMobile?: boolean; alt: string }>`
    width: 100%;
    height: 100%;
    display: block;
    ${forDesktop`
        display: block;
    `}
    ${({ hideOnMobile, hideOnDesktop }) => css`
        ${hideOnMobile ? 'display: none;' : ''}
        ${hideOnDesktop
            ? forDesktop`
            display: none;
        `
            : ''}
    `}
`;

interface HybridImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
    mobileSrc: string;
    desktopSrc: string;
    fallbackSrc?: string;
    tabletAsMobile?: boolean;
    alt: string; // enforce alt (instead of optional)
}

const HybridImage = ({
    mobileSrc,
    desktopSrc,
    className,
    fallbackSrc,
    tabletAsMobile = true,
    alt,
    ...otherProps
}: HybridImageProps) => {
    if (!mobileSrc && !desktopSrc && !fallbackSrc) {
        return null;
    }

    const imgProps = {
        ...otherProps,
        hideOnDesktop: !desktopSrc,
        hideOnMobile: !mobileSrc,
        src: fallbackSrc,
    };

    const breakPoint = tabletAsMobile ? 'breakpointDesktop' : 'breakpointTablet';

    return (
        <StyledPicture className={className}>
            {desktopSrc && <source media={getMediaStringValue(breakPoint)} srcSet={desktopSrc} />}
            {mobileSrc && <source media={getMediaStringValue(breakPoint, true)} srcSet={mobileSrc} />}
            <StyledImage alt={alt} {...imgProps} />
        </StyledPicture>
    );
};

export default HybridImage;

const StyledLazyImageWrapper = styled.div`
    min-width: 100%;
    min-height: 1px;
    width: 100%;
`;

export interface LazyImageProps extends HybridImageProps, React.HTMLAttributes<HTMLImageElement> {
    shouldBeEagerlyLoaded?: boolean;
    trackImpressionFunction?: Function;
}

export const LazyHybridImage = React.memo(
    ({
        desktopSrc,
        mobileSrc,
        alt,
        className,
        fallbackSrc,
        trackImpressionFunction,
        shouldBeEagerlyLoaded = false,
        tabletAsMobile = true,
        ...restProps
    }: LazyImageProps) => {
        const [hasComeIntoView, setHasComeIntoView] = useState<boolean>(shouldBeEagerlyLoaded);

        const trackImpression = useCallback(() => {
            if (trackImpressionFunction) {
                trackImpressionFunction();
            }
        }, [trackImpressionFunction]);
        const lazyImageRef = useRef<HTMLDivElement>(null);

        const onIntersection = () => {
            setHasComeIntoView(true);
            trackImpression();
        };

        useElementIntersection(lazyImageRef, () => {
            if (!hasComeIntoView) {
                onIntersection();
            }
        });

        const hybridImageProps = {
            desktopSrc: hasComeIntoView ? desktopSrc : '',
            mobileSrc: hasComeIntoView ? mobileSrc : '',
            fallbackSrc: hasComeIntoView ? fallbackSrc : '',
            tabletAsMobile,
            className,
            alt,
            ...restProps,
        };

        return (
            <StyledLazyImageWrapper className={className} ref={!hasComeIntoView ? lazyImageRef : null}>
                <HybridImage {...hybridImageProps} />
            </StyledLazyImageWrapper>
        );
    }
);
