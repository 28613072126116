import React, { FC, ReactElement } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { Dictionary } from 'Utils/types/utility';
import { ListingPageUrlState, ProductsState } from '../../../redux/products/types';
import { CategoryEntity } from '../../../redux/category/types';
import { SEO_CONSTANTS } from '../services/seo/constant';
import useBreadcrumbsState from '../hooks/useBreadcrumbsState';
import StateInterface from 'Client/redux/types';
import useConfig from 'AppShell/hooks/useConfig';

interface Props {
    host: string;
    pageHeaderTitle: string;
    entities: Dictionary<CategoryEntity>;
    urlKeys: Dictionary<string>;
    urlState: ListingPageUrlState;
}

export interface SeoListItem {
    '@type': string;
    position: number;
    name: string;
    item: string;
}
const getAvailability = (soldOut: boolean, isAvailable: boolean) => {
    if (soldOut) {
        return 'https://schema.org/SoldOut';
    }
    if (!isAvailable) {
        return 'https://schema.org/OutOfStock';
    }
    return 'https://schema.org/InStock';
};

const getStructUrlGenerator = (baseUrl: string) => (slug: string) =>
    (baseUrl[baseUrl.length - 1] === '/' && slug[0] === '/'
        ? `${baseUrl.slice(0, -1)}${slug}`
        : `${baseUrl}${slug}`
    ).split('?simple=')[0];

const SeoProductListScript: FC<Props> = ({ entities, urlKeys, host, urlState, pageHeaderTitle }): ReactElement => {
    const { showSeoScript, breadcrumbList } = useBreadcrumbsState({ entities, urlKeys }, urlState, {
        pageHeaderTitle,
        host,
    });
    const products = useSelector<StateInterface, ProductsState>(state => state.products);
    const config = useConfig();

    if (!showSeoScript) {
        return <Helmet script={[]} />;
    }

    const structUrlGenerator = getStructUrlGenerator(config.links.url);
    const truncatedProducts = products.products.length > 30 ? products.products.slice(0, 30) : products.products;

    const productListObject = {
        [SEO_CONSTANTS.structureDataContextKey]: SEO_CONSTANTS.structureDataContextValue,
        '@graph': [
            {
                '@type': 'WebPage',
                name: products.pageTitle,
                url: structUrlGenerator(products.identifier),
                breadcrumb: {
                    '@type': SEO_CONSTANTS.breadcrumbListType,
                    itemListElement: breadcrumbList.map(breadcrumbEl => ({
                        '@type': breadcrumbEl['@type'],
                        position: breadcrumbEl.position,
                        item: { '@id': breadcrumbEl.item, name: breadcrumbEl.name },
                    })),
                },
            },
            {
                '@type': 'ProductCollection',
                name: products.pageTitle,
                url: structUrlGenerator(products.identifier),
                mainEntity: {
                    '@type': 'ItemList',
                    numberOfItems: products.totalCount,
                    itemListorder: 'ItemListUnordered',
                    itemListElement: truncatedProducts.map(prod => ({
                        '@type': 'Product',
                        image: prod.baseImage,
                        url: structUrlGenerator(prod.link),
                        name: prod.name,
                        offers: {
                            '@type': 'Offer',
                            availability: getAvailability(!!prod.soldOut, !!prod.isAvailable),
                            price: prod.numericPrice,
                            priceCurrency: config.currency,
                        },
                    })),
                },
            },
        ],
    };

    const scriptTag = {
        type: SEO_CONSTANTS.structureDataScriptType,
        innerHTML: JSON.stringify(productListObject),
    };

    return <Helmet script={[scriptTag]} />;
};

export default SeoProductListScript;
