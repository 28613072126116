import React, { FunctionComponent, ReactElement } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { body1 } from '@westwing/ui-kit/typography';
import StateInterface from '../../../redux/types';
import { ReducedDidYouMeanInterface } from '../../../redux/products/types';
import useTranslator from '../../../hooks/useTranslator';
import BellaHybridLink from '../../Generic/Links/BellaHybridLink';

interface Props {
    didYouMean: ReducedDidYouMeanInterface[];
}

const Styledink = styled(BellaHybridLink)`
    color: ${({ theme }) => theme.colors.turquoise100};
`;

const StyledWrapper = styled.div`
    margin: 4px 0;
`;

const StyledSpan = styled.span`
    ${body1}
`;

const StyledWrapperDidYouMean = styled.div`
    padding: 0 30px;
`;
type CompProps = Props;

export const DidYouMeanTitle: FunctionComponent<CompProps> = ({ didYouMean }): ReactElement => {
    const t = useTranslator();
    const separators = ['', ',', `${t('Did_you_mean_mobile_or')}`];
    const { length } = didYouMean;
    const USE_CONJUNCTION = 2;
    const USE_COMMAS = 1;

    const buildDidYouMean = (): React.ReactElement[] => {
        const didYouSuggestions = didYouMean.map((iterable, index) => {
            const itemPositionFromLast = length - index;
            let selectedSeparated = 0;
            let prefix = '';
            let suffix = '';
            if (itemPositionFromLast === USE_CONJUNCTION) {
                selectedSeparated = USE_CONJUNCTION;
                prefix = ' ';
                suffix = ' ';
            } else if (itemPositionFromLast > USE_CONJUNCTION) {
                selectedSeparated = USE_COMMAS;
                suffix = ' ';
            }
            return (
                <React.Fragment key={iterable.relLink}>
                    <Styledink to={iterable.relLink}>{iterable.title}</Styledink>
                    <span>
                        {prefix}
                        {separators[selectedSeparated]}
                        {suffix}
                    </span>
                </React.Fragment>
            );
        });
        return [
            <span key="didyoumean1">{t('Did_you_mean_mobile')}&nbsp;</span>,
            ...didYouSuggestions,
            <span key="didyoumean2">&#63;</span>,
        ];
    };
    return didYouMean.length > 0 ? (
        <StyledWrapper>
            <StyledWrapperDidYouMean>
                <StyledSpan>{buildDidYouMean()}</StyledSpan>
            </StyledWrapperDidYouMean>
        </StyledWrapper>
    ) : (
        <></>
    );
};

const mapStateToProps = (state: StateInterface): { didYouMean: ReducedDidYouMeanInterface[] } => ({
    didYouMean: state.products.didYouMean,
});

export default compose(connect(mapStateToProps, null))(DidYouMeanTitle) as any;
