/* eslint-disable no-restricted-syntax */
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { SEO_CONSTANTS } from '../../ListingPage/services/seo/constant';
import { Config } from 'AppShell/appshell_types';

export type LinkProps = JSX.IntrinsicElements['link'];

interface SeoHeadProps {
    pageTitle: string;
    pageDescription: string;
    robotsIndexAbleContent: string;
    links: LinkProps[];
    pageUrl: string;
    config: Config;
    type?: string;
    openGraphImage?: string;
}

const GenericSeoHead: React.FC<SeoHeadProps> = ({
    config,
    pageTitle,
    pageDescription,
    pageUrl,
    robotsIndexAbleContent,
    links,
    type,
    openGraphImage,
}) => {
    const { siteName } = SEO_CONSTANTS;
    return (
        <Helmet link={links}>
            <title>{pageTitle}</title>
            <meta name={SEO_CONSTANTS.descriptionMetaName} content={pageDescription} />
            <meta name={SEO_CONSTANTS.robotsMetaName} content={robotsIndexAbleContent} />
            <meta property={SEO_CONSTANTS.ogLocale} content={config.countryCode} />
            <meta property={SEO_CONSTANTS.ogSiteName} content={siteName} />
            <meta property={SEO_CONSTANTS.ogTitle} content={pageTitle || siteName} />
            <meta property={SEO_CONSTANTS.ogDescription} content={pageDescription || siteName} />
            <meta property={SEO_CONSTANTS.ogUrl} content={pageUrl} />
            {openGraphImage && <meta property={SEO_CONSTANTS.ogImageUrl} content={openGraphImage} />}
            {type && <meta property={SEO_CONSTANTS.ogType} content={type} />}
        </Helmet>
    );
};

export default GenericSeoHead;
