export const SEGMENT_TRACKING_SOURCES = {
    PLP: 'Product Listing Page',
    LDP: 'Looks Detail Page',
    RECENTLY_VIEWED_SLIDER: 'Recently Viewed Slider',
    SIMILAR_PRODUCT_SLIDER: 'Similar Products Slider',
    RELATED_PRODUCT_SLIDER: 'Related Products Slider',
    SHOP_THE_LOOK_SLIDER: 'Shop the Look Slider',
    SHOP_THE_LOOK_PROMO: 'Shop the Look Promo',
    NEW_PRODUCT_SLIDER: 'New Products Slider',
    BESTSELLER_SLIDER: 'Bestseller Slider',
    CROSS_SALE_RECOMMENDATION_OVERLAY: 'Cross Sale Recommendation Overlay',
};
