import React, { useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import loadable from '@loadable/component';
import { forTablet, forWideScreens } from '@westwing/ui-kit/breakPoints';
import { StyledGrid, StyledHorizontalGrid, Title, StyledLineSeparator } from './ProductGrid';
import ErrorBoundary from 'Client/components/Generic/ErrorBoundaries';
import GenericErrorRetry from 'Client/components/Generic/ErrorPages/GenericErrorRetry';
import Infusions from 'Client/components/Generic/ProductsGrid/Infusions';
import useFeatureToggle from 'Client/hooks/useFeatureToggle';
import { PromotionsConfig } from 'Client/components/Generic/ProductsGrid/types';
import useBellaTranslator from 'Client/hooks/useTranslator';
import { useBellaSelector } from 'Client/redux/hooks';
import { GridBaseProps, GridProduct, ProductGridProps, ProductsGrid } from 'Client/components/Generic/ProductsGrid';
import { heroProductsSpotsInGrid } from 'Client/components/Generic/ProductsGrid/heroProducts';
import useGa from 'Client/hooks/useGa';
import { ProductInterface } from 'Client/redux/products/types';
import useListingPageUrlState from 'Client/components/ListingPage/hooks/useListingPageUrlState';

const SkuDiverActionMenu = loadable(() => import('../SkuDiver/SkuDiverActionMenu'));

const HorizontalGridWrapper = styled(StyledHorizontalGrid)<{ sliderLength: number }>`
    margin-top: 1.4em;
    padding-left: 2.15em;
    padding-bottom: 40px;
    ${({ sliderLength }) => css`
        ${forTablet`
            padding-left: 0;
            grid-template-columns: repeat(${sliderLength}, 200px);
        `}
        ${forWideScreens`
            grid-template-columns: repeat(${sliderLength}, 303px);
        `}
    `}

    &::after {
        display: block;
        content: '';
        height: 100%;
        width: 1px;
    }
`;

const PLPProductsGrid = ({
    products,
    isInline,
    list,
    infusions,
    eagerImageLoading = false,
    looksInfusions,
    promotions,
}: GridBaseProps) => {
    const gaTracking = useGa();
    const desktopInfusionCounter = useRef<number>(0);
    const mobileInfusionCounter = useRef<number>(0);
    const wishlist = useBellaSelector(state => state.header.productsWishlist?.products);
    const [{ listingPageKey, page }] = useListingPageUrlState();
    const colorVariants = useBellaSelector(state => state.products.colorVariants);
    mobileInfusionCounter.current = 0;
    desktopInfusionCounter.current = 0;
    useEffect(() => {
        mobileInfusionCounter.current = 0;
        desktopInfusionCounter.current = 0;
    }, [wishlist]);

    const mobileCounter = useRef<number>(0);
    const desktopCounter = useRef<number>(0);
    mobileCounter.current = 0;
    desktopCounter.current = 0;

    const mobileNoPromoSpots = useRef<Set<number>>(new Set(heroProductsSpotsInGrid));
    const desktopNoPromoSpots = useRef<Set<number>>(new Set([]));

    const promotionsConfig: PromotionsConfig = {
        promotions,
        mobileCounter,
        desktopCounter,
        mobileNoPromoSpots,
        desktopNoPromoSpots,
    };
    const { p100ColorVariantsPlp } = useFeatureToggle();
    const hasSentColorVariantsVariationTracking = useRef(false);

    const onProductHover = (item: ProductInterface) => {
        const itemColorVariants = colorVariants[item.simpleSku]?.data || [];

        if (!hasSentColorVariantsVariationTracking.current && itemColorVariants.length > 1) {
            gaTracking.trackVariation('p100ColorVariantsPlp', p100ColorVariantsPlp);
            hasSentColorVariantsVariationTracking.current = true;
        }
    };

    useEffect(() => {
        hasSentColorVariantsVariationTracking.current = false;
    }, [listingPageKey, page]);

    const isFetching = useBellaSelector<boolean>(state => state.products.isFetching);

    return (
        <>
            <ErrorBoundary boundaryName="ProductGrid" fallbackComponent={<GenericErrorRetry />}>
                {products.map((item, index) => (
                    <GridProduct
                        product={item}
                        products={products}
                        key={item.simpleSku}
                        index={index}
                        isInline={isInline}
                        list={list}
                        eagerImageLoading={eagerImageLoading}
                        looksInfusions={looksInfusions}
                        desktopInfusionCounter={desktopInfusionCounter}
                        mobileInfusionCounter={mobileInfusionCounter}
                        promotionsConfig={promotionsConfig}
                        onProductHover={() => onProductHover(item)}
                        skeletonMode={isFetching}
                    />
                ))}
            </ErrorBoundary>
            {infusions && <Infusions infusions={infusions} />}
        </>
    );
};

const AlternativeProductsGrid = (props: ProductGridProps) => {
    const { className, products, list, alternativeProducts, showFourColumn } = props;
    const t = useBellaTranslator();
    const { skuDiver } = useFeatureToggle();

    if (!alternativeProducts || alternativeProducts?.length <= 0) {
        return null;
    }

    return (
        <>
            {products.length > 0 && <StyledLineSeparator />}
            <Title data-testid="plp-alternative-products-title">{t('Related products')}</Title>
            <StyledGrid
                fourColumnPlp={showFourColumn}
                data-testid="plp-alternative-products-grid"
                className={className}
            >
                {skuDiver && <SkuDiverActionMenu products={alternativeProducts} />}
                <ProductsGrid products={alternativeProducts} list={list} />
            </StyledGrid>
        </>
    );
};

const PLPGrid = (props: ProductGridProps) => {
    const { className, products, list, infusions, showFourColumn } = props;
    const { skuDiver } = useFeatureToggle();
    const { looksInfusions, promotions } = useBellaSelector(state => state.products);
    return (
        <>
            <StyledGrid fourColumnPlp={showFourColumn} data-testid="plp-products-grid" className={className}>
                {skuDiver && <SkuDiverActionMenu products={products} />}
                <PLPProductsGrid
                    products={products}
                    list={list}
                    infusions={infusions}
                    eagerImageLoading
                    looksInfusions={looksInfusions}
                    promotions={promotions}
                />
            </StyledGrid>
            <AlternativeProductsGrid {...props} />
        </>
    );
};

export const HorizontalGrid = ({ className, products, list, ref }: GridBaseProps) => (
    <HorizontalGridWrapper className={className} ref={ref} sliderLength={products.length}>
        <ProductsGrid products={products} list={list} isInline />
    </HorizontalGridWrapper>
);

export default PLPGrid;
