import React from 'react';
import UiKitCheckbox, { CheckboxProps } from '@westwing/ui-kit/Checkbox';
import { inputBaseCss } from '@westwing/ui-kit/typography';
import styled from 'styled-components';

const CheckboxLabel = styled.span`
    ${inputBaseCss}
    font-size: 14px;
`;

const CheckboxControl = styled.label`
    display: flex;
    cursor: pointer;
    ${CheckboxLabel} {
        padding: 0 7px;
    }
`;

const CheckBox = (props: CheckboxProps) => <UiKitCheckbox {...props} />;

const SmallCheckbox = ({ label, ...props }: CheckboxProps) => (
    <CheckboxControl>
        <UiKitCheckbox {...props} />
        <CheckboxLabel>{label}</CheckboxLabel>
    </CheckboxControl>
);

CheckBox.Small = SmallCheckbox;

export default CheckBox;
